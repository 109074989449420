import React from 'react';
import { useTranslation } from 'react-i18next';
import PackageSizeCard from './PackageSizeCard';
import PaymentCard from './PaymentCard';
import EditPaymentCard from '../OrderForm/PaymentCard/EditPaymentCard';
import { Form, FormInstance } from 'antd';
import { Preview } from '@/types';

interface PackageSizeOption {
  size: 'small' | 'medium' | 'large';
  description: string;
}

interface PackageAndPaymentCardProps {
  form: FormInstance; // Add form instance prop
  onPackageSizeChange: (size: string) => void; // New prop to handle size change
  onCustomShipmentFeeChange: (shipmentFee?: number) => void;
  customShipmentFee?: number;
  orderPrice: Preview;
  orderPriceLoading: boolean;
}
const PackageAndPaymentCard: React.FC<PackageAndPaymentCardProps> = ({
  form,
  onPackageSizeChange,
  onCustomShipmentFeeChange,
  customShipmentFee,
  orderPrice,
  orderPriceLoading,
}) => {
  const { t } = useTranslation();
  const packageOptions: PackageSizeOption[] = [
    { size: 'small', description: t('up_to_one_kg') },
    { size: 'medium', description: t('up_to_three_kg') },
    { size: 'large', description: t('up_to_five_kg') },
  ];
  return (
    <div className='sticky top-4  w-full '>
      <Form.Item name='packageSize'>
        <PackageSizeCard
          title={t('package_size')}
          options={packageOptions}
          form={form}
          onPackageSizeChange={onPackageSizeChange}
        />
      </Form.Item>

      <EditPaymentCard
        form={form}
        title={t('payment')}
        orderTitle={t('order_title')}
        codTitle={t('cod')}
        prepaidTitle={t('prepaid')}
        shipmentFeeTitle={t('shipment_fee')}
        customerTitle={t('customer')}
        merchantTitle={t('merchant')}
        walletBalanceText={t('wallet_balance')}
        availableText={t('available')}
        iqdText={t('iqd')}
        discountPlaceholder={t('discount_code')}
        orderPrice={orderPrice} // Pass orderPrice to PaymentCard
        orderPriceLoading={orderPriceLoading}
        onCustomShipmentFeeChange={onCustomShipmentFeeChange}
        customShipmentFee={customShipmentFee}
      />
    </div>
  );
};

export default PackageAndPaymentCard;
