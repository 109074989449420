import { SVGProps } from 'react';

interface BellIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CalendarOutlineIcon = ({
  width = 32,
  height = 32,
  color = '#38383E',
  ...props
}: BellIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path fill='#EBDD4C' d='M5.5 8a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v4h-22V8Z' />
    <path
      fill={color}
      d='M12.5 2.934v1.733h8V2.934c0-.148.12-.267.267-.267h1.466c.148 0 .267.12.267.267v1.733h2.667a4 4 0 0 1 4 4v16.667a4 4 0 0 1-4 4H7.833a4 4 0 0 1-4-4V8.667a4 4 0 0 1 4-4H10.5V2.934c0-.148.12-.267.267-.267h1.466c.148 0 .267.12.267.267Zm14.667 10.4H5.833v12a2 2 0 0 0 1.883 1.996l.117.004h17.334a2 2 0 0 0 1.996-1.883l.004-.117v-12ZM10.5 6.667H7.833a2 2 0 0 0-1.996 1.882l-.003.118v2.667h21.333V8.667a2 2 0 0 0-1.883-1.997l-.117-.003H22.5V8.4c0 .148-.12.267-.267.267h-1.466A.267.267 0 0 1 20.5 8.4V6.667h-8V8.4c0 .148-.12.267-.267.267h-1.466A.267.267 0 0 1 10.5 8.4V6.667Z'
    />
  </svg>
);
export default CalendarOutlineIcon;
