import { useEffect } from 'react';
import {
  Col,
  Flex,
  Radio,
  RadioChangeEvent,
  Row,
  Typography,
  Form,
  FormInstance,
  Select,
} from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddNewLocationModal } from '../AddNewLocationModal';
import { LOCAL_EN } from '@/config';
import { useFetchPickupAddresses } from '@/hooksApi/usePickupAddress';
// import { usePickupAddressStore } from '@/stores';
import { Store } from 'antd/es/form/interface';
import { usePickupAddressEdit } from '@/hooks';
// import { CustomSelectEditOrder } from '../CustomSelectEditOrder';
// import { selectData } from '@/utils/CustomSelectData';

const { Text } = Typography;
const { Option } = Select;

interface PickupDropEditCardProps {
  form: FormInstance; // Accept form instance as a prop
  title: string;
  option1Label: string;
  option2Label: string;
  collectDescription: string;
  dropoffDescription: string;
  previewOrderPrice: (orderPayload: Store) => void; // New prop for previewOrderPrice
  transformValuesToPayload: (values: Store) => Store; // New prop for transforming form values
  pickupOrDropoffProp?: string;
}

interface SelectItem {
  uid: string;
  title: string;
  address_text: string;
}

const PickupDropEditCard = ({
  form, // Use the form instance to interact with the parent form
  title,
  option1Label,
  option2Label,
  collectDescription,
  dropoffDescription,
  previewOrderPrice,
  transformValuesToPayload,
  pickupOrDropoffProp,
}: PickupDropEditCardProps) => {
  const [selectedValue, setSelectedValue] = useState('PICK_UP');
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  console.log('form', form.getFieldValue('pick_up_address_uid'));
  // const [selectedUid, setSelectedUid] = useState(''); // State for selected uid
  const gettedPickupUid = usePickupAddressEdit((state) => state.selectedPickupUid); // Set the Pickup address UID from Zustand

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
    form.setFieldsValue({ pick_up_address_uid: '' });
  };

  // const { clearPickupAddresses } = usePickupAddressStore();
  // const { clearPickupAddressesEdit } = usePickupAddressEdit();

  const { data: pickupAddresses, isLoading: pickupAddressLoading } = useFetchPickupAddresses();

  const typedSelectData: SelectItem[] =
    pickupAddresses?.object?.items
      .filter((item) => item.type === 'WAREHOUSE') // Filter by type 'WAREHOUSE'
      .map((item) => ({
        uid: item.uid,
        title: item.title,
        address_text: item.address_text,
      })) || [];
  //ADD INITIAL pickupOrDropoff
  useEffect(() => {
    if (pickupOrDropoffProp) {
      form.setFieldsValue({
        pickupOrDropoff: pickupOrDropoffProp,
      });
      setSelectedValue(pickupOrDropoffProp || '');
    }
  }, [pickupOrDropoffProp]);

  // useEffect(() => {
  //   return () => {
  //     clearPickupAddressesEdit();
  //     alert(gettedPickupUid);
  //   };
  // }, []);

  // Call previewOrderPrice when pick_up_address_uid changes
  const handleSelectPickupAddress = (uid: string) => {
    form.setFieldsValue({ pick_up_address_uid: uid });
    const formValues = form.getFieldsValue(); // Get current form values
    const orderPayload = transformValuesToPayload({ ...formValues, pick_up_address_uid: uid });
    previewOrderPrice(orderPayload); // Call the previewOrderPrice function
  };

  const handleChange = (value: string) => {
    const selectedItem = typedSelectData.find((item) => item.title === value);
    if (selectedItem && handleSelectPickupAddress) {
      handleSelectPickupAddress(selectedItem?.uid);
    }
  };

  const defaultValue =
    typedSelectData.length > 0
      ? typedSelectData?.find((item) => item.uid === form.getFieldValue('pick_up_address_uid'))
          ?.title
      : undefined;

  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Form.Item name='pickupOrDropoff' initialValue='PICK_UP'>
        <Radio.Group className='w-full' value={selectedValue} onChange={handleRadioChange}>
          <Row gutter={[24, 16]} className='mt-[19.5px]'>
            <Col lg={12} md={24} xs={24}>
              <Flex
                className={`h-[81px] ${
                  selectedValue === 'PICK_UP'
                    ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
                    : 'border border-solid border-customGrayShade4'
                } rounded-[10px] flex items-center justify-start pl-5`}
              >
                <Radio
                  value='PICK_UP'
                  className={`custom-radio ${
                    selectedValue === 'PICK_UP' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
                  } flex items-center font-bold text-lg leading-[20.88px]`}
                >
                  {option1Label}
                </Radio>
              </Flex>
            </Col>
            {/* V0 remove */}
            {/* <Col lg={12} md={24} xs={24}>
              <Flex
                className={`h-[81px] ${
                  selectedValue === 'DROP_OFF'
                    ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
                    : 'border border-solid border-customGrayShade4'
                } rounded-[10px] flex items-center justify-start pl-5`}
              >
                <Radio
                  value='DROP_OFF'
                  className={`custom-radio ${
                    selectedValue === 'DROP_OFF'
                      ? 'text-customTunaPrimary'
                      : 'text-customGrayShade5'
                  } flex items-center font-bold text-lg leading-[20.88px]`}
                >
                  {option2Label}
                </Radio>
              </Flex>
            </Col> */}
          </Row>
        </Radio.Group>
      </Form.Item>

      {selectedValue === 'PICK_UP' ? (
        <Row>
          <Col lg={24} md={24} xs={24}>
            {typedSelectData.length !== 0 ? (
              <Form.Item name='pick_up_address_uid' initialValue={gettedPickupUid}>
                <Flex vertical className={`mt-3`}>
                  <Text
                    className={`font-normal text-base leading-[20.48px] text-customTunaPrimary mb-[14px] `}
                  >
                    {title}
                  </Text>
                  <Select
                    defaultValue={defaultValue}
                    loading={pickupAddressLoading}
                    onChange={handleChange}
                    variant='borderless'
                    value={defaultValue}
                    className='border px-[5px]  border-solid w-full h-[81px] custom-select-placeholder custom-select-item border-customGrayShade4 rounded-[10px]'
                  >
                    {typedSelectData.map((item, index) => (
                      <Option key={index} value={item.title}>
                        <Col>
                          <Text className='font-bold text-lg leading-[20.88px]  text-customTunaPrimary'>
                            {item.title}
                          </Text>
                          <br />
                          <Text className='font-normal text-base leading-[20.48px]  text-customTunaPrimary'>
                            {item.address_text}
                          </Text>
                        </Col>
                      </Option>
                    ))}
                  </Select>
                </Flex>
              </Form.Item>
            ) : (
              <Flex vertical>
                <Text className='font-normal text-base my-3 leading-[20.48px] text-customTunaPrimary'>
                  {collectDescription}
                </Text>
                <Flex
                  className={`rounded-[10px] gap-x-1 py-[30px] ${
                    i18n.language === LOCAL_EN ? 'pl-4' : 'pr-4'
                  } pl-4 border border-solid border-customGrayShade4`}
                >
                  <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary'>
                    {t('there_is_no_address_in_your_list')}
                  </Text>
                  <Text
                    onClick={() => {
                      setAddNewLocationModalOpen((prev) => !prev);
                      // clearPickupAddresses();
                    }}
                    className='font-normal cursor-pointer text-base leading-[20.48px] capitalize text-customBlue'
                  >
                    {t('address_list')}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Col>
        </Row>
      ) : (
        <Row className='mt-3'>
          <Col lg={24} md={24} xs={24}>
            <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary mb-[14px]'>
              {dropoffDescription}
            </Text>
          </Col>
        </Row>
      )}
      <AddNewLocationModal
        visible={addNewLocationModalOpen}
        onClose={() => setAddNewLocationModalOpen(false)}
      />
    </Card>
  );
};

export default PickupDropEditCard;
