import { Layout, Space, Typography } from 'antd';
import { ReactNode } from 'react';

interface OrderSummaryCardsProps {
  title: string;
  value: number;
  Icon: ReactNode;
}

const { Text } = Typography;

const OrderSummaryCards = ({ title, value, Icon }: OrderSummaryCardsProps) => {
  return (
    <Layout className='p-6 flex flex-row items-center justify-between bg-white rounded-[10px] shadow-card-dark'>
      <Space align='center'>
        <Space className='p-[10px] rounded-full w-[45px] h-[45px] bg-customGrayShade2 flex items-center justify-center'>
          {Icon}
        </Space>
        <Text className='text-sm font-medium'>{title}</Text>
      </Space>
      <Text className='text-2xl font-bold'>{value}</Text>
    </Layout>
  );
};

export default OrderSummaryCards;
