import { ITable } from '@/components/TableAction/TableAction';

// interface column tab Ready to Ship
export interface OrderDataReady extends ITable {
  orderDate: string;
  orderId: string;
  customerName: string;
  customerAddress: string;
  quantity: number;
  totalValue: string;
}

// interface column tab InProgress
export interface OrderDataInProgress extends ITable {
  orderDate: string;
  orderId: string;
  customerName: string;
  customerAddress: string;
  pickupLocation: string;
  product: string;
  status: string;
}

// interface column tab OnHold
export interface OrderDataOnHold extends ITable {
  orderDate: string;
  orderId: string;
  customerName: string;
  customerAddress: string;
  pickupLocation: string;
  product: string;
  status: string;
}
// interface column tab RTO
export interface OrderDataRTO extends ITable {
  time: string;
  dropOffLocation: string;
  driver: {
    image: string;
    name: string;
    phone: string;
  };
  status: string;
}
// export interface OrderDataRTO extends ITable {
//   orderDate: string;
//   orderId: string;
//   customerName: string;
//   customerAddress: string;
//   pickupLocation: string;
//   product: string;
//   status: string;
// }

// interface column tab finished
export interface OrderDataFinished extends ITable {
  orderDate: string;
  orderId: string;
  customerName: string;
  customerAddress: string;
  quantity: number;
  totalValue: string;
  status: string;
}

export enum OrderStatusTab {
  ReadyToShip = 'readyToShip',
  InProgress = 'inProgress',
  OnHold = 'onHold',
  RTO = 'RTO',
  Finished = 'finished',
}

export enum StatusEnum {
  COLLECTING = 'Collecting',
  NEW = 'New',
}

export interface CsvData extends ITable {
  key: string;
  label: string;
  value: string;
}

export interface OrderDataImport {
  key: string;
  validity: string;
  orderId: string;
  customerName: string;
  customerPhone: string;
  customerAddress: string;
  quantity: number;
  total: number;
  packageSize: string;
  orderPayment: string;
  shipmentPayment: string;
  errors: any;
}

export enum FinanceTab {
  Balance = 'Balance',
  COD = 'COD Remittances',
  Transactions = 'Transactions',
}

export interface RemittanceRecord {
  key: string;
  date: string;
  remittanceId: string;
  amount: string;
  status: string;
}

export interface FinanceCOD extends ITable {
  date: string;
  remittanceId: string;
  amount: string;
  eta: string;
  status: string;
}

export interface FinanceTransaction extends ITable {
  date: string;
  transactionId: string;
  title: string;
  amount: string;
}
