import { SVGProps } from 'react';

interface StepIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const StepIcon = ({ width = 26, height = 26, color = '#939496', ...props }: StepIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={28} height={28} fill='none' {...props}>
    <rect width={width} height={height} x={1} y={1} stroke={color} strokeWidth={2} rx={13} />
    <rect width={12} height={12} x={8} y={8} fill={color} rx={6} />
    <path
      fill={color}
      fillRule='evenodd'
      d='M14 18.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0 .75a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z'
      clipRule='evenodd'
    />
  </svg>
);
export default StepIcon;
