import React from 'react';
import { SVGProps } from 'react';

interface RightArrowIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const RightArrowIcon: React.FC<RightArrowIconProps> = ({
  width = 16,
  height = 16,
  fillColor = '#000',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M13.32 7.673l-4.16-4.16a.5.5 0 0 1 .707-.707l5.014 5.013a.5.5 0 0 1 0 .708L9.867 13.54a.5.5 0 0 1-.707-.707l4.16-4.16H1.527a.5.5 0 0 1 0-1H13.32Z'
      clipRule='evenodd'
    />
  </svg>
);

export default RightArrowIcon;
