import { Empty, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

const NoData = () => {
  const { t } = useTranslation();
  return (
    <Flex className=' max-h-screen flex justify-center pt-[18%] '>
      <Empty
        description={t('no_data')}
        className='font-normal text-sm leading-[18.2px] text-customGrayShade5'
      />
    </Flex>
  );
};

export default NoData;
