import { SVGProps } from 'react';

interface AddIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const AddIcon = ({ width = 24, height = 24, color = '#38383E', ...props }: AddIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 24 24' // Ensures the SVG scales correctly with the given width and height
    {...props}
  >
    <path
      fill={color}
      d='M12.55 3.25c.11 0 .2.09.2.2v7.8h7.8c.11 0 .2.09.2.2v1.1a.2.2 0 0 1-.2.2h-7.8v7.8a.2.2 0 0 1-.2.2h-1.1a.2.2 0 0 1-.2-.2v-7.8h-7.8a.2.2 0 0 1-.2-.2v-1.1c0-.11.09-.2.2-.2h7.8v-7.8c0-.11.09-.2.2-.2h1.1Z'
    />
  </svg>
);

export default AddIcon;
