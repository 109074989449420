import { SVGProps } from 'react';

interface BarIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BarIcon = ({ width = 52, height = 40, color = '#939496', ...props }: BarIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      d='m28 21.172 3.586-3.586a2 2 0 0 1 2.829 2.828l-7 7a2 2 0 0 1-2.828 0l-7.001-7a2.002 2.002 0 0 1 0-2.828c.78-.78 2.048-.78 2.829 0L24 21.172V2.001a2 2 0 0 1 4 0v19.171ZM39 14a2.001 2.001 0 0 1 0-4h8.232a4.581 4.581 0 0 1 4.579 4.58v20.632a4.581 4.581 0 0 1-4.58 4.578H4.818a4.581 4.581 0 0 1-4.58-4.578V14.579A4.581 4.581 0 0 1 4.817 10H13a2.001 2.001 0 0 1 0 4H4.817a.58.58 0 0 0-.58.58v20.632c0 .319.26.578.58.578h42.415c.32 0 .579-.26.579-.578V14.579a.58.58 0 0 0-.58-.579h-8.23Z'
      clipRule='evenodd'
    />
  </svg>
);
export default BarIcon;
