import * as React from 'react';
import { SVGProps } from 'react';

interface CustomSvgProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const DownIcon: React.FC<CustomSvgProps> = ({
  width = 16, // default width
  height = 16, // default height
  color = '#38383E', // default color
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16' // added viewBox for scaling
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='m1.703 5.465 5.85 6.06a.499.499 0 0 0 .672.033l.035-.032 6.034-6.06a.133.133 0 0 0 .039-.095V4.33a.133.133 0 0 0-.228-.094L7.927 10.44 1.895 4.241a.133.133 0 0 0-.229.093v1.038c0 .035.013.068.037.093Z'
    />
  </svg>
);

export default DownIcon;
