import { LOCAL_EN } from '@/config';
import { Col, Flex, Row, Spin, Tooltip, Typography } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LeftIcon } from '../Icons';
type TypeFontSize =
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl'
  | 'text-3xl'
  | 'text-4xl'
  | 'text-5xl'
  | 'text-6xl';
export type TypeFontWeight =
  | 'font-thin'
  | 'font-extralight'
  | 'font-light'
  | 'font-normal'
  | 'font-medium'
  | 'font-semibold'
  | 'font-bold'
  | 'font-extrabold'
  | 'font-black';
const { Text, Title } = Typography;
interface HeadingProps {
  routeLink?: string;
  title?: string;
  subTitle?: string;
  subTitleClassName?: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  color?: string;
  fontWeight?: TypeFontWeight;
  fontSize?: TypeFontSize;
  level?: 1 | 2 | 3 | 4 | 5;
  extraClassName?: string;
  parentClassName?: string;
  textTooltip?: string;
  upperCase?: boolean;
  rotation?: boolean;
  onClick?: () => void;
  loading?: boolean;
  textLoading?: string;
  disabled?: boolean;
}
const Heading = ({
  routeLink,
  title,
  subTitleClassName,
  subTitle,
  trailingIcon,
  leadingIcon,
  fontWeight = 'font-medium',
  fontSize = 'text-xs',
  color = 'text-customTunaPrimary',
  level = undefined, // Default level if not provided
  extraClassName = '', // Default to empty string if not provided
  parentClassName = '', // Default to empty string if not provided
  textLoading = '',
  textTooltip,
  upperCase = false,
  rotation = true, // change if rotation is true or false
  onClick,
  loading,
  disabled = false,
}: HeadingProps) => {
  const { i18n } = useTranslation();
  // Calculate rotation class based on language
  const rotationClass = rotation ? (i18n.language === LOCAL_EN ? 'rotate-0' : 'rotate-180') : '';
  // Combine classes for text styling
  const textClassName = `${fontWeight} ${color} ${extraClassName} ${upperCase ? 'uppercase' : ''}`;
  // Render icon with optional tooltip
  const renderIconWithTooltip = (icon: ReactNode) =>
    icon && (
      <Row className={rotationClass}>
        {textTooltip ? <Tooltip title={textTooltip}>{icon}</Tooltip> : icon}
      </Row>
    );
  const backIconWithLink = () => (
    <Row className={rotationClass}>
      {routeLink && (
        <Link to={routeLink} className='flex'>
          <Col className='rounded-[10px] w-[44px] h-[44px] flex justify-center items-center border border-solid border-customTunaPrimary'>
            <LeftIcon />
          </Col>
        </Link>
      )}
    </Row>
  );
  return (
    <Flex
      className={`flex ${trailingIcon || leadingIcon || routeLink ? 'gap-2 items-center' : ''} ${parentClassName} ${disabled ? 'opacity-50' : 'opacity-100'}`}
      onClick={!disabled ? onClick : undefined}
    >
      {/* Prefix Icon */}
      {!loading && renderIconWithTooltip(leadingIcon)}
      {routeLink && backIconWithLink()}
      {/* Title or Text */}
      {loading ? (
        textLoading ? (
          <Text>{textLoading}</Text>
        ) : (
          <Spin />
        )
      ) : level ? (
        <Title level={level} className={textClassName}>
          {title}
        </Title>
      ) : (
        <Flex className={`${subTitle && 'gap-x-1'}`}>
          {' '}
          <Text className={`${textClassName} ${fontSize}`}>{title}</Text>
          <Text className={`$ ${subTitleClassName}`}>{subTitle}</Text>
        </Flex>
      )}
      {/* Suffix Icon */}
      {renderIconWithTooltip(trailingIcon)}
    </Flex>
  );
};
export default Heading;
