interface searchItem {
  label: string;
  value: string;
}

export const getSearchItems = (t: any) => {
  const searchItems: searchItem[] = [
    { label: t('phone_number'), value: 'filter_phone' },
    { label: t('address'), value: 'filter_address_text' },
    { label: t('first_name'), value: 'filter_full_name' },
    { label: t('package_size'), value: 'filter_size' },
    { label: t('platform_code'), value: 'filter_platform_code' },
    { label: t('pickup_address'), value: 'filter_pick_up_address_title' },
    { label: t('filter_custom_id'), value: 'filter_custom_id' },
  ];

  return searchItems.map((item) => ({
    ...item,
    label: t(item.label),
  }));
};
