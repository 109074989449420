import { useState } from 'react';
import { RegisterFormCard, RegisterImageCard } from '@/components/RegisterCard';
import { useRegister } from '@/hooks';
import { Button, Col, Flex, Image, Layout, Row, Spin, Menu, Dropdown } from 'antd';
import boxyLogo from '/boxy-logo.svg';
import { useTranslation } from 'react-i18next';
import { LOCAL_EN, LOCAL_AR } from '@/config';
import { GlobalOutlined } from '@ant-design/icons';
const Register = () => {
  const {
    countryCode,
    setCountryCode,
    firstNameInput,
    setFirstNameInput,
    lastNameInput,
    setLastNameInput,
    phoneNumberInput,
    setPhoneNumberInput,
    onFinish,
    onFinishFailed,
    toggleDirection,
    isLoading,
  } = useRegister();
  const { t, i18n } = useTranslation();

  // State to track image loading status
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const languageMenu = (
    <Menu onClick={toggleDirection}>
      <Menu.Item key={LOCAL_EN}>English</Menu.Item>
      <Menu.Item key={LOCAL_AR}>العربية</Menu.Item>
    </Menu>
  );
  return (
    <Layout className='flex justify-center items-center min-h-screen bg-customYellowPrimary'>
      <Flex vertical>
        {isImageLoaded && (
          <>
            <Col className='flex justify-center items-center'>
              <Image
                width={'160px'}
                height={'74.1px'}
                src={boxyLogo}
                alt='register Visual'
                preview={false}
              />
            </Col>
            {/* <Button onClick={toggleDirection} className="absolute top-6 right-6">
            Change Language
          </Button> */}
            <Dropdown overlay={languageMenu} trigger={['click']} className='absolute top-6 right-6'>
              <Button icon={<GlobalOutlined style={{ fontSize: '26px' }} />} type='text'></Button>
            </Dropdown>
          </>
        )}

        {/* Render spinner until the image is fully loaded */}
        {!isImageLoaded && (
          <Flex className='flex justify-center items-center h-full'>
            <Spin size='large' fullscreen />
          </Flex>
        )}

        {/* Form and Image Row - hidden until the image is loaded */}
        <Col className={`h-[78vh] mt-[53px] ${!isImageLoaded ? 'hidden' : ''}`}>
          <Row
            className={`shadow-deep-shadow rounded-l-[20px] overflow-hidden ${
              i18n.language === LOCAL_EN ? 'rounded-l-[20px]' : 'rounded-r-[20px]'
            }`}
          >
            <RegisterFormCard
              firstNameInput={firstNameInput}
              setFirstNameInput={setFirstNameInput}
              lastNameInput={lastNameInput}
              setLastNameInput={setLastNameInput}
              phoneNumberInput={phoneNumberInput}
              setPhoneNumberInput={setPhoneNumberInput}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              createAccountText={t('create_new_account')}
              firstNamePlaceholder={t('first_name')}
              lastNamePlaceholder={t('last_name')}
              countryCodePlaceholder={t('country_code')}
              phoneNumberPlaceholder={t('phone_number')}
              termsAndConditionsText={t('terms_conditions')}
              agreeWithText={t('i_agree_with')}
              registerButtonText={t('register')}
              loginText={t('already_have_account')}
              loginLinkText={t('loginUpp')}
              isLoading={isLoading}
            />
            {/* Pass the onImageLoad function */}
            <RegisterImageCard onImageLoad={() => setIsImageLoaded(true)} />
          </Row>
        </Col>
      </Flex>
    </Layout>
  );
};

export default Register;
