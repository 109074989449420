// sentryQueryWrapper.ts
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryKey,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

// Wrapper for `useQuery` with Sentry tracing in v8
export function useSentryQuery<TData, TError>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) {
  return useQuery<TData, TError>(
    queryKey,
    async () => {
      return await Sentry.startSpan(
        {
          name: `Query: ${queryKey[0]}`, // Name for the span
          op: 'query',
          attributes: { queryKey: JSON.stringify(queryKey) },
        },
        async (span) => {
          try {
            const result = await queryFn();
            // span.setStatus('ok'); // Set status as 'ok' on success
            return result;
          } catch (error) {
            // span.setStatus('error'); // Set status as 'error' on failure
            Sentry.captureException(error);
            throw error;
          }
        },
      );
    },
    options,
  );
}

// Wrapper for `useMutation` with Sentry tracing in v8
export function useSentryMutation<TData, TError, TVariables>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TError, TVariables>,
) {
  return useMutation<TData, TError, TVariables>(async (variables: TVariables) => {
    return await Sentry.startSpan(
      {
        name: `Mutation: ${mutationFn.name || 'unnamed'}`, // Name for the span
        op: 'mutation',
      },
      async (span) => {
        try {
          const result = await mutationFn(variables);
          // span.setStatus('ok'); // Set status as 'ok' on success
          return result;
        } catch (error) {
          // span.setStatus('error'); // Set status as 'error' on failure
          Sentry.captureException(error);
          throw error;
        }
      },
    );
  }, options);
}
