import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Heading } from '../Heading';
import {
  AutoComplete,
  Checkbox,
  Col,
  Flex,
  Form,
  FormInstance,
  Image,
  Input,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
// import { useCustomerDetailsForm } from '@/hooks';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { useEffect, useState } from 'react';
import { countries } from '@/utils/CountryCodes';
import { useDeleteContact, useFetchContact, useFetchContacts } from '@/hooksApi';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DeleteIcon } from '../Icons';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchAreaByProvinceCode, useFetchListProvinces } from '@/hooksApi/useRegion';
import { useContactsStore } from '@/stores/contactStore';
import { RegionItem } from '@/types/region';
// import { convertArabicToEnglishNumbers, handlePhoneInput } from '@/utils';

// import { ContactRequest, responseContactByUid } from '@/types';

const { Text } = Typography;
const { Option } = Select;
interface ContactData {
  full_name?: string;
  phone?: string;
  address_text?: string;
  region?: RegionItem;
  city?: string;
}

interface EditCustomerDetailsProps {
  form: FormInstance; // Use the FormInstance type from Ant Design;
  title: string;
  namePlaceholder: string;
  primaryPhonePlaceholder: string;
  secondaryPhonePlaceholder: string;
  provincePlaceholder: string;
  areaPlaceholder: string;
  addressPlaceholder: string;
  saveToAddress: string;
  resetForm?: boolean;
  contactData?: ContactData;
  selectedProvinceCode?: string;
  setSelectedProvinceCode: (provinceCode?: string) => void;
}

const EditCustomerDetails = ({
  form,
  title,
  namePlaceholder,
  primaryPhonePlaceholder,
  secondaryPhonePlaceholder,
  provincePlaceholder,
  areaPlaceholder,
  addressPlaceholder,
  saveToAddress,
  resetForm, // Step 1: Receive resetForm prop
  contactData,
  selectedProvinceCode,
  setSelectedProvinceCode,
}: EditCustomerDetailsProps) => {
  // const [primaryCountryCode, setPrimaryCountryCode] = useState('+98');
  // const [secondaryCountryCode, setSecondaryCountryCode] = useState('+98');
  // const [primaryPhoneInput, setPrimaryPhoneInput] = useState('');
  const [selectedUid, setSelectedUid] = useState<string | null>(null);
  // const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | null>(null);
  const [areaOptions, setAreaOptions] = useState<any[]>([]);
  const { i18n, t } = useTranslation();

  // Use the Zustand store
  const setRegionUid = useContactsStore((state) => state.setRegionUid);

  // Use the hook to fetch contacts
  const { data: contacts, isLoading: contactsLoading } = useFetchContacts();

  // Fetch individual contact by UID
  const { data: contactDetails } = useFetchContact(selectedUid || '');
  const { data: provinceList, isLoading: provinceListLoading } = useFetchListProvinces();
  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );

  const nameOptions = contacts?.object?.items.map((contact) => ({
    value: contact.full_name,
    uid: contact.uid, // Store the uid for later use
  }));

  // Handle province change to fetch area list based on selected province code
  const handleProvinceChange = (provinceCode: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });

    setSelectedProvinceCode(provinceCode);
  };

  // handleAreaChange to save area UID to Zustand store and set the Field Value in form
  const handleAreaChange = (value: string) => {
    form.setFieldsValue({ region_uid: value });
    handleChangeRegionUid(value);
  };

  // handleChangeRegionUid to save area UID to Zustand store
  const handleChangeRegionUid = (value: string) => {
    // const selectedArea = areaList?.object.find((area) => area.uid === value);
    // if (selectedArea) {
    //   setRegionUid(selectedArea.uid); // Set region UID to Zustand store
    // }
  };

  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Row gutter={[24, 16]} className='mt-[17px]'>
        <Col lg={12} md={24} xs={24}>
          <Form.Item rules={[{ required: true, message: '' }]} name='name'>
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              bordered={false}
              placeholder={namePlaceholder}
              className='border custom-select-placeholder border-solid  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]} style={{ direction: 'ltr' }}>
        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          <Form.Item
            name='countryCodePrimary'
            initialValue={'+964'}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              variant='borderless'
              style={{ direction: 'ltr' }}
              className='border border-solid custom-select-item custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px]'
              dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
            >
              {countries.map((country, index) => (
                <Option key={index} value={country.dialCodes?.[0]}>
                  <span role='img'>
                    <Image preview={false} src={country.image} width={20} height={20} />
                  </span>{' '}
                  ({country.dialCodes?.[0]})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className='w-full'
            name='phoneNumberPrimary'
            rules={[{ required: true, message: '' }]}
          >
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              bordered={false}
              autoComplete='off'
              style={{ direction: 'ltr' }}
              placeholder={primaryPhonePlaceholder}
              className='border border-solid custom-form-placeholder-input  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          <Form.Item
            initialValue={'+964'}
            name='countryCodeSecondary'
            rules={[{ required: true, message: '' }]}
          >
            <Select
              variant='borderless'
              style={{ direction: 'ltr' }}
              className='border border-solid custom-select-placeholder custom-select-item border-customGrayShade5 h-[56px] rounded-[10px]'
              // onChange={(value) => setSecondaryCountryCode(value)}
              dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
            >
              {countries.map((country, index) => (
                <Option key={index} value={country.dialCodes?.[0]}>
                  <span role='img'>
                    <Image preview={false} src={country.image} width={20} height={20} />
                  </span>{' '}
                  ({country.dialCodes?.[0]})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className='w-full' name='secondaryPhoneNumber'>
            <Flex className='relative'>
              <Input
                autoComplete='off'
                style={{ direction: 'ltr' }}
                onChange={(e) => {
                  form.setFieldsValue({ secondaryPhoneNumber: e.target.value });
                }}
                placeholder={secondaryPhonePlaceholder}
                className='border border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
                // onInput={handlePhoneInput} // Handle input to filter numbers
              />
            </Flex>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='province' rules={[{ required: true, message: '' }]}>
            <Select
              suffixIcon={<span className='text-customRed text-base'>*</span>}
              loading={provinceListLoading}
              variant='borderless'
              placeholder={provincePlaceholder}
              className='border border-solid custom-select-item placeholder:capitalize custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px] text-red-500'
              onChange={handleProvinceChange}
            >
              {provinceList?.object?.map((province) => (
                <Option key={province.uid} value={province.province_code}>
                  {province.province}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='region_uid' rules={[{ required: true, message: '' }]}>
            <Select
              suffixIcon={<span className='text-customRed text-base'>*</span>}
              loading={areaListLoading}
              variant='borderless'
              placeholder={areaPlaceholder}
              className='border border-solid custom-select-item placeholder:capitalize custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px] text-red-500'
              onChange={handleAreaChange}
            >
              {areaList?.object.map((area) => (
                <Option key={area.uid} value={area.uid}>
                  {area.area_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col lg={24} md={24} xs={24}>
          <Form.Item
            className='pb-2'
            name='address'
            rules={[
              { required: true, message: '' }, // Message for required field
              { min: 5, message: t('address_min_length', { length: 5 }) }, // Minimum length validation
            ]}
          >
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              bordered={false}
              placeholder={addressPlaceholder}
              className={`border border-solid   custom-form-placeholder-input  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg`}
              onChange={(e) => {
                // setAddressInput(e.target.value);
                form.setFieldsValue({ address: e.target.value }); // Keep form data in sync
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default EditCustomerDetails;
