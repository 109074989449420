import { SVGProps } from 'react';

interface SvgComponentProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const VaultIcon = ({
  width = 48, // default width
  height = 48, // default height
  color = '#38383E', // default color
  ...props
}: SvgComponentProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 48 48' // Use viewBox to maintain aspect ratio
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M43.409 9.718v28.6c0 2.8-2.274 5.075-5.075 5.075H9.704a5.078 5.078 0 0 1-5.075-5.075v-28.6c0-2.801 2.274-5.075 5.074-5.075h28.631c2.801 0 5.075 2.274 5.075 5.075Zm-3 0c0-1.146-.93-2.075-2.075-2.075H9.704c-1.146 0-2.075.93-2.075 2.075v28.6c0 1.145.93 2.075 2.074 2.075h28.631c1.146 0 2.075-.93 2.075-2.075v-28.6Zm-30.658 11.3a1.5 1.5 0 0 1 3 0v6a1.5 1.5 0 0 1-3 0v-6Zm8.593-.661-1.404-1.403a1.5 1.5 0 0 1 2.122-2.121l1.427 1.427a6.72 6.72 0 0 1 3.53-.996c1.37 0 2.646.409 3.711 1.112l1.565-1.565a1.5 1.5 0 0 1 2.122 2.12l-1.609 1.61a6.715 6.715 0 0 1 .965 3.477 6.721 6.721 0 0 1-.996 3.53l1.391 1.392a1.5 1.5 0 1 1-2.12 2.12l-1.368-1.367a6.72 6.72 0 0 1-3.661 1.079 6.72 6.72 0 0 1-3.478-.964l-1.23 1.23a1.5 1.5 0 1 1-2.122-2.12l1.187-1.189a6.723 6.723 0 0 1-1.11-3.711 6.72 6.72 0 0 1 1.078-3.661Zm3.044.984a3.756 3.756 0 0 0 2.631 6.431 3.756 3.756 0 0 0 3.754-3.754 3.756 3.756 0 0 0-3.754-3.754 3.742 3.742 0 0 0-2.6 1.047l-.031.03Z'
      clipRule='evenodd'
    />
  </svg>
);

export default VaultIcon;
