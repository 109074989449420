import { useEffect } from 'react';
import { useFetchUser } from '@/hooksApi';

const useCrisp = () => {
  const { data } = useFetchUser(); // Fetch user data
  useEffect(() => {
    return () => {
      // Listen for Crisp's "chat closed" event to hide the chat automatically
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide']);
        },
      ]);
    };
  }, []);
  // Function to show and open the chat
  const showCrispChat = () => {
    if (window.$crisp) {
      window.$crisp.push(['set', 'session:segments', [['merchant']]]);
      window.$crisp.push(['set', 'user:phone', [`+${data?.object?.phone}`]]);
      window.$crisp.push([
        'set',
        'user:nickname',
        [`${data?.object.first_name} ${data?.object.last_name}`],
      ]);
      // Disable the Helpdesk before opening the chat
      //window.$crisp.push(['do', 'helpdesk:hide']);
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
    }
  };

  // Function to hide the chat
  const hideCrispChat = () => {
    if (window.$crisp) {
      alert();
      window.$crisp.push(['do', 'chat:hide']);
      window.$crisp.push(['do', 'chat:close']);
      window.$crisp.push(['do', 'helpdesk:hide']);
    }
  };

  // Function to trigger helpdesk search
  const triggerHelpdeskSearch = (searchQuery: string = 'How to track my order?') => {
    if (window.$crisp) {
      //   window.$crisp.push(['do', 'chat:hide']);
      //window.$crisp.push(['do', 'chat:hide']);
      window.$crisp.push(['do', 'helpdesk:search', [searchQuery]]);
    }
  };

  return {
    showCrispChat,
    hideCrispChat,
    triggerHelpdeskSearch,
  };
};

export default useCrisp;
