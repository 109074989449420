import { Tag } from 'antd';
import { DotIcon } from '../Icons';

interface StatusTagProps {
  text: string;
  color?: string;
  iconColor?: string;
  className?: string;
}

const StatusTag = ({ text, color, iconColor, className }: StatusTagProps) => {
  return (
    <Tag
      color={color}
      className={`rounded-full text-sm ${className} mt-1`}
      icon={<DotIcon color={iconColor} className='mb-[1px] mx-1' />}
    >
      {text}
    </Tag>
  );
};

export default StatusTag;
