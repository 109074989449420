import React from 'react';
import { SVGProps } from 'react';

interface SupportIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const SupportIcon: React.FC<SupportIconProps> = ({
  width = 24,
  height = 24,
  color = '#38383E',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M4.698 15.758v.003a3.187 3.187 0 0 1-1.06-6.194C3.798 5.087 7.483 1.5 12 1.5c4.514 0 8.198 3.584 8.36 8.058a3.19 3.19 0 0 1-.21 6.095 8.39 8.39 0 0 1-5.284 5.967 1.5 1.5 0 0 1-1.366.88h-3a1.5 1.5 0 1 1 0-3h3c.481 0 .91.227 1.184.58a6.874 6.874 0 0 0 4.171-5.939c.013-1.666.011-4.047.011-4.275A6.87 6.87 0 0 0 12 3a6.87 6.87 0 0 0-6.866 6.866c0 .343.023 5.895.023 5.895l-.459-.003Z'
      clipRule='evenodd'
    />
  </svg>
);

export default SupportIcon;
