// useSearchStore.ts
import { ISearch } from '@/components/TableAction/FilterHeader';
import { create } from 'zustand';

const useSearchStore = create<{
  searchValue: ISearch;
  setSearchValue: (value: ISearch) => void;
}>((set) => ({
  searchValue: { search: null, searchBy: 'filter_platform_code' },
  setSearchValue: (value) => set({ searchValue: value }),
}));

export default useSearchStore;
