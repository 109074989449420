import { SVGProps } from 'react';

interface CompanyIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CompanyIcon = ({
  width = 24,
  height = 24,
  color = '#38383E',
  ...props
}: CompanyIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M14.78 22.163c-.31.196-.68.309-1.074.309h-10.2a2.025 2.025 0 0 1-2.024-2.024V3.522c0-1.117.907-2.024 2.024-2.024h10.2c1.117 0 2.024.907 2.024 2.024v3.22h5.319l.286.028.268.084.242.131.21.173.173.21.132.243.083.268.029.286v12.884l-.029.286-.083.268-.132.242-.173.21-.21.173-.242.131-.268.084-.286.029h-5.393l-.287-.029-.268-.084-.242-.13-.079-.066Zm-.548-1.715V3.522a.526.526 0 0 0-.526-.526h-10.2a.526.526 0 0 0-.526.526v16.926c0 .29.236.526.526.526h10.2c.29 0 .526-.236.526-.526ZM15.73 8.24v12.734h5.244V8.24H15.73Zm3.146 5.243c.078 0 .141.063.141.141v2.34a.142.142 0 0 1-.141.14h-1.132a.142.142 0 0 1-.141-.14v-2.34c0-.078.063-.14.141-.14h1.132Zm0-4.12c.078 0 .141.064.141.141v2.34a.141.141 0 0 1-.141.14h-1.132a.141.141 0 0 1-.141-.14v-2.34c0-.077.063-.14.141-.14h1.132ZM7.229 5.243c.144 0 .262.118.262.262v2.098a.262.262 0 0 1-.262.262H4.757a.263.263 0 0 1-.263-.262V5.505c0-.144.118-.262.263-.262h2.472Zm0 10.862c.144 0 .262.117.262.262v2.097a.262.262 0 0 1-.262.262H4.757a.262.262 0 0 1-.263-.262v-2.097c0-.145.118-.262.263-.262h2.472Zm0-5.244c.144 0 .262.118.262.263v2.097a.262.262 0 0 1-.262.262H4.757a.262.262 0 0 1-.263-.262v-2.097c0-.145.118-.263.263-.263h2.472Zm5.243 5.244c.145 0 .262.117.262.262v2.097a.262.262 0 0 1-.262.262H10a.262.262 0 0 1-.262-.262v-2.097c0-.145.117-.262.262-.262h2.472Zm0-5.244c.145 0 .262.118.262.263v2.097a.262.262 0 0 1-.262.262H10a.262.262 0 0 1-.262-.262v-2.097c0-.145.117-.263.262-.263h2.472Zm0-5.618c.145 0 .262.118.262.262v2.098a.262.262 0 0 1-.262.262H10a.262.262 0 0 1-.262-.262V5.505c0-.144.117-.262.262-.262h2.472Z'
      clipRule='evenodd'
    />
  </svg>
);

export default CompanyIcon;
