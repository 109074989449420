import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Sat',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Sun',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mon',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Tue',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Tur',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Wed',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Fri',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const MixBarChart = () => {
  const { i18n } = useTranslation();
  return (
    <ResponsiveContainer width='100%' height='100%' className='mt-4'>
      <BarChart data={data} barCategoryGap={25}>
        <XAxis dataKey='name' axisLine={false} tickLine={false} />
        <YAxis
          width={35}
          axisLine={false}
          tickLine={false}
          tickMargin={i18n.language === LOCAL_EN ? 0 : 20}
        />
        <Bar dataKey='pv' stackId='a' fill={THEME_VARIABLE.customYellowPrimary} />
        <Bar
          dataKey='uv'
          stackId='a'
          fill={THEME_VARIABLE.customTunaPrimary}
          radius={[20, 20, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MixBarChart;
