import * as React from 'react';
import { SVGProps } from 'react';

interface SvgComponentProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CopyIcon: React.FC<SvgComponentProps> = ({
  width = 15,
  height = 18,
  color = '#38383E',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 15 18'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M4.116 14H1.621c-.826 0-1.497-.67-1.497-1.497V2.45c0-.826.671-1.497 1.497-1.497h6.975c.826 0 1.497.67 1.497 1.497v1.628h2.495c.826 0 1.497.67 1.497 1.497v10.054c0 .826-.671 1.497-1.497 1.497H5.613c-.826 0-1.497-.67-1.497-1.497V14Zm5.977-8.673v7.176c0 .826-.671 1.497-1.497 1.497h-3.23v1.628c0 .136.111.247.247.247h6.975c.136 0 .247-.11.247-.247V5.574a.247.247 0 0 0-.247-.247h-2.495ZM1.62 12.75h6.975c.136 0 .247-.11.247-.247V2.45a.247.247 0 0 0-.247-.247H1.62a.247.247 0 0 0-.247.247v10.054c0 .136.111.247.247.247Z'
      clipRule='evenodd'
    />
  </svg>
);
export default CopyIcon;
