import { ErrorObject } from '@/types';

export function handleError(error: ErrorObject): string | null {
  for (const key in error) {
    if (Object.prototype.hasOwnProperty.call(error, key) && error[key].length > 0) {
      return error[key][0];
    }
  }
  return null;
}
