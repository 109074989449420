import { SVGProps } from 'react';

interface CustomerNotificationIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CustomerNotificationIcon = ({
  width = 24,
  height = 24,
  color = '#38383E',
  ...props
}: CustomerNotificationIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill={color}
        fillRule='evenodd'
        d='M11.967 2.375c2.706 0 4.9 2.162 4.9 4.829v1.932c0 1.786-.985 3.347-2.45 4.182v.163l1.182.621c.095.05.131.168.081.262l-.002.004-.52.931a.194.194 0 0 1-.259.077l-1.85-.972a.195.195 0 0 1-.104-.172l.005-1.639c0-.07.037-.133.097-.168l.634-.36a3.373 3.373 0 0 0 1.714-2.8l.002-.13V7.205c0-1.867-1.535-3.38-3.43-3.38-1.855 0-3.366 1.45-3.428 3.263l-.002.117v1.932c0 1.177.615 2.248 1.605 2.862l.11.066.637.363c.06.034.098.099.098.169v1.637c0 .072-.04.138-.104.172l-6.067 3.189a1.77 1.77 0 0 0-.943 1.764c.054.478.446.845.926.881l.077.003h8.994c.07 0 .135.037.169.099l.592 1.061a.194.194 0 0 1-.168.288H4.876c-1.264 0-2.325-.936-2.464-2.173a3.213 3.213 0 0 1 1.712-3.201l5.393-2.835v-.162c-1.465-.836-2.45-2.396-2.45-4.183V7.204c0-2.667 2.194-4.829 4.9-4.829Zm5.217 18.475h-1.14l-.205-.02-.194-.06-.18-.095-.159-.13-.13-.16-.096-.179-.059-.194-.02-.205v-.53l.034-.264.1-.248.203-.361v-.555a3.225 3.225 0 0 1 3.225-3.224h.001a3.225 3.225 0 0 1 3.224 3.224v.555l.204.361.1.248.034.264v.53l-.02.205-.06.194-.095.18-.13.159-.16.13-.18.096-.194.059-.204.02h-1.028a1.596 1.596 0 0 1-2.87 0Zm-.657-1.5H20.6l-.208-.37-.077-.19-.027-.205v-.736a1.725 1.725 0 0 0-1.724-1.724h-.002a1.724 1.724 0 0 0-1.724 1.724v.75c0 .13-.033.257-.096.369l-.215.382Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default CustomerNotificationIcon;
