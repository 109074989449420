// sentryConfig.ts
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

const getEnvironment = () => {
  if (window.location.hostname.includes('localhost')) {
    return 'local';
  } else if (window.location.hostname.includes('dashboard-stg')) {
    return 'stg';
  } else if (window.location.hostname.includes('dashboard')) {
    return 'prod';
  }
  return 'prod'; // Default to production if no match
};

export const initSentry = () => {
  const environment = getEnvironment();

  // Only initialize Sentry if the environment is not 'local'
  if (environment !== 'local') {
    Sentry.init({
      dsn: 'https://e3289083ae24cb1d9a5a8baa62ff98fe@o4507878645628928.ingest.us.sentry.io/4508176647585792',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      replaysSessionSampleRate: environment === 'prod' ? 0.2 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: environment === 'prod' ? 1.0 : 0.3, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      tracesSampleRate: environment === 'prod' ? 0.2 : 1.0, // Adjust as needed
      profilesSampleRate: environment === 'prod' ? 0.1 : 1.0, // Lower rate for production
      tracePropagationTargets: ['localhost', 'dashboard-stg.tryboxy.dev', 'dashboard.tryboxy.com'],
      environment, // Set environment dynamically
    });
  }
};
