import { SVGProps } from 'react';

interface PickupIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const PickupIcon = ({
  width = 16,
  height = 17,
  fillColor = '#38383E',
  ...props
}: PickupIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 17'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip)'>
      <path
        fill={fillColor}
        fillRule='evenodd'
        d='M5.01 11.785h-.495a1.372 1.372 0 0 1-1.371-1.372V3.44c0-.757.614-1.372 1.371-1.372h6.981c.757 0 1.372.615 1.372 1.372v6.973c0 .757-.615 1.372-1.372 1.372h-.494c.002.053.004.105.004.159 0 1.656-1.345 3-3 3a3.001 3.001 0 0 1-2.996-3.159Zm1.11-8.717H4.514a.372.372 0 0 0-.371.372v6.973c0 .205.166.372.371.372h.723a3.003 3.003 0 0 1 5.535 0h.723a.372.372 0 0 0 .372-.372V3.44a.372.372 0 0 0-.372-.372H9.892v1.975l-.016.156-.045.146-.072.132-.094.114-.114.094-.132.072-.146.045-.156.016H6.894l-.155-.016-.147-.045-.131-.072-.115-.094-.094-.114-.071-.132-.046-.146-.016-.156V3.068Zm2.772 0H7.12v1.75h1.773v-1.75Zm-.886 10.876a2.001 2.001 0 0 0 0-4 2.001 2.001 0 0 0 0 4Zm-.5-2.114-.255.233a.5.5 0 0 1-.675-.738l1.092-1a.5.5 0 0 1 .675 0l1.093 1a.5.5 0 0 1-.676.738l-.254-.233v1.114a.5.5 0 0 1-1 0V11.83Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='clip'>
        <rect width={width} height={height} />
      </clipPath>
    </defs>
  </svg>
);

export default PickupIcon;
