import { create } from 'zustand';

interface SelectState {
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

const useSelectStore = create<SelectState>((set) => ({
  selectedValue: 'last_week',
  setSelectedValue: (value) => set({ selectedValue: value }),
}));

export default useSelectStore;
