import { Flex, Typography } from 'antd';
import React from 'react';
import { Heading } from '../Heading';
import { TLStatusIcon } from '../Icons';

const { Text } = Typography;

interface NotifCardProps {
  title: string;
  time: string;
  iconColor: string;
  description: string;
}

const NotificationCard: React.FC<NotifCardProps> = ({ title, time, iconColor, description }) => {
  return (
    <Flex vertical className='mb-6'>
      <Flex className=' flex justify-between items-center mb-[14px]'>
        <Heading
          title={title}
          fontWeight='font-bold'
          fontSize='text-xs'
          extraClassName='leading-[18px] capitalize'
        />
        <Flex className='flex items-center gap-x-1 '>
          <Text className='font-normal text-xs leading-[18px] text-customGrayShade5'>{time}</Text>
          <TLStatusIcon color={iconColor} />
        </Flex>
      </Flex>
      <Flex className='flex justify-center items-center'>
        <Text className='font-normal px-[19px] text-[10px] whitespace-normal leading-[15px] text-customTunaPrimary'>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default NotificationCard;
