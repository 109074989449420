import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ImportIcon = ({ width = 18, height = 13, color = '#38383E', ...props }: CloseIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      d='m9.625 6.742 1.12-1.121a.625.625 0 0 1 .885.884L9.442 8.692a.625.625 0 0 1-.884 0L6.371 6.505a.626.626 0 0 1 .884-.884l1.12 1.12V.752a.625.625 0 0 1 1.25 0v5.99ZM13.063 4.5a.625.625 0 0 1 0-1.25h2.572c.79 0 1.431.642 1.431 1.431v6.448c0 .79-.641 1.43-1.431 1.43H2.38c-.79 0-1.43-.64-1.43-1.43V4.68c0-.79.64-1.43 1.43-1.43h2.558a.625.625 0 0 1 0 1.25H2.38c-.1 0-.18.08-.18.18v6.448c0 .1.08.18.18.18h13.255a.18.18 0 0 0 .18-.18V4.68c0-.1-.08-.18-.18-.18h-2.572Z'
      clipRule='evenodd'
    />
  </svg>
);
export default ImportIcon;
