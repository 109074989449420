import React from 'react';
import { Col, Flex, Row, Typography } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { LOCAL_EN } from '@/config';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

// Step 1: Define the Props interface
interface CustomerDetailCardProps {
  title: string;
  nameLabel: string;
  name: string;
  phoneLabel: string;
  phoneNumber: string;
  regionLabel: string;
  region: string;
  cityLabel: string;
  city: string;
  addressLabel: string;
  address: string;
}

// Step 2: Update the component to accept props
const CustomerDetailCard: React.FC<CustomerDetailCardProps> = ({
  title,
  nameLabel,
  name,
  phoneLabel,
  phoneNumber,
  regionLabel,
  region,
  cityLabel,
  city,
  addressLabel,
  address,
}) => {
  const { i18n } = useTranslation();
  return (
    <Card>
      <Heading
        title={title} // Use the title prop
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />

      <Row gutter={[24, 16]} className='mt-[17px]'>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {nameLabel} {/* Use the nameLabel prop */}
            </Text>
            <Text className=' capitalize font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {name} {/* Use the name prop */}
            </Text>
          </Flex>
        </Col>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {phoneLabel} {/* Use the phoneLabel prop */}
            </Text>
            <Text
              style={{ direction: 'ltr' }}
              className={` ${i18n.language === LOCAL_EN ? 'text-left' : 'text-right'} font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary`}
            >
              {phoneNumber} {/* Use the phoneNumber prop */}
            </Text>
          </Flex>
        </Col>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {regionLabel} {/* Use the regionLabel prop */}
            </Text>
            <Text className=' font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {region} {/* Use the region prop */}
            </Text>
          </Flex>
        </Col>
      </Row>
      <Row gutter={[24, 16]} className='mt-[17px]'>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {cityLabel} {/* Use the cityLabel prop */}
            </Text>
            <Text className=' capitalize font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {city} {/* Use the city prop */}
            </Text>
          </Flex>
        </Col>
        <Col lg={17} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {addressLabel} {/* Use the addressLabel prop */}
            </Text>
            <Text className=' capitalize font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {address} {/* Use the address prop */}
            </Text>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default CustomerDetailCard;
