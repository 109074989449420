import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const TLStatusIcon = ({
  width = 12,
  height = 12,
  color = '#1677FF', // Default color for the fill of the rect
  ...props
}: CustomSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 12 12'
    fill='none'
    {...props}
  >
    <rect width={11} height={11} x={0.5} y={0.5} fill={color} stroke='#fff' rx={5.5} />
  </svg>
);

export default TLStatusIcon;
