import React from 'react';
import { SVGProps } from 'react';

interface ReturnIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
  backgroundColor?: string;
}

const ReturnIcon: React.FC<ReturnIconProps> = ({
  width = 40,
  height = 40,
  fillColor = '#38383E',
  backgroundColor = '#F5F7FA',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    {...props}
  >
    <rect width={width} height={height} fill={backgroundColor} rx={20} />
    <g clipPath='url(#clip)'>
      <path
        fill={fillColor}
        d='M12.654 15.568l.798.798a.682.682 0 0 1-.964.965l-1.834-1.834a.682.682 0 0 1 0-.964l1.834-1.833a.682.682 0 0 1 .964.964l-.541.54H23.41a6.136 6.136 0 0 1 6.136 6.136v.002a6.136 6.136 0 0 1-6.136 6.135H11.137a.682.682 0 0 1 0-1.363H23.41a4.772 4.772 0 0 0 4.772-4.772v-.002a4.772 4.772 0 0 0-4.772-4.772H12.654Z'
      />
    </g>
    <defs>
      <clipPath id='clip'>
        <rect width={width} height={height} />
      </clipPath>
    </defs>
  </svg>
);

export default ReturnIcon;
