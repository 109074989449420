import React from 'react';
import { THEME_VARIABLE } from '@/config';
import { Flex, Skeleton, Typography } from 'antd';
import { useEditModalTotalPriceStore } from '@/hooks';

const { Text } = Typography;

// Define the props interface
interface OrderShipmentValueEditCardProps {
  orderValueText: string;
  shipmentFeeText: string;
  totalText: string;
  currency: string;
}

const EditOrderShipment: React.FC<OrderShipmentValueEditCardProps> = ({
  orderValueText,
  totalText,
  currency,
}) => {
  const totalPrice = useEditModalTotalPriceStore((state) => state.totalPrice);

  return (
    <Flex
      vertical
      className='py-3 px-4 border border-dashed border-customGrayShade4 rounded-md mt-4'
    >
      <Flex
        className='flex justify-between items-center pb-2'
        style={{ borderBottom: `1px solid ${THEME_VARIABLE.customGrayShade4}` }}
      >
        <Text className='font-normal capitalize text-sm leading-[18px] text-customTunaPrimary'>
          {orderValueText}
        </Text>
        <Flex className='gap-x-1'>
          <>
            <Text className='font-bold text-sm leading-[18px] text-customTunaPrimary'>
              {totalPrice.toLocaleString()}
            </Text>
            <Text className='font-normal uppercase text-sm leading-[18px] text-customTunaPrimary'>
              {` ${currency}`}
            </Text>
          </>
          {/* )} */}
        </Flex>
      </Flex>
      <Flex className='flex justify-between items-center mt-3'>
        <Text className='font-bold text-base capitalize leading-[18px] text-customTunaPrimary'>
          {totalText}
        </Text>
        <Flex className='gap-x-1'>
          <>
            <Text className='font-bold text-base leading-[18px] text-customTunaPrimary'>
              {totalPrice.toLocaleString()}
            </Text>
            <Text className='font-normal text-base uppercase leading-[18px] text-customTunaPrimary'>
              {` ${currency}`}
            </Text>
          </>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EditOrderShipment;
