// stores/usePickupDropStore.ts

import { create } from 'zustand';

interface PickupDropState {
  orderUid: string;

  setOrderUidEdit: (uid: string) => void;
}

const useOrderUidEdit = create<PickupDropState>((set) => ({
  orderUid: '',

  setOrderUidEdit: (value) => set({ orderUid: value }),
}));

export default useOrderUidEdit;
