import { SVGProps } from 'react';

interface TrashIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const TrashIcon = ({ width = 20, height = 21, color = '#38383E', ...props }: TrashIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      d='M14.5 0A1.5 1.5 0 0 1 16 1.5V3h3.3c.11 0 .2.09.2.2v1.1a.2.2 0 0 1-.2.2h-.8V18a3 3 0 0 1-3 3h-11a3 3 0 0 1-3-3V4.5H.7a.2.2 0 0 1-.2-.2V3.2c0-.11.09-.2.2-.2H4V1.5A1.5 1.5 0 0 1 5.5 0h9ZM17 4.5H3V18a1.5 1.5 0 0 0 1.412 1.497l.088.003h11a1.5 1.5 0 0 0 1.497-1.412L17 18V4.5Zm-9.2 3c.11 0 .2.09.2.2v8.6a.2.2 0 0 1-.2.2H6.7a.2.2 0 0 1-.2-.2V7.7c0-.11.09-.2.2-.2h1.1Zm6 0c.11 0 .2.09.2.2v8.6a.2.2 0 0 1-.2.2h-1.1a.2.2 0 0 1-.2-.2V7.7c0-.11.09-.2.2-.2h1.1Zm.7-6h-9V3h9V1.5Z'
    />
  </svg>
);
export default TrashIcon;
