import { create } from 'zustand';

interface PickupDropState {
  importTableUid: string;

  setImportTableUid: (uid: string) => void;
}

const useImportTableUid = create<PickupDropState>((set) => ({
  importTableUid: '',
  setImportTableUid: (value) => set({ importTableUid: value }),
}));

export default useImportTableUid;
