import { create } from 'zustand';

// Define the shape of the store's state
interface OrderStore {
  full_name: string;
  platform_code: string;
  phone: string;
  order_value: string;
  addressTitle: string;

  // Actions (setters) to update the state
  setFullName: (full_name: string) => void;
  setPlatformCode: (platform_code: string) => void;
  setPhone: (phone: string) => void;
  setOrderValue: (order_value: string) => void;
  setAddressTitle: (addressTitle: string) => void;
}

// Create the Zustand store with initial values and actions
const useResolveModalStore = create<OrderStore>((set) => ({
  full_name: '',
  platform_code: '',
  phone: '',
  order_value: '',
  addressTitle: '',

  // Action to set full_name
  setFullName: (value) =>
    set({
      full_name: value,
    }),

  // Action to set platform_code
  setPlatformCode: (value) =>
    set({
      platform_code: value,
    }),

  // Action to set phone
  setPhone: (value) =>
    set({
      phone: value,
    }),

  // Action to set order_value
  setOrderValue: (value) =>
    set({
      order_value: value,
    }),

  // Action to set addressTitle
  setAddressTitle: (value) =>
    set({
      addressTitle: value,
    }),
}));

export default useResolveModalStore;
