import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Heading } from '../Heading';
import {
  AutoComplete,
  Col,
  Flex,
  Form,
  FormInstance,
  Image,
  Input,
  message,
  Row,
  Select,
  Spin,
  Typography,
  Checkbox,
} from 'antd';
// import { useCustomerDetailsForm } from '@/hooks';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { useEffect, useState } from 'react';
import { countries } from '@/utils/CountryCodes';
import { useDeleteContact, useFetchContact, useFetchContacts } from '@/hooksApi';
import { DeleteIcon } from '../Icons';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchAreaByProvinceCode, useFetchListProvinces } from '@/hooksApi/useRegion';
import { useContactsStore } from '@/stores/contactStore';
import { RegionItem } from '@/types/region';
import { separateCountryCodeFromPhone } from '@/utils';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
// import { convertArabicToEnglishNumbers, handlePhoneInput } from '@/utils';

// import { ContactRequest, responseContactByUid } from '@/types';

const { Text } = Typography;
const { Option } = Select;
export interface ContactData {
  full_name?: string;
  phone?: string;
  address_text?: string;
  region?: RegionItem;
  city?: string;
}

interface CustomerDetailsPropsCard {
  form: FormInstance; // Use the FormInstance type from Ant Design;
  title: string;
  namePlaceholder: string;
  primaryPhonePlaceholder: string;
  secondaryPhonePlaceholder: string;
  provincePlaceholder: string;
  areaPlaceholder: string;
  addressPlaceholder: string;
  saveToAddress: string;
  resetForm?: boolean;
  contactData?: ContactData;
  selectedProvinceCode?: string;
  setSelectedProvinceCode: (provinceCode?: string) => void;
}

const CustomerDetailsCard = ({
  form,
  title,
  namePlaceholder,
  primaryPhonePlaceholder,
  secondaryPhonePlaceholder,
  provincePlaceholder,
  areaPlaceholder,
  addressPlaceholder,
  saveToAddress,
  resetForm, // Step 1: Receive resetForm prop
  contactData,
  selectedProvinceCode,
  setSelectedProvinceCode,
}: CustomerDetailsPropsCard) => {
  const [selectedUid, setSelectedUid] = useState<string | null>(null);
  const [saveAddressChecked, setSaveAddressChecked] = useState(false);

  const { t } = useTranslation();

  // Use the hook to fetch contacts
  const { data: contacts, isLoading: contactsLoading } = useFetchContacts();

  // Fetch individual contact by UID
  const { data: contactDetails } = useFetchContact(selectedUid || '');

  const { data: provinceList, isLoading: provinceListLoading } = useFetchListProvinces();
  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );

  const nameOptions = contacts?.object?.items.map((contact) => ({
    value: contact.full_name,
    uid: contact.uid, // Store the uid for later use
  }));

  useEffect(() => {
    if (selectedUid && contactDetails) {
      const contact = contactDetails.object;

      setSelectedProvinceCode(contact.region?.province_code || '');

      // Check if contact data exists before setting the fields
      if (contact) {
        const phoneInfo = separateCountryCodeFromPhone(contact.phone);
        // sae for secondary phone
        form.setFieldsValue({
          name: contact.full_name || '',
          phoneNumberPrimary: phoneInfo.phone, // Set phone number without country code
          countryCodePrimary: phoneInfo.countryCode, // Set country code
          address: contact.address_text || '',
          province: contact.region?.province || '',
          region_uid: contact.region?.uid || '',
        });

        // Set selected province code to fetch area list for the province
      }
    }
  }, [selectedUid, contactDetails, form]);

  // Handle province change to fetch area list based on selected province code
  const handleProvinceChange = (provinceCode: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });

    setSelectedProvinceCode(provinceCode);
  };

  // handleAreaChange to save area UID to Zustand store and set the Field Value in form
  const handleAreaChange = (value: string) => {
    form.setFieldsValue({ region_uid: value });
  };

  const queryClient = useQueryClient();

  // Handle delete action
  const { mutate: deleteContact } = useDeleteContact(); // Use the selected UID for deletion
  const handleDeleteContact = (uid: any) => {
    deleteContact(uid, {
      onSuccess: () => {
        queryClient.refetchQueries(['contacts']);
        message?.success(t('contact_removed'));
      },
      onError: (error) => {
        message.error(error?.response?.data?.message || t('failed_to_remove_contact'));
      },
    });
  };

  // Handle name selection
  const handleNameSelect = (name: string) => {
    const selectedContact = nameOptions?.find((option) => option.value === name);

    if (selectedContact) {
      // Store the selected UID to fetch full contact details
      setSelectedUid(selectedContact.uid);
      const contactDetail = contacts?.object?.items.find(
        (contact) => contact.uid === selectedContact.uid,
      );

      if (contactDetail) {
        const phoneInfo = separateCountryCodeFromPhone(contactDetail.phone);
        // const secondaryPhoneInfo = separateCountryCodeFromPhone(contactDetail.);
        const phone = contactDetail.phone;
        const countryCode = phone.startsWith('964') ? '964' : '98';

        // setPrimaryPhoneInput(phone.replace(`${countryCode}`, '').trim());
        // setPrimaryCountryCode(`+${countryCode}`);

        form.setFieldsValue({
          phoneNumberPrimary: phoneInfo.phone,
          countryCodePrimary: phoneInfo.countryCode,
        });
      }
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setSaveAddressChecked(e.target.checked);
  };

  const filterOptions = (input: string, option: any) => {
    const optionChildren = option.children;
    return optionChildren.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Row gutter={[24, 16]} className='mt-[17px]'>
        <Col lg={12} md={24} xs={24}>
          <Form.Item rules={[{ required: true, message: '' }]} name='name'>
            <AutoComplete
              suffixIcon={<span className='text-customRed text-base'>*</span>}
              bordered={false}
              options={
                contactsLoading
                  ? []
                  : nameOptions?.map((option) => ({
                      value: option.value,
                      label: (
                        <div className='flex justify-between items-center'>
                          <span>{option.value}</span>
                          <DeleteIcon
                            className='cursor-pointer'
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the AutoComplete from closing
                              handleDeleteContact(option.uid);
                            }}
                            width={19}
                            height={21}
                            color={THEME_VARIABLE.customTunaPrimary}
                          />
                        </div>
                      ),
                    }))
              } // Customize the label with the trash icon
              placeholder={namePlaceholder}
              className='border custom-select-placeholder border-solid  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) => {
                // setNameInput(value);
                form.setFieldsValue({ name: value });
              }}
              onSelect={handleNameSelect} // Use onSelect to handle name selection
            >
              {contactsLoading && (
                <Option key='loading'>
                  <Spin size='small' />
                </Option>
              )}
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={[24, 16]}
        // style={{ direction: 'ltr' }}
      >
        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          <Form.Item
            name='countryCodePrimary'
            initialValue={'+964'}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              variant='borderless'
              style={{ direction: 'ltr' }}
              className='border border-solid custom-select-item custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px]'
              dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
            >
              {countries.map((country, index) => (
                <Option key={index} value={country.dialCodes?.[0]}>
                  <span role='img'>
                    <Image preview={false} src={country.image} width={20} height={20} />
                  </span>{' '}
                  ({country.dialCodes?.[0]})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className='w-full'
            name='phoneNumberPrimary'
            rules={[{ required: true, message: '' }]}
          >
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              bordered={false}
              autoComplete='off'
              style={{ direction: 'ltr' }}
              placeholder={primaryPhonePlaceholder}
              className='border border-solid custom-form-placeholder-input  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          <Form.Item
            initialValue={'+964'}
            name='countryCodeSecondary'
            rules={[{ required: true, message: '' }]}
          >
            <Select
              variant='borderless'
              style={{ direction: 'ltr' }}
              className='border border-solid custom-select-placeholder custom-select-item border-customGrayShade5 h-[56px] rounded-[10px]'
              // onChange={(value) => setSecondaryCountryCode(value)}
              dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
            >
              {countries.map((country, index) => (
                <Option key={index} value={country.dialCodes?.[0]}>
                  <span role='img'>
                    <Image preview={false} src={country.image} width={20} height={20} />
                  </span>{' '}
                  ({country.dialCodes?.[0]})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className='w-full' name='secondaryPhoneNumber'>
            <Input
              bordered={false}
              autoComplete='off'
              style={{ direction: 'ltr' }}
              placeholder={secondaryPhonePlaceholder}
              className='border border-solid custom-form-placeholder-input  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='province' rules={[{ required: true, message: '' }]}>
            <Select
              suffixIcon={<span className='text-customRed text-base'>*</span>}
              loading={provinceListLoading}
              variant='borderless'
              placeholder={provincePlaceholder}
              className='border border-solid custom-select-item placeholder:capitalize custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px] text-red-500'
              onChange={handleProvinceChange}
              showSearch
              filterOption={filterOptions}
            >
              {provinceList?.object?.map((province) => (
                <Option key={province.uid} value={province.province_code}>
                  {province.province}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='region_uid' rules={[{ required: true, message: '' }]}>
            <Select
              suffixIcon={<span className='text-customRed text-base'>*</span>}
              loading={areaListLoading}
              variant='borderless'
              placeholder={areaPlaceholder}
              className='border border-solid custom-select-item placeholder:capitalize custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px] text-red-500'
              onChange={handleAreaChange}
              showSearch
              filterOption={filterOptions}
            >
              {areaList?.object.map((area) => (
                <Option key={area.uid} value={area.uid}>
                  {area.area_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col lg={24} md={24} xs={24}>
          <Form.Item
            className='pb-2'
            name='address'
            rules={[
              { required: true, message: '' }, // Message for required field
              { min: 5, message: t('address_min_length', { length: 5 }) }, // Minimum length validation
            ]}
          >
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              bordered={false}
              placeholder={addressPlaceholder}
              className={`border border-solid   custom-form-placeholder-input  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg`}
              onChange={(e) => {
                // setAddressInput(e.target.value);
                form.setFieldsValue({ address: e.target.value }); // Keep form data in sync
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {!selectedUid && (
          <Form.Item
            initialValue={saveAddressChecked} // Set the initial value
            className='mt-[6px] mb-0'
            name='saveToAddress'
            valuePropName='checked'
          >
            <Checkbox
              checked={saveAddressChecked}
              onChange={handleCheckboxChange}
              className='text-customTunaPrimary custom-register-checkbox  -mt-[27.5px] flex items-center justify-center tracking-[-.32px] font-[400] text-[14px] leading-[11px]'
            >
              <Flex className='flex items-center gap-x-1'>
                <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary'>
                  {saveToAddress}
                </Text>
              </Flex>
            </Checkbox>
          </Form.Item>
        )}
      </Row>
    </Card>
  );
};

export default CustomerDetailsCard;
