import { ReactNode } from 'react';
import { Layout, Row } from 'antd';
import { Header } from '@/components';
import { Sidebar } from '@/components/Sidebar';
import { useTranslation } from 'react-i18next';
import { LOCAL_EN } from '@/config';

interface MainProps {
  children: ReactNode;
}

const Main = ({ children }: MainProps) => {
  const { i18n } = useTranslation();

  return (
    <Layout className='min-h-screen p-6'>
      <Sidebar />
      <Layout className={i18n.language === LOCAL_EN ? 'pl-6' : 'pr-6'}>
        <Row className='mt-4 md:mt-0 flex flex-col'>
          <Header />
          {children}
        </Row>
      </Layout>
    </Layout>
  );
};

export default Main;
