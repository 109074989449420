interface RouteInfo {
  location: string;
  name: string;
}

// Define your routes with corresponding sidebar names
const sidebarRoutes: { [key: string]: string } = {
  orders: 'orders',
  pickups: 'pickups',
  finance: 'finance',
  dashboard: 'dashboard',
  import: 'import',
  settings: 'settings',
};

export function identifySelectRoute(pathname: string): RouteInfo | null {
  // Handle routes that should not activate any sidebar item
  if (pathname === '/no-data' || pathname === '/400' || pathname === '/500' || pathname === '*') {
    return null; // Return null for no-data, 400, and 500 routes
  }
  for (const route in sidebarRoutes) {
    if (pathname.includes(route)) {
      return { location: route, name: sidebarRoutes[route] };
    }
  }

  // Default return if no match is found
  return { location: '/', name: 'Unknown' };
}

export default identifySelectRoute;
