import { SVGProps } from 'react';

interface BoxLargeIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BoxLargeIcon = ({
  width = 56,
  height = 56,
  color = '#939496',
  ...props
}: BoxLargeIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 56 56' // Ensures proper scaling based on the provided width and height
    {...props}
  >
    <g fill={color} fillRule='evenodd' clipPath='url(#a)' clipRule='evenodd'>
      <path d='m11.815 19.78-7.82-3.644a.874.874 0 0 1 .001-1.586L27.55 3.582a.876.876 0 0 1 .74 0l13.986 6.52.087.039.048.023 9.453 4.406a.876.876 0 0 1 0 1.586L28.309 27.124a.877.877 0 0 1-.739 0l-7.443-3.469v6.533a.876.876 0 0 1-1.522.588l-3.091-3.406-2.498 1.004a.875.875 0 0 1-1.201-.811V19.78ZM33.384 7.888 27.92 5.341 6.437 15.343l5.817 2.711.056-.03L33.385 7.887ZM21.296 22.27l6.644 3.097 21.482-10.003-7.408-3.453-20.718 10.359Zm-7.731-1.673v5.672l1.86-.75a.875.875 0 0 1 .975.225l1.977 2.179V22.84l-4.812-2.244Zm5.706.73 20.696-10.35-4.62-2.091-21.046 10.124 4.97 2.316Z' />
      <path d='M28.878 49.867 50.62 39.742v-24.43a.875.875 0 1 1 1.75 0V40.3c0 .34-.197.65-.506.793L28.31 52.062a.876.876 0 0 1-.739 0L3.997 41.074a.875.875 0 0 1-.506-.794V15.313a.875.875 0 1 1 1.75 0v24.41l21.887 10.201V26.688a.875.875 0 1 1 1.75 0v23.179Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h56v56H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default BoxLargeIcon;
