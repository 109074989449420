import { SVGProps } from 'react';

interface TruckOutlineIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const TruckOutlineIcon = ({
  width = 33,
  height = 32,
  color = '#38383E',
  ...props
}: TruckOutlineIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <circle cx={24} cy={24.5} r={2.5} fill='#EBDD4C' />
    <circle cx={11} cy={24.5} r={2.5} fill='#EBDD4C' />
    <path
      fill={color}
      d='M19.488 8.111V9.39h6.296a2.43 2.43 0 0 1 1.621.621l.1.095 2.226 2.235c.423.424.676.99.71 1.59l.003.139v9.376a2.44 2.44 0 0 1-2.313 2.441l-.121.003h-.735a3.347 3.347 0 0 1-4.136 2.317 3.356 3.356 0 0 1-2.308-2.317h-6.947a3.347 3.347 0 0 1-4.137 2.317A3.356 3.356 0 0 1 7.44 25.89H4.88a2.44 2.44 0 0 1-2.436-2.445V8.11A2.44 2.44 0 0 1 4.88 5.667h12.174a2.44 2.44 0 0 1 2.435 2.444ZM9.14 24.973c0 .843.681 1.527 1.522 1.527.84 0 1.521-.684 1.521-1.527 0-.844-.68-1.528-1.521-1.528-.84 0-1.522.684-1.522 1.528Zm13.391 0c0 .843.682 1.527 1.522 1.527s1.522-.684 1.522-1.527c0-.844-.682-1.528-1.522-1.528s-1.522.684-1.522 1.528ZM4.88 7.5a.61.61 0 0 0-.604.54l-.005.071v15.334c0 .31.231.57.538.607l.071.004h2.56a3.347 3.347 0 0 1 4.137-2.317 3.356 3.356 0 0 1 2.307 2.317h3.779V8.11a.61.61 0 0 0-.469-.595l-.069-.012-.071-.004H4.879Zm14.609 3.723v12.833h1.343a3.347 3.347 0 0 1 4.136-2.317 3.356 3.356 0 0 1 2.308 2.317h.735l.07-.004a.61.61 0 0 0 .534-.536l.004-.071v-9.377l-.005-.08a.611.611 0 0 0-.12-.291l-.053-.061-2.226-2.234-.06-.053a.606.606 0 0 0-.29-.12l-.08-.006h-6.296Z'
    />
  </svg>
);
export default TruckOutlineIcon;
