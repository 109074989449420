import { create } from 'zustand';
import { Remittance } from '@/types';

interface RemittanceStore {
  remittances: Remittance[];
  setRemittances: (remittances: Remittance[]) => void;
  addRemittance: (remittance: Remittance) => void;
}

const useRemittanceStore = create<RemittanceStore>((set) => ({
  remittances: [],

  // Set multiple remittances (e.g., after fetching data from API)
  setRemittances: (remittances) => set({ remittances }),

  // Add a new remittance
  addRemittance: (remittance) =>
    set((state) => ({
      remittances: [...state.remittances, remittance],
    })),
}));

export default useRemittanceStore;
