import { SVGProps } from 'react';

interface StepIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const StepTickIcon = ({ width = 28, height = 28, color = '#EBDD4C', ...props }: StepIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={29} height={28} fill='none' {...props}>
    <rect width={width} height={height} x={0.5} fill={color} rx={14} />
    <path
      fill='#38383E'
      d='m19.577 11.171-5.773 5.72a.376.376 0 0 1-.563-.039.358.358 0 0 1-.04-.034l-3.779-3.745a.1.1 0 0 1 .07-.171h.773a.1.1 0 0 1 .07.029l3.205 3.175 5.124-5.077a.1.1 0 0 1 .07-.029h.773a.1.1 0 0 1 .07.171Z'
    />
  </svg>
);
export default StepTickIcon;
