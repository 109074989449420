import { LOCAL_EN } from '@/config';
import { LanguageStore } from '@/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useLanguageStore = create<LanguageStore>()(
  persist(
    (set) => ({
      language: 'ar',
      setLanguage: (lang) => set({ language: lang }),
    }),
    {
      name: 'language-storage',
      getStorage: () => localStorage,
    },
  ),
);

export default useLanguageStore;
