import { Col, Flex, Image } from 'antd';
import { SupportIcon } from '@/components';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import image1 from '/login-banner.jpg';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */
interface RegisterImageCardProps {
  onImageLoad: () => void; // Explicitly type the prop
}

const LoginImageCard: React.FC<RegisterImageCardProps> = ({ onImageLoad }) => {
  const { i18n } = useTranslation();

  return (
    <Col span={14} className='relative'>
      <Image
        width='100%'
        height='628px'
        src={image1}
        alt='Login Visual'
        preview={false}
        onLoad={() => {
          onImageLoad(); // Notify parent that image has loaded
        }}
        className={`object-cover object-left-top max-h-[78vh]  w-full ${
          i18n.language === LOCAL_EN ? 'rounded-r-[20px]' : 'rounded-l-[20px]'
        }`}
      />
      {/* <Flex
        className={`absolute  m-[55px] flex justify-center items-center cursor-pointer w-[54px] h-[54px] bg-customTunaPrimary rounded-full ${
          i18n.language === LOCAL_EN ? 'top-0 right-0' : 'top-0 left-0'
        }`}
      >
        <SupportIcon width={30.55} height={30.55} color={THEME_VARIABLE.customYellowPrimary}  />
      </Flex> */}
    </Col>
  );
};

export default LoginImageCard;
