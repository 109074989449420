import React from 'react';
import { Radio, Typography } from 'antd';

const { Text } = Typography;

interface CustomRadioProps {
  title: string;
  selected: boolean;
  onSelect: () => void;
  className?: string;
}

const CustomRadio: React.FC<CustomRadioProps> = ({ title, selected, onSelect, className }) => {
  return (
    <Radio
      checked={selected}
      onChange={onSelect}
      className={`custom-radio-small uppercase font-bold text-lg leading-[20.88px] h-[54px] rounded-[10px] flex items-center px-4 cursor-pointer  ${
        selected
          ? 'text-customTunaPrimary bg-customYellowPrimary border border-solid border-customTunaPrimary'
          : 'text-customGrayShade5 bg-transparent border border-solid border-customGrayShade4'
      } ${className ?? ''} `}
    >
      <Text className='whitespace-nowrap'>{title}</Text>
    </Radio>
  );
};

export default CustomRadio;
