import { axiosJSONInstance } from '@/config';
import { PickupDataResponse, PickUpParams, PickupResponse, PickUpSingleResponse } from '@/types';

// Fetch Pickups
export const fetchPickups = async (page: number, pageSize?: number): Promise<PickupResponse> => {
  const response = await axiosJSONInstance.get(
    `/merchants/pick-ups?page=${page}&perpage=${pageSize || 25}`,
  );
  return response.data;
};

// Fetch Single Pickup By ID
export const fetchSinglePickupById = async (uid: string): Promise<PickUpSingleResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/pick-ups/${uid}`);
  return response.data;
};

// request Pickup
export const requestPickup = async (data: PickUpParams): Promise<PickupDataResponse> => {
  const response = await axiosJSONInstance.post(`/merchants/pick-ups/request`, data);
  return response.data;
};

// Cancel Pickup By ID
export const cancelPickupById = async (id: string): Promise<PickupResponse> => {
  const response = await axiosJSONInstance.post(`/merchants/pick-ups/${id}/cancel`);
  return response.data;
};

// Fetch labels Pickup by UID
export const fetchLabelsPickupByUID = async (uid: string): Promise<File> => {
  const response = await axiosJSONInstance.get(`/merchants/pick-ups/${uid}/labels`, {
    responseType: 'blob',
  });
  return response.data;
};
