import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Flex, Layout, MenuProps, Row } from 'antd';
import {
  BottomSheet,
  TableAction,
  DownloadIcon,
  FilterHeader,
  PickupsIcon,
  Heading,
  ExportIcon,
} from '@/components';
import TabBar, { TabItem } from '@/components/Tab/TabBar';
import { In_TRANSIT, LOCAL_AR, STYLE_ORDERS } from '@/config';
import { useTranslation } from 'react-i18next';
import { getColumns, getListColumns } from './list.tsx';
import { OrderStatusTab } from '@/types/columns.ts';
import styles from './orders.module.css';
import useOrderData, { OrderDataType } from '@/hooks/useOrderData.ts';
import { listStatusButtons } from '@/utils/getStatus.ts';
import FilterButton from '@/components/FilterButton/FilterButton.tsx';
import useSubmitOrder from '@/hooks/useSubmitOrders.tsx';
import useTabVisibility from '@/hooks/useTabVisibility.ts';
import { ISearch } from '@/components/TableAction/FilterHeader.tsx';
import useSearchStore from '@/hooks/useSearchStore.ts';
import useOrderListData from '@/hooks/useOrderListData.tsx';
import getTabItems from '@/utils/getTabsItems.tsx';
import { useLocation } from 'react-router-dom';
import { OrderColumn } from '@/types/order.ts';
import { downloadFile } from '@/utils/helper.ts';
import { fetchBulkOrderByUIDs } from '@/api/orderService.ts';

const pageSize = 25;

const Orders = () => {
  const { i18n, t } = useTranslation();

  const location = useLocation();

  // parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const activeTabQuery = queryParams.get('activeTab') as OrderStatusTab;

  // search value
  const [searchValue, setSearchValue] = useState<ISearch>({
    search: null,
    searchBy: 'filter_platform_code',
  });

  // Zustand store values search
  const { searchValue: searchTermHeader } = useSearchStore();

  // handle active checkbox Item
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<(OrderDataType & OrderColumn)[]>([]);
  const [current, setCurrent] = useState<number>(1);

  // pagination
  const [activeTab, setActiveTab] = useState<OrderStatusTab>(OrderStatusTab.ReadyToShip);

  // find active tab
  const [activeButton, setActiveButton] = useState<string>('all');

  // filter
  const [currentAll, setCurrentAll] = useState<number>(1);

  // data for table list orders
  const { listData, loadingOrderList, totalsOrderList } = useOrderListData(
    currentAll,
    pageSize,
    searchTermHeader,
  );

  // set current page when search change
  useEffect(() => {
    if (searchTermHeader?.search || searchTermHeader?.searchBy) {
      setCurrentAll(1);
    }
  }, [searchTermHeader?.search, searchTermHeader?.searchBy]);

  useEffect(() => {
    if (isValidOrderStatusTab(activeTabQuery)) setActiveTab(activeTabQuery);
  }, [activeTabQuery]);

  // data tables
  const { activeData, totals, isLoading, count, refetch } = useOrderData(
    current,
    activeTab,
    activeButton,
    pageSize,
    searchValue,
  );

  // handle request pickup
  const { onSubmit, LoadingRequestPickup } = useSubmitOrder(activeTab, selectedRow);

  // handle row selection
  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  }, [activeTab]);

  // Handle when the tab becomes visible again
  const handleTabVisible = () => {
    refetch();
  };

  function isValidOrderStatusTab(value: string): boolean {
    return Object.values(OrderStatusTab).includes(value as OrderStatusTab);
  }

  // Hook to handle tab visibility
  useTabVisibility(() => {}, handleTabVisible);

  // active button
  const checkActive = (item: string) => (activeButton === item ? 'primary' : 'default');

  // handle search
  const handleSearchChange = useCallback((value: ISearch) => {
    setSearchValue(value);
    setCurrent(1);
  }, []);

  // tabs items
  const tabItems = getTabItems({
    t,
    i18n,
    checkActive,
    setActiveButton,
    count,
  });

  // handle onChange pagination
  const handlePaginationChange = (page: number) => {
    setCurrent(page);
  };

  // handle All onChange pagination
  const handlePaginationAllChange = (page: number) => {
    setCurrent(page);
  };

  // handle row selection
  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  }, [activeTab]);

  const actionFilter = activeData?.filter((item) => item?.merchant_action_required === 1);
  const checkAction = OrderStatusTab.OnHold === activeTab && activeButton === 'action_required';

  // has search header
  const hasSearchHeader = searchTermHeader?.search === null || searchTermHeader?.search === '';

  // menu items dropdown
  const onClickDropdown: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'export') {
      const queryString = selectedRow
        .map((row: OrderColumn) => `filter_uids[]=${encodeURIComponent(row?.key)}`)
        .join('&');

      await downloadFile(queryString, fetchBulkOrderByUIDs, `orders-${new Date().getTime()}.csv`);
    }
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <Heading
          title={t('export_selected_orders')}
          leadingIcon={<ExportIcon />}
          rotation={false}
          loading={isLoading}
        />
      ),
    },
  ];

  const tabBarContent = (
    <>
      <Row className='w-full my-6'>
        <Col md={24} xs={24}>
          <TabBar
            activeTab={activeTab}
            setActiveTab={setActiveTab as any}
            handleChange={() => {
              setCurrent(1);
              setActiveButton('all');
            }}
            tabItems={tabItems}
          />
        </Col>
        <Col md={24} xs={24} className='mt-6 mb-16'>
          <TableAction<OrderDataType & OrderColumn>
            columns={getColumns(activeTab, t) || []}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            setSelectedRow={setSelectedRow}
            data={checkAction ? actionFilter || [] : activeData || []}
            pageSize={pageSize}
            current={current}
            loading={checkAction ? false : isLoading}
            total={totals}
            onPaginationChange={handlePaginationChange}
            rowSelectionEnabled
            header={
              <FilterHeader
                includeSearchBox={activeTab === OrderStatusTab.RTO ? false : true}
                filterItems={[
                  activeTab === OrderStatusTab.OnHold ? t('on_hold') : t('order'),
                  t('customer'),
                ]}
                setSearchValue={handleSearchChange}
                searchValue={searchValue}
              />
            }
          />
        </Col>
      </Row>
      <Row className='sticky bottom-6 z-50'>
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            isVisible={selectedRowKeys.length > 0}
            numberSelected={selectedRowKeys.length}
            total={totals}
            labelBtn={
              activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold
                ? t('download')
                : t('ship_now')
            }
            Icon={
              activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold ? (
                <DownloadIcon />
              ) : (
                <PickupsIcon width={20} height={20} />
              )
            }
            onSubmit={onSubmit}
            loadingSubmit={LoadingRequestPickup}
            menuItems={menuItems}
            onClickDropdown={onClickDropdown}
          />
        </div>
      </Row>
    </>
  );

  const listContent = (
    <>
      <TableAction
        columns={getListColumns(t)}
        data={listData || []}
        pageSize={pageSize}
        current={currentAll}
        loading={loadingOrderList}
        total={totalsOrderList}
        onPaginationChange={handlePaginationAllChange}
        rowSelectionEnabled
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedRow={setSelectedRow}
        className='mt-2'
      />
      <Row className='sticky bottom-6'>
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            isVisible={selectedRowKeys.length > 0}
            numberSelected={selectedRowKeys.length}
            withoutButton
            total={totals}
            menuItems={menuItems}
            onClickDropdown={onClickDropdown}
          />
        </div>
      </Row>
    </>
  );

  return (
    <Layout className='overflow-visible relative w-full' rootClassName={styles.antLayoutCustom}>
      {hasSearchHeader ? tabBarContent : listContent}
    </Layout>
  );
};

export default Orders;
