/* eslint-disable react/prop-types */
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  onOk?: () => void;
  title: string;
  children?: React.ReactNode;
  okButtonProps?: React.CSSProperties;
  cancelButtonProps?: React.CSSProperties;
  footer?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  visible,
  onClose,
  onOk,
  title,
  children,
  okButtonProps,
  cancelButtonProps,
  footer = true,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      okText={t('ok')}
      cancelText={t('cancel')}
      open={visible}
      onOk={onOk}
      onCancel={onClose}
      footer={footer}
      okButtonProps={{
        style: {
          ...okButtonProps,
        },
      }}
      cancelButtonProps={{
        style: {
          ...cancelButtonProps,
        },
      }}
    >
      {/* body modal */}
      {children}
    </Modal>
  );
};

export default CustomModal;
