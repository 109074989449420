import { listItem } from '@/utils/getStatus';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FilterButtonProps {
  item: listItem;
  setActiveButton: (item: string) => void;
  checkActive: (item: string) => 'primary' | 'default';
}

const FilterButton: React.FC<FilterButtonProps> = ({ item, setActiveButton, checkActive }) => {
  const { t } = useTranslation();
  return (
    <Button onClick={() => setActiveButton(item.value)} type={checkActive(item.value)}>
      {t(item.title)}
    </Button>
  );
};

export default FilterButton;
