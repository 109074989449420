import { axiosJSONInstance } from '@/config';
import { ContactsResponse, ContactRequest, responseContactByUid } from '@/types';

// Fetch Contacts
export const fetchContacts = async (): Promise<ContactsResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/contacts`);
  return response.data;
};

// Create Contact
export const createContact = async (data: ContactRequest): Promise<ContactsResponse> => {
  const response = await axiosJSONInstance.post(`/merchants/contacts`, data);
  return response.data;
};

// Fetch Contact By ID
export const fetchContactById = async (id: string): Promise<responseContactByUid> => {
  const response = await axiosJSONInstance.get(`/merchants/contacts/${id}`);
  return response.data;
};

// Update Contact By ID
export const updateContactById = async (
  id: string,
  data: ContactRequest,
): Promise<ContactsResponse> => {
  const response = await axiosJSONInstance.patch(`/merchants/contacts/${id}`, { data });
  return response.data;
};

// Delete Contact By ID
export const deleteContactById = async (id: string): Promise<ContactsResponse> => {
  const response = await axiosJSONInstance.delete(`/merchants/contacts/${id}`);
  return response.data;
};
