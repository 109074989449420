import React, { ReactNode } from 'react';
import { Button, Dropdown, Flex, MenuProps, Typography } from 'antd';
import { Heading } from '../Heading';
import { CloseOutline, DeleteIcon, EditOutline, ExportIcon, MoreOutline } from '../Icons';
import { useTranslation } from 'react-i18next';
import '@/styles/style.css';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/config';
import { OrderStatusTab } from '@/types';
import { useHandleOrderActions } from '@/hooks/useHandleOrderActions';

interface BottomSheetProps {
  withoutButton?: boolean;
  isVisible: boolean;
  Icon?: ReactNode;
  numberSelected?: number;
  total?: number;
  labelBtn?: string;
  children?: ReactNode;
  showRecords?: boolean;
  justify?: string;
  menuItems?: MenuProps['items'];
  onSubmit?: () => void;
  loadingSubmit?: boolean;
  uid?: string;
  editDisabled?: boolean;
  deleteLabel?: string;
  activeTab?: OrderStatusTab;
  cancelDisabled?: boolean;
  disableDropdown?: boolean;
  onClickDropdown?: any;
}

const { Text } = Typography;

const BottomSheet: React.FC<BottomSheetProps> = ({
  withoutButton = false,
  isVisible,
  numberSelected = 0,
  total = 0,
  labelBtn = 'ship now',
  Icon,
  children,
  showRecords = true,
  menuItems,
  onSubmit,
  uid,
  editDisabled = false,
  cancelDisabled = false,
  disableDropdown = false,
  deleteLabel,
  loadingSubmit,
  activeTab,
  onClickDropdown,
  justify = 'flex-end',
}) => {
  const isAnimate = isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleOrderAction, isLoading } = useHandleOrderActions(
    uid || '',
    activeTab || OrderStatusTab.ReadyToShip,
  );

  const items: MenuProps['items'] = menuItems || [
    {
      key: '1',
      label: (
        <Heading
          onClick={() => navigate(ROUTE.ORDER_EDIT.replace(':orderUid', String(uid)))}
          title={t('edit')}
          leadingIcon={<EditOutline />}
          rotation={false}
          disabled={editDisabled}
        />
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <Heading
          title={deleteLabel ?? t('cancel')}
          leadingIcon={<DeleteIcon />}
          rotation={false}
          onClick={handleOrderAction}
          loading={isLoading}
          disabled={cancelDisabled}
        />
      ),
    },
  ];

  return (
    <Flex
      className={`absolute gap-x-4 items-center max-h-[100px] border-solid border-0 border-customGrayShade3 rounded-[10px] shadow-card-dark bottom-0 left-0 right-0 bg-colorWhite p-4 transform transition-all duration-300 opacity-0 ${isAnimate}`}
      justify={justify}
      style={{ transitionProperty: 'transform, max-height' }}
    >
      {children ? (
        children
      ) : (
        <>
          {showRecords && (
            <Text strong>
              {numberSelected}/{total} <Text className='font-normal'>{t('selected')}</Text>
            </Text>
          )}
          {!withoutButton && (
            <Button
              style={{ paddingTop: '27px', paddingBottom: '27px' }}
              type='primary'
              className='min-w-[150px]'
              onClick={onSubmit}
              loading={loadingSubmit}
              disabled={loadingSubmit}
            >
              <Heading
                title={labelBtn}
                leadingIcon={Icon}
                fontWeight='font-bold'
                fontSize='text-sm'
                extraClassName='uppercase leading-[20px]'
                rotation={false}
              />
            </Button>
          )}
          {!disableDropdown && (
            <Dropdown
              menu={{ items, onClick: onClickDropdown }}
              trigger={['click']}
              rootClassName='bottomSheet'
              placement='topLeft'
            >
              <Flex className='flex  justify-center cursor-pointer items-center w-[56px] h-[56px] rounded-lg border border-solid border-customTunaPrimary'>
                <MoreOutline width={29} height={29} />
              </Flex>
            </Dropdown>
          )}
        </>
      )}
    </Flex>
  );
};

export default BottomSheet;
