/* eslint-disable react/prop-types */
import { SVGProps } from 'react';

interface RightArrowFailIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
  backgroundColor?: string;
}

const RightArrowFailIcon: React.FC<RightArrowFailIconProps> = ({
  width = 19,
  height = 10,
  fillColor = '#F05652',
  backgroundColor = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={backgroundColor}
    {...props}
  >
    <path
      fill={fillColor}
      d='m6.715 6.156.353.354-.351.351-.354-.35.352-.355Zm4.456-4.452-.353-.353.352-.353.354.352-.353.354ZM18.5 8.5a.5.5 0 0 1-.499.501l-4.5.011a.5.5 0 0 1-.002-1l4-.01-.01-4a.5.5 0 1 1 1-.002l.011 4.5ZM1.352.145 7.067 5.8l-.704.71L.648.855l.704-.71Zm5.01 5.657 4.456-4.451.706.707L7.068 6.51l-.706-.708Zm5.162-4.452 6.829 6.796-.706.708-6.829-6.795.706-.71Z'
    />
  </svg>
);
export default RightArrowFailIcon;
