import { SVGProps } from 'react';

const OnHoldIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={20} fill='none' {...props}>
      <path
        fill='#38383E'
        fillRule='evenodd'
        d='M8.017 3.442a7.455 7.455 0 1 0 8.266 7.41h1.24a8.693 8.693 0 1 1-9.845-8.617l-.773-.539a.682.682 0 0 1 .78-1.119L9.812 2.06c.309.215.385.64.17.949L8.498 5.136a.682.682 0 0 1-1.119-.78l.637-.914ZM5.079 9.66a1.194 1.194 0 1 1-.001 2.387 1.194 1.194 0 0 1 .001-2.387Zm3.75 0a1.194 1.194 0 1 1-.001 2.387 1.194 1.194 0 0 1 0-2.387Zm3.75 0a1.194 1.194 0 1 1-.001 2.387 1.194 1.194 0 0 1 0-2.387Z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default OnHoldIcon;
