import { Button, Flex, Input, Select } from 'antd';
import { AddIcon, ImportIcon, SearchIcon } from '../Icons';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/config';
import { getSearchItems } from '@/utils/getSearchItems ';
import useSearchStore from '@/hooks/useSearchStore';
import debounce from 'lodash.debounce';

const { Option } = Select;

const ButtonHeaderOrders = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchItems = getSearchItems(t);

  // store search in
  const { searchValue, setSearchValue } = useSearchStore();

  // Handle select change
  const handleSelectChange = (value: string) => {
    setSearchValue({ ...searchValue, searchBy: value });
  };

  // Handle search input change
  const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({ ...searchValue, search: e.target.value });
  }, 500);

  return (
    <Flex gap='middle' justify='space-between' className='mt-2 sm:mb-2'>
      <Flex gap='16px'>
        <Select
          placeholder={t('search_by')}
          className='w-1/2 h-full min-w-[140px]'
          allowClear
          size='middle'
          value={searchValue.searchBy}
          onChange={handleSelectChange}
        >
          {searchItems?.map((search, index) => (
            <Option key={index} value={search?.value}>
              {search?.label}
            </Option>
          ))}
        </Select>
        <Input
          size='middle'
          placeholder={t('search')}
          prefix={<SearchIcon />}
          className='w-full rounded-lg'
          allowClear
          onChange={handleSearchChange}
          defaultValue={searchValue?.search || ''}
        />
      </Flex>
      <Button
        className='rounded-md border-solid border-customTunaPrimary'
        onClick={() => navigate(ROUTE.IMPORT)}
      >
        <Heading
          leadingIcon={<ImportIcon width={20} height={20} className='mt-1' />}
          title={t('import')}
          fontWeight='font-bold'
        />
      </Button>
      <Button onClick={() => navigate(ROUTE.ADDNEWORDER)} type='primary' className='rounded-md'>
        <AddIcon width={20} height={20} />
        <Heading title={t('new_order')} fontWeight='font-bold' />
      </Button>
    </Flex>
  );
};

export default ButtonHeaderOrders;
