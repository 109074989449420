import { IUser, User } from '@/types';
import { create } from 'zustand';

interface UserStore {
  users: User[];
  setUsers: (users: User[]) => void;
  hash: string | undefined;
  setHash: (hash: string | undefined) => void;
  user?: IUser;
  setUser: (user?: IUser) => void;
  clearUser: () => void;
}

const useUserStore = create<UserStore>((set) => ({
  users: [],
  setUsers: (users) => set({ users }),
  hash: undefined,
  setHash: (hash) => set({ hash }),
  user: undefined,
  setUser: (user) => set({ user }),
  clearUser: () => set({ user: undefined }),
}));

export default useUserStore;
