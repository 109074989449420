import { SVGProps } from 'react';

interface BellIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BellIcon = ({ width = 24, height = 24, color = '#38383E', ...props }: BellIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 24 24' // Ensures proper scaling based on the provided width and height
    {...props}
  >
    <path
      fill={color}
      d='M3.5 19v-.018A1.75 1.75 0 0 1 2 17.25v-.922a2 2 0 0 1 .586-1.414L3.5 14v-3.5a8.5 8.5 0 0 1 17 0V14l.914.914A2 2 0 0 1 22 16.328v.922a1.75 1.75 0 0 1-1.5 1.732V19h-5.036a3.5 3.5 0 0 1-6.929 0H3.5Zm10.437 0h-3.874l.027.095a2 2 0 0 0 3.82 0l.027-.095ZM12 3.5a7 7 0 0 0-6.998 6.844L5 10.5v4.039a.2.2 0 0 1-.059.14l-1.295 1.296a.5.5 0 0 0-.142.288l-.004.065v.922c0 .109.07.203.169.237l.043.01.021.003h16.534l.02-.003a.25.25 0 0 0 .209-.201l.004-.046v-.922a.5.5 0 0 0-.103-.304l-.043-.05-1.295-1.294a.2.2 0 0 1-.059-.141V10.5a7 7 0 0 0-7-7Z'
    />
  </svg>
);

export default BellIcon;
