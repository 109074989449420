import { SVGProps } from 'react';

interface BoxSmallIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BoxSmallIcon = ({
  width = 56,
  height = 56,
  color = '#38383E',
  ...props
}: BoxSmallIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 56 56' // Ensures proper scaling based on the provided width and height
    {...props}
  >
    <g fill={color} fillRule='evenodd' clipPath='url(#a)' clipRule='evenodd'>
      <path d='m27.125 38.292-7-3.262v6.532a.876.876 0 0 1-1.522.589l-3.091-3.406-2.498 1.004a.875.875 0 0 1-1.201-.812v-7.781l-7.82-3.645a.874.874 0 0 1 .001-1.586l23.554-10.968a.876.876 0 0 1 .74 0l13.986 6.52.087.038.049.024 9.452 4.405a.876.876 0 0 1 0 1.587L28.875 38.234v12.35a.876.876 0 0 1-1.75 0V38.292Zm-13.562-6.321v5.671l1.86-.748a.875.875 0 0 1 .975.224l1.977 2.178v-5.082l-4.812-2.243Zm19.82-12.709-5.465-2.547L6.435 26.718l5.817 2.711.056-.03 21.074-10.137ZM21.293 33.644l6.644 3.096L49.42 26.737l-7.407-3.452-20.719 10.359ZM19.27 32.7 39.965 22.35l-4.62-2.092L14.3 30.383l4.97 2.317Z' />
      <path d='M50.617 27.125a.876.876 0 0 1 1.75 0V40.3c0 .34-.197.65-.506.793L28.307 52.062a.877.877 0 0 1-.74 0L3.995 41.074a.875.875 0 0 1-.506-.794V26.687a.876.876 0 0 1 1.75 0v13.036l22.7 10.58 22.679-10.56V27.124Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h56v56H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default BoxSmallIcon;
