import { create } from 'zustand';
import { Product } from '@/types';

// Products Store for managing products within a specific order
interface ProductsStore {
  products: Product[];
  setProducts: (products: Product[]) => void;
  addProduct: (orderUid: number, product: Product) => void;
  updateProduct: (orderUid: number, productUid: number, updatedProduct: Partial<Product>) => void;
  deleteProduct: (orderUid: number, productUid: number) => void;
}

const useProductsStore = create<ProductsStore>((set) => ({
  products: [],

  setProducts: (products) => set({ products }),

  addProduct: (orderUid, product) =>
    set((state) => ({
      products: [...state.products, { ...product, orderUid }],
    })),

  updateProduct: (orderUid, productUid, updatedProduct) =>
    set((state) => ({
      products: state.products.map((product) =>
        product.orderUID === orderUid && product.uid === productUid
          ? { ...product, ...updatedProduct }
          : product,
      ),
    })),

  deleteProduct: (orderUid, productUid) =>
    set((state) => ({
      products: state.products.filter(
        (product) => product.orderUID !== orderUid || product.uid !== productUid,
      ),
    })),
}));

export default useProductsStore;
