import { Card } from '../Card';
import { Heading } from '../Heading';
import { Col, Form, Input, Row, Typography } from 'antd';

const { TextArea } = Input;
const { Text } = Typography;

interface EditDeliveryInstructionProps {
  title: string;
  description: string;
  placeholder: string;
}

const EditDeliveryInstruction = ({
  title,
  description,
  placeholder,
}: EditDeliveryInstructionProps) => {
  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Text>{description}</Text>
      <Row>
        <Col lg={24} md={24} xs={24} className=' '>
          <Form.Item name='deliveryInstruction'>
            <TextArea
              autoComplete='off'
              variant='borderless'
              className='h-[65px] rounded-[10px] border mt-4 border-solid  border-customGrayShade5'
              placeholder={placeholder}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default EditDeliveryInstruction;
