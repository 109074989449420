import { ProfileResponse } from '@/types';
import { create } from 'zustand';

interface ProfileState {
  profile: ProfileResponse | null;
  setProfile: (profile: ProfileResponse) => void;
}

export const useProfileStore = create<ProfileState>((set) => ({
  profile: null,
  setProfile: (profile) => set({ profile }),
}));
