import React from 'react';
import { Col, Flex, Layout, Row, Typography } from 'antd';
import RightIcon from '../Icons/RightIcon';
import WarningIcon from '../Icons/WarningIcon';
import SuccessIcon from '../Icons/SuccessIcon';
import InfoIcon from '../Icons/InfoIcon';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ToastProps {
  title?: string;
  description?: string;
  link?: string;
  type?: 'info' | 'warning' | 'success';
}

const Toast: React.FC<ToastProps> = ({
  title = '',
  description = '',
  link = '',
  type = 'info',
}) => {
  const typeStyles = {
    info: 'bg-customBlueShade1 border-customBlue',
    warning: 'bg-customOrangeShade1 border-customOrange',
    success: 'bg-customGreenShade1 border-customGreen',
  };
  const linkIconColor = {
    info: THEME_VARIABLE.customBlue,
    warning: THEME_VARIABLE.customOrange,
    success: THEME_VARIABLE.customGreen,
  };

  const iconMapping = {
    info: <InfoIcon />,
    warning: <WarningIcon />,
    success: <SuccessIcon />,
  };
  const { i18n } = useTranslation();
  const { Text } = Typography;
  return (
    <Layout>
      <Row>
        <Col span={24}>
          <Col
            className={`w-full flex justify-between border border-solid shadow-card-dark items-center px-4 rounded-[10px] ${typeStyles[type]}`}
          >
            <Col className='flex items-center gap-x-[10px] flex-1 pr-[10px] min-w-0'>
              <Flex className='py-3'>{iconMapping[type]}</Flex>
              <Flex vertical className='flex-1 min-w-0'>
                {title && (
                  <Text className='font-[700] block text-4 leading-[11px] text-customTunaPrimary tracking-[-0.32px] break-words'>
                    {title}
                  </Text>
                )}
                {description && (
                  <Text
                    className={`block ${
                      title
                        ? ' font-[400] text-[14px] leading-4 tracking-[-0.32px] text-customTunaPrimary break-words'
                        : 'font-[400] text-[16px] leading-[20.48px] tracking-[-0.32px] text-customTunaPrimary break-words'
                    }`}
                  >
                    {description}
                  </Text>
                )}
              </Flex>
            </Col>
            {link && type !== 'info' && (
              <Col className={`flex-shrink-0  ${i18n.language === LOCAL_EN ? '' : 'rotate-180'}`}>
                <Link to={link}>
                  <RightIcon color={linkIconColor[type]} />
                </Link>
              </Col>
            )}
          </Col>
        </Col>
      </Row>
    </Layout>
  );
};

export default Toast;
