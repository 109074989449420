import { useState } from 'react';
import { ViewStateChangeEvent, MapLayerMouseEvent } from 'react-map-gl';

export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiaGVzdDEyMyIsImEiOiJja28wZWhscjUwZXpvMm9tdnV0NDhtYjM1In0.UuZF95SWdyCnerb0xORO6A'; // Replace with your Mapbox token

interface Coordinates {
  latitude: number;
  longitude: number;
}

const useMapCoordinates = (onCoordinatesChange: (coords: Coordinates) => void) => {
  const [viewport, setViewport] = useState({
    latitude: 33.3152,
    longitude: 44.3661,
    zoom: 12,
  });

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleViewportChange = (event: ViewStateChangeEvent) => {
    setViewport(event.viewState);
  };

  const fetchCoordinates = async (address: string) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${MAPBOX_TOKEN}&limit=1`,
      );

      if (!response.ok) {
        throw new Error('Failed to fetch coordinates');
      }

      const data = await response.json();
      return data.features[0]?.center || null;
    } catch (error) {
      console.error('Error fetching the geolocation data:', error);
      return null;
    }
  };

  const handleSearch = async (value: string) => {
    if (!value) return;

    const coords = await fetchCoordinates(value);
    if (coords) {
      const [longitude, latitude] = coords;
      setViewport((prev) => ({
        ...prev,
        latitude,
        longitude,
      }));
      onCoordinatesChange({ latitude, longitude });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeout = setTimeout(() => {
      handleSearch(value);
    }, 500);

    setSearchTimeout(timeout);
  };

  const handleMapClick = (event: MapLayerMouseEvent) => {
    const { lng, lat } = event.lngLat;
    onCoordinatesChange({ latitude: lat, longitude: lng });
  };

  return {
    viewport,
    handleViewportChange,
    handleInputChange,
    handleMapClick,
  };
};

export default useMapCoordinates;
