import { SVGProps } from 'react';

interface PickupsIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const PickupsIcon = ({
  width = 20,
  height = 20,
  fillColor = '#38383E',
  ...props
}: PickupsIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill={fillColor}
      d='M11.867 5.07v.798h3.935c.374 0 .735.138 1.014.388l.063.06 1.39 1.396c.265.266.423.62.444.994l.002.087v5.86c0 .814-.636 1.485-1.445 1.526l-.076.002h-.46a2.092 2.092 0 0 1-4.027 0H8.365a2.092 2.092 0 0 1-4.027 0h-1.6c-.841 0-1.523-.684-1.523-1.528V5.07c0-.844.682-1.528 1.522-1.528h7.609c.84 0 1.521.684 1.521 1.528ZM5.4 15.608c0 .527.426.955.951.955a.953.953 0 0 0 .951-.955.953.953 0 0 0-.95-.955.953.953 0 0 0-.952.955Zm8.37 0c0 .527.425.955.95.955a.953.953 0 0 0 .952-.955.953.953 0 0 0-.951-.955.953.953 0 0 0-.951.955ZM2.737 4.688a.381.381 0 0 0-.378.337l-.002.045v9.583c0 .194.144.356.335.38l.045.002h1.6a2.092 2.092 0 0 1 4.028 0h2.361V5.07a.382.382 0 0 0-.293-.372l-.043-.008-.044-.002H2.737Zm9.13 2.326v8.02h.84a2.092 2.092 0 0 1 4.027 0h.46l.044-.002a.381.381 0 0 0 .333-.334l.003-.045v-5.86l-.003-.05a.383.383 0 0 0-.075-.183l-.034-.038-1.39-1.396-.038-.033a.38.38 0 0 0-.181-.076l-.05-.003h-3.936Z'
    />
  </svg>
);

export default PickupsIcon;
