import { ROUTE } from '@/config';
import { useUserStore } from '@/stores';
import { useAuthStore } from '@/stores/authStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthRedirect = () => {
  const { user } = useUserStore((state) => state);
  const { token } = useAuthStore((state) => state);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(ROUTE.LOGIN);
    }
  }, [user, navigate]);
};

export default useAuthRedirect;
