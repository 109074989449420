import { SVGProps } from 'react';

interface InvoiceIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const InvoiceIcon = ({
  width = 18,
  height = 17,
  color = '#38383E',
  ...props
}: InvoiceIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      d='M2.581 3.33H1.144L.94 3.308l-.194-.06-.172-.094-.15-.124-.125-.15-.095-.174-.059-.193-.02-.205v-.79l.02-.204.059-.192.095-.174L.423.8.574.674.746.58.94.52 1.144.5h15.442l.203.02.194.06.172.094.15.125.125.15.095.174.059.192.02.205v.79l-.02.204-.059.193-.095.173-.124.15-.151.125-.172.094-.194.06-.203.02h-1.403v11.297c0 .563-.223 1.103-.623 1.502a2.12 2.12 0 0 1-1.5.622.629.629 0 0 1-.467-.207l-.942-1.047-.942 1.047a.629.629 0 0 1-.93 0l-.942-1.047-.945 1.047a.619.619 0 0 1-.464.207.619.619 0 0 1-.463-.207l-.945-1.047-.942 1.047a.623.623 0 0 1-.464.207 2.033 2.033 0 0 1-2.033-2.033V3.33Zm11.352 0H3.83v11.387c0 .347.227.642.54.744l1.186-1.317a.624.624 0 0 1 .929 0l.942 1.047.943-1.047a.624.624 0 0 1 .93 0l.941 1.047.946-1.047a.63.63 0 0 1 .93 0l1.188 1.32a.874.874 0 0 0 .627-.838V3.329ZM1.373 1.75v.33h14.982v-.33H1.374Zm4.438 5.937a.626.626 0 0 1 0-1.25h3.688a.624.624 0 1 1 0 1.25H5.81Zm0 1.875a.626.626 0 0 1 0-1.25h5.252a.626.626 0 0 1 0 1.25H5.811Zm0-3.75a.626.626 0 0 1 0-1.25h6.142a.626.626 0 0 1 0 1.25H5.81Z'
      clipRule='evenodd'
    />
  </svg>
);
export default InvoiceIcon;
