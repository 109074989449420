import { Flex } from 'antd';
import InformationIcon from './InformationIcon';

const InfoIcon = () => {
  return (
    <Flex className='w-8 h-8 rounded-full flex justify-center items-center bg-customBlue'>
      <InformationIcon />
    </Flex>
  );
};

export default InfoIcon;
