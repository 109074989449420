import React from 'react';
import { Button, Flex, Form } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { PickupsIcon } from '../Icons';

// Define an interface for the props
interface BottomOrderEditCardProps {
  createOrderTitle: string;
  readyToShipTitle: string;
  isLoading?: boolean;
  isReadyToSubmit?: boolean;
}

const BottomOrderEditCard: React.FC<BottomOrderEditCardProps> = ({
  createOrderTitle,
  readyToShipTitle,
  isLoading,
  // isReadyToSubmit,
}) => {
  return (
    <Card extraClassName='py-[19px]'>
      <Flex gap='middle' justify='flex-end' align='center'>
        <Form.Item className='!mb-0'>
          <Button
            loading={isLoading}
            htmlType='submit'
            // disabled={!isReadyToSubmit} // Disable based on isFormReady
            type='primary'
            className='rounded-[8px] disabled:bg-yellow-100 gap-x-2'
            style={{ paddingBottom: '27px', paddingTop: '27px' }}
          >
            <Heading
              title={createOrderTitle}
              fontWeight='font-bold'
              fontSize='text-sm'
              extraClassName=' uppercase leading-5 text-customTunaPrimary'
            />
          </Button>
        </Form.Item>
      </Flex>
    </Card>
  );
};

export default BottomOrderEditCard;
