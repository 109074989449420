import { create } from 'zustand';
import { Order } from '@/types';

interface OrdersStore {
  orders: Order[];
  orderUid: string;
  setOrderUid: (value: string) => void;
  setOrders: (orders: Order[]) => void;
  addOrder: (order: Order) => void;
}

const useOrdersStore = create<OrdersStore>((set) => ({
  orders: [],
  orderUid: '',
  setOrderUid: (orderUid) => set({ orderUid }), // Setter implementation
  // Set multiple orders (e.g., after fetching data from API)
  setOrders: (orders) => set({ orders }),

  // Add a new order
  addOrder: (order) =>
    set((state) => ({
      orders: [...state.orders, order],
    })),
}));

export default useOrdersStore;
