import { LOCAL_EN } from '@/config';
import { Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';
import { useResolveModalStore } from '@/hooks';

const { Text } = Typography;

const ResolveCustomerDetails = () => {
  const { i18n, t } = useTranslation();
  // Zustand store values
  const fullName = useResolveModalStore((state) => state.full_name);
  const phone = useResolveModalStore((state) => state.phone);
  const addressTitle = useResolveModalStore((state) => state.addressTitle);
  return (
    <Flex vertical className='mt-[34px]'>
      <Heading
        title={t('customer_details')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />

      <Row gutter={[24, 16]} className='mt-[17px]'>
        <Col lg={8} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {t('name')}
            </Text>
            <Text className=' capitalize font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {fullName} {/* Use the name from zustand */}
            </Text>
          </Flex>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {t('phone_number')}
            </Text>
            <Text
              style={{ direction: 'ltr' }}
              className={` ${i18n.language === LOCAL_EN ? 'text-left' : 'text-right'} font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary`}
            >
              {phone} {/* Use the phoneNumber from zustand  */}
            </Text>
          </Flex>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {t('address')}
            </Text>
            <Text className=' capitalize font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {addressTitle} {/* Use the address from zustand */}
            </Text>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
};

export default ResolveCustomerDetails;
