import { SVGProps } from 'react';

interface GreenCheckIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const GreenCheckIcon = ({
  width = 24,
  height = 25,
  color = '#2BB45D',
  ...props
}: GreenCheckIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect width={24} height={24} y={0.5} fill={color} rx={12} />
    <path
      fill='#fff'
      d='m17.539 9.414-6.298 6.24a.41.41 0 0 1-.614-.043.413.413 0 0 1-.043-.037L6.461 11.49a.11.11 0 0 1 .077-.187h.843c.028 0 .056.011.076.032l3.496 3.463 5.59-5.538a.109.109 0 0 1 .076-.032h.843a.11.11 0 0 1 .077.187Z'
    />
  </svg>
);
export default GreenCheckIcon;
