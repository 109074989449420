import { fetchBulkLabelPickupByUIDs, fetchSingleLabelPickupByUID } from '@/api';
import { ROUTE } from '@/config';
import { useRequestPickup } from '@/hooksApi';
import { OrderColumn, OrderStatusTab } from '@/types';
import { downloadFile } from '@/utils';
import { message } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface useSubmitSingleOrderProps {
  activeTab: string;
  pickupId?: string;
  orderId: string;
}

const useSubmitSingleOrder = ({ activeTab, orderId, pickupId }: useSubmitSingleOrderProps) => {
  const { mutate: mutateRequestPickup, isLoading: LoadingRequestPickup } = useRequestPickup();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleReadyToShip = () => {
    mutateRequestPickup(
      {
        pick_up_address_uid: pickupId || '',
        order_uids: [orderId ?? ''],
      },
      {
        onSuccess: () => {
          message.success(t('operation'));
        },
        onError: (error) => {
          message.error(error?.response?.data?.message || t('operation_failed'));
        },
      },
    );
  };

  const handleInProgress = async () => {
    await downloadFile(orderId, fetchSingleLabelPickupByUID, 'pickup-label.pdf');
  };

  const onSubmit = useCallback(() => {
    if (activeTab === OrderStatusTab.ReadyToShip) {
      handleReadyToShip();
    } else if (
      activeTab === OrderStatusTab.InProgress ||
      activeTab === OrderStatusTab.RTO ||
      activeTab === OrderStatusTab.OnHold
    ) {
      handleInProgress();
    }
  }, [activeTab, handleReadyToShip, handleInProgress]);

  return { onSubmit, LoadingRequestPickup };
};

export default useSubmitSingleOrder;
