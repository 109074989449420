// import { refreshTokenUser } from '@/api';
import { useAuthStore } from '@/stores/authStore';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { API_URL, API_VERSION } from './setup.config';
import { useUserStore } from '@/stores';
import { ROUTE } from './routes.config';

export const apiConfig = {
  BASE_URL: `${API_URL}/${API_VERSION}`,
};

const setAuthHeader = (config: InternalAxiosRequestConfig) => {
  const token = useAuthStore.getState().token;
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  return config;
};

// refresh token
// const refreshToken = async () => {
//   const { setToken, clearToken } = useAuthStore.getState();
//   const { setUser } = useUserStore.getState();

//   try {
//     const result = await refreshTokenUser();
//     setToken(result?.object?.access_token);
//     return result?.object?.access_token;
//   } catch (error) {
//     clearToken();
//     setUser(undefined);
//     return false;
//   }
// };

const createAxiosInstance = (contentType: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: apiConfig.BASE_URL,
    headers: {
      'Content-Type': contentType,
      //'Document-Policy': 'js-profiling'
    },
  });

  instance.interceptors.request.use(setAuthHeader);

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // const originalRequest = error.config;
      // originalRequest._retryCount = originalRequest._retryCount || 0;

      if (error?.response?.status === 401) {
        const { clearToken } = useAuthStore.getState();
        const { clearUser } = useUserStore.getState();

        clearUser();
        clearToken();
        window.location.replace(ROUTE.LOGIN);

        // originalRequest._retryCount += 1;
        // try {
        //   const newToken = await refreshToken();
        //   if (newToken) {
        //     originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        //     return instance(originalRequest);
        //   }
        // } catch (tokenError) {
        //   return Promise.reject(tokenError);
        // }
      }

      // Reject the original error if it's not handled
      return Promise.reject(error);
    },
  );

  return instance;
};

export const axiosJSONInstance = createAxiosInstance('application/json');
export const axiosMultipartInstance = createAxiosInstance('multipart/form-data');
