import { useEffect } from 'react';

const useTabVisibility = (onHidden: () => void, onVisible: () => void) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        onHidden(); // Called when the tab is hidden
      } else {
        onVisible(); // Called when the tab is visible
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onHidden, onVisible]);
};

export default useTabVisibility;
