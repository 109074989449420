import { SVGProps } from 'react';

interface BoxyLogoProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BoxyLogo = ({ width = 86, height = 40, color = '#38383E', ...props }: BoxyLogoProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill='#38383E'
      d='M10.244 21.18c-.846-.515-1.837-.774-2.973-.774-1.033 0-1.919.247-2.66.74a4.38 4.38 0 0 0-1.155 1.134v-6.354H0v16.623h3.456v-1.764c.33.465.727.848 1.19 1.148.747.485 1.645.728 2.693.728 1.136 0 2.123-.254 2.962-.762.838-.507 1.489-1.216 1.953-2.128.463-.91.695-1.97.695-3.181 0-1.21-.24-2.323-.718-3.249-.479-.925-1.141-1.647-1.986-2.162Zm-1.166 7.102a2.817 2.817 0 0 1-1.044 1.198c-.449.284-.973.425-1.57.425-.598 0-1.104-.142-1.56-.425a2.866 2.866 0 0 1-1.066-1.198c-.255-.516-.382-1.11-.382-1.781 0-.673.127-1.262.382-1.77.254-.508.61-.908 1.066-1.199a2.836 2.836 0 0 1 1.56-.437c.583 0 1.122.146 1.57.437.45.291.797.694 1.044 1.21.246.515.37 1.101.37 1.759 0 .657-.124 1.265-.37 1.78ZM24.08 21.169c-.973-.508-2.102-.762-3.39-.762-1.286 0-2.42.254-3.399.762-.98.508-1.739 1.22-2.278 2.139-.538.919-.808 1.99-.808 3.215 0 1.224.27 2.3.808 3.226a5.494 5.494 0 0 0 2.278 2.15c.98.508 2.113.761 3.4.761s2.416-.254 3.389-.761a5.515 5.515 0 0 0 2.266-2.15c.538-.926.808-2.002.808-3.226 0-1.225-.27-2.296-.808-3.215a5.535 5.535 0 0 0-2.267-2.14Zm-.763 7.18a2.98 2.98 0 0 1-1.055 1.21c-.449.29-.973.437-1.57.437-.598 0-1.126-.146-1.583-.438a2.945 2.945 0 0 1-1.066-1.21c-.255-.514-.382-1.108-.382-1.78 0-.673.127-1.266.382-1.781a2.87 2.87 0 0 1 1.066-1.199c.456-.283.983-.425 1.583-.425.599 0 1.122.142 1.57.425.45.284.8.683 1.055 1.199.254.515.381 1.108.381 1.78 0 .673-.127 1.267-.381 1.782ZM36.019 26.389l3.837-5.87h-3.77l-2.357 3.72-2.22-3.72h-3.95l3.881 5.87-4.173 6.161h3.814l2.648-4.01 2.425 4.01h3.972l-4.107-6.161Z'
    />
    <path
      fill='#38383E'
      d='m49.596 20.52-2.85 8.423-3.164-8.423h-3.569l5.005 11.783-.337.717c-.165.373-.382.653-.65.84-.27.186-.584.28-.943.28-.524 0-1.047-.209-1.57-.627l-1.459 2.397a5.378 5.378 0 0 0 1.538.863c.531.186 1.11.28 1.739.28 1.047 0 1.937-.262 2.67-.785.733-.523 1.316-1.3 1.75-2.33l5.296-13.42h-3.456v.002ZM77.068 3.38c-.02 0-.023-.006-.023-.026V1.281c0-.013.003-.026.02-.026.218-.006.362-.01.606-.01.5 0 .799.221.799.632 0 .286-.186.481-.411.566.104.15.482.733.577.905.01.017.012.033-.007.033h-.374c-.026 0-.033-.006-.043-.017-.09-.15-.316-.527-.46-.761-.023-.039-.039-.055-.075-.055h-.274v.81c0 .016-.006.023-.023.023h-.313l.001-.001Zm.61-1.149c.267 0 .423-.127.423-.335 0-.221-.166-.332-.443-.332-.105 0-.203.006-.255.009v.657h.274v.001Z'
    />
    <path
      fill='#38383E'
      d='M77.715 4.563A2.286 2.286 0 0 1 75.43 2.28a2.286 2.286 0 0 1 4.57 0 2.286 2.286 0 0 1-2.285 2.282Zm0-4.374a2.097 2.097 0 0 0-2.097 2.092 2.097 2.097 0 0 0 4.193 0c0-1.153-.94-2.092-2.096-2.092ZM67.987 8.937a.841.841 0 0 0-1.031.6c-.09.338-.345.656-.72.896-.427.273-.976.423-1.546.423-.57 0-1.084-.14-1.505-.398-.371-.227-.634-.532-.741-.858a.838.838 0 0 0-.42-.49.84.84 0 0 0-.645-.05.839.839 0 0 0-.54 1.063c.465 1.424 2.05 2.419 3.85 2.419.89 0 1.761-.245 2.457-.69.732-.47 1.244-1.138 1.441-1.886a.844.844 0 0 0-.601-1.03h.001ZM66.817 7.546a.844.844 0 0 0 .844-.843V5.287a.844.844 0 0 0-1.689 0v1.416c0 .465.38.843.845.843ZM62.602 7.546a.844.844 0 0 0 .845-.843V5.287a.844.844 0 0 0-1.689 0v1.416c0 .465.379.843.844.843Z'
    />
    <path
      fill='#38383E'
      d='M72.438.804A2.74 2.74 0 0 0 70.498 0H58.741a4.668 4.668 0 0 0-3.32 1.373L44.49 12.287l-.003.003a3.184 3.184 0 0 0-.266.308l-.011.015a3.67 3.67 0 0 0-.093.134l-.03.046c-.029.046-.056.094-.083.141l-.015.03a2.726 2.726 0 0 0-.146.328l-.015.044a2.493 2.493 0 0 0-.092.33l-.01.058a3.191 3.191 0 0 0-.04.329 2.066 2.066 0 0 0-.004.07l-.002.085v.036l.002.07.008.129.009.084c.004.039.009.077.015.115l.014.075c.007.04.016.08.025.119a2.88 2.88 0 0 0 .057.205l.025.077.04.108.02.052a2.704 2.704 0 0 0 2.536 1.691h9.818v9.8c0 1.13.65 2.1 1.694 2.533l.052.019c.035.014.072.028.108.04l.077.026.11.032a4.126 4.126 0 0 0 .214.05l.075.013.115.016.085.008a2.389 2.389 0 0 0 .199.01h.035c.029 0 .058 0 .086-.002a1.992 1.992 0 0 0 .208-.015l.065-.008c.043-.005.085-.012.127-.02l.058-.011c.047-.01.094-.021.14-.033l.06-.017a2.53 2.53 0 0 0 .13-.042c.015-.004.03-.01.044-.015.049-.017.096-.037.143-.056l.053-.024c.045-.02.09-.043.134-.066l.03-.015a2.51 2.51 0 0 0 .14-.082l.046-.03c.046-.029.09-.06.134-.093l.016-.01a2.52 2.52 0 0 0 .137-.111l.038-.033c.045-.04.09-.08.133-.123l.003-.002 10.933-10.914a4.653 4.653 0 0 0 1.375-3.314V2.745a2.73 2.73 0 0 0-.805-1.937l-.003-.004ZM58.123 4.617V2.741a.87.87 0 0 1 .87-.868h11.504a.865.865 0 0 1 .87.869v11.483a.87.87 0 0 1-.87.869H58.124V4.617Z'
    />
    <path
      fill='#EBDD4C'
      d='M0 3.05A3.05 3.05 0 0 1 3.05 0h27.192a3.05 3.05 0 0 1 3.051 3.05v8.418a3.05 3.05 0 0 1-3.05 3.05H0V3.052Z'
    />
    <path
      fill='#38383E'
      d='M27.168 4.606h1.095l2.422 5.595h-1.143l-1.863-4.516-1.854 4.516h-1.119l2.462-5.595Zm-1.175 3.5h3.333v.872h-3.333v-.871ZM19.1 4.606h1.07V7.02h2.79V4.606h1.08V10.2h-1.08V7.907h-2.79V10.2H19.1V4.606ZM16.005 4.606c.709 0 1.257.168 1.646.503.39.336.584.808.584 1.415 0 .634-.195 1.127-.584 1.479-.389.351-.938.527-1.646.527h-1.687l.472-.471V10.2h-1.071V4.606h2.286Zm-.048 3.037c.4 0 .706-.09.919-.272.218-.18.328-.455.328-.823 0-.352-.11-.613-.328-.783-.213-.176-.52-.264-.92-.264h-1.638l.472-.472v3.086l-.472-.472h1.639ZM9.407 4.606h1.071V9.29h2.414v.91H9.407V4.607ZM5.215 4.606H6.31L8.732 10.2H7.59L5.727 5.685 3.873 10.2h-1.12l2.462-5.595Zm-1.175 3.5h3.333v.872H4.04v-.871Z'
    />
  </svg>
);

export default BoxyLogo;
