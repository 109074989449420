import { axiosJSONInstance } from '@/config';
import { RegionResponse } from '@/types/region';

// Fetch List Provinces
export const fetchListProvinces = async (): Promise<RegionResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/regions/provinces`);
  return response.data;
};

// Fetch area for a province By Code
export const fetchAreaByProvinceCode = async (ProvinceCode: string): Promise<RegionResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/regions/provinces/${ProvinceCode}`);
  return response.data;
};
