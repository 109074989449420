import React, { useState } from 'react';
import MapGL, { Marker, ViewStateChangeEvent, MapMouseEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Layout, Space } from 'antd';
import { PickupsIcon } from '../Icons';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiaGVzdDEyMyIsImEiOiJja28wZWhscjUwZXpvMm9tdnV0NDhtYjM1In0.UuZF95SWdyCnerb0xORO6A'; // Replace with your Mapbox token

interface Location {
  latitude: number;
  longitude: number;
}

interface MapComponentProps {
  //TODO: the location props probably shouldn't be optional
  targetLocation?: Location;
  driverLocation?: Location;
}
const MapComponent = ({ driverLocation, targetLocation }: MapComponentProps) => {
  const [viewport, setViewport] = useState({
    ...(driverLocation || targetLocation),
    zoom: 12,
  });

  const handleViewportChange = (event: ViewStateChangeEvent) => {
    setViewport(event.viewState);
  };

  return (
    <Layout style={{ height: '100vh', width: '100%' }}>
      <MapGL
        {...viewport}
        onMove={handleViewportChange}
        style={{ borderRadius: '10px' }}
        mapStyle='mapbox://styles/mapbox/streets-v11'
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {targetLocation && (
          <Marker latitude={targetLocation.latitude} longitude={targetLocation.longitude} />
        )}
        {driverLocation && (
          <Marker latitude={driverLocation.latitude} longitude={driverLocation.longitude}>
            <Space className='bg-customYellowPrimary  rounded-full w-10 h-10 flex items-center justify-center'>
              <PickupsIcon className='mt-2' />
            </Space>
          </Marker>
        )}
      </MapGL>
    </Layout>
  );
};

export default MapComponent;
