import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const MoreOutline = ({ width = 30, height = 30, color = '#38383E', ...props }: CustomSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M7.636 13.16a1.84 1.84 0 1 1 0 3.681 1.84 1.84 0 0 1 0-3.682Zm7.364 0a1.84 1.84 0 1 1 0 3.681 1.84 1.84 0 0 1 0-3.682Zm7.364 0a1.84 1.84 0 1 1 0 3.681 1.84 1.84 0 0 1 0-3.682Z'
    />
  </svg>
);

export default MoreOutline;
