import { SVGProps } from 'react';

interface ExportIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ExportIcon = ({ width = 20, height = 20, color = '#939496', ...props }: ExportIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M17.495 6.509v10.053c0 1.209-.98 2.188-2.188 2.188h-10.6a2.188 2.188 0 0 1-2.187-2.188V3.438c0-1.209.98-2.188 2.187-2.188h7.534c.332 0 .65.132.884.366l2.004 2.004v.001l2 2.005c.235.234.366.551.366.883ZM10.938 2.5h-6.23a.938.938 0 0 0-.938.938v13.125c0 .517.42.937.937.937h10.6c.518 0 .938-.42.938-.938V7.187h-4.058c-.69 0-1.25-.56-1.25-1.25V2.5Zm-.305 9.116.807-.808a.625.625 0 0 1 .884.884l-1.875 1.875a.625.625 0 0 1-.883 0L7.69 11.692a.625.625 0 0 1 .884-.884l.809.808V8.018a.625.625 0 0 1 1.25 0v3.598Zm5.042-5.679-1.43-1.433L12.241 2.5h-.053v3.437h3.487Z'
      clipRule='evenodd'
    />
  </svg>
);

export default ExportIcon;
