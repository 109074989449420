import { SVGProps } from 'react';

interface NewOrdersProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
  backgroundColor?: string;
}

const NewOrders = ({
  width = 40,
  height = 40,
  fillColor = '#38383E',
  ...props
}: NewOrdersProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M3.338.23H14.74c1.723 0 3.122 1.399 3.122 3.122v11.39c0 1.723-1.399 3.122-3.122 3.122H3.338A3.124 3.124 0 0 1 .215 14.74V3.351C.215 1.63 1.615.23 3.338.23Zm2.569 1.363h-2.57A1.76 1.76 0 0 0 1.58 3.352v11.39c0 .97.788 1.758 1.759 1.758H14.74a1.76 1.76 0 0 0 1.759-1.758V3.351a1.76 1.76 0 0 0-1.76-1.759H12.11V4.89c0 .764-.62 1.384-1.383 1.384H7.29c-.764 0-1.384-.62-1.384-1.384V1.593Zm4.819 0H7.29a.02.02 0 0 0-.02.02V4.89c0 .012.01.02.02.02h3.436a.02.02 0 0 0 .02-.02V1.613a.02.02 0 0 0-.02-.02Z'
      clipRule='evenodd'
    />
  </svg>
);

export default NewOrders;
