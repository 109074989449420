import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useOrderByUID } from '@/hooksApi/useOrder';
import { TimelineItemProps } from '@/components/OrderTracker/OrderTracker';
import { mapOrderStatusToValue } from '@/utils/getStatus';

const useOrderTimeline = (orderId: string) => {
  const { t } = useTranslation();
  const { data, isLoading } = useOrderByUID(orderId);

  const timelineItems = useMemo(() => {
    if (!data?.object?.statuses) return [] as TimelineItemProps[];

    return data.object.statuses
      .map((status) => ({
        day: dayjs(status?.pivot?.created_at).format('DD'),
        month: dayjs(status?.pivot?.created_at).format('MMM'),
        time: dayjs(status?.pivot?.created_at).format('hh:mm'),
        title: status?.merchant_title,
        description: 'description',
        status: mapOrderStatusToValue(status?.title || '') || 'created',
      }))
      .reverse();
  }, [data, t]);

  return { timelineItems, isLoading, data };
};

export default useOrderTimeline;
