import * as React from 'react';
import { SVGProps } from 'react';

interface CalendarOutlineProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CalendarOutline: React.FC<CalendarOutlineProps> = ({
  width = 20, // default width
  height = 20, // default height
  color = '#38383E', // default color
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M7.5 1.833v1.084h5V1.833c0-.092.075-.166.167-.166h.917c.092 0 .167.074.167.166v1.084h1.666a2.5 2.5 0 0 1 2.5 2.5v10.416a2.5 2.5 0 0 1-2.5 2.5H4.584a2.5 2.5 0 0 1-2.5-2.5V5.417a2.5 2.5 0 0 1 2.5-2.5h1.667V1.833c0-.092.074-.166.166-.166h.917c.092 0 .167.074.167.166Zm9.167 6.5H3.334v7.5c0 .666.52 1.21 1.177 1.248l.073.002h10.833c.666 0 1.21-.52 1.248-1.176l.002-.074v-7.5ZM6.251 4.167H4.584c-.666 0-1.21.52-1.248 1.176l-.002.074v1.666h13.333V5.417c0-.666-.52-1.21-1.176-1.248l-.074-.002h-1.666V5.25a.167.167 0 0 1-.167.167h-.917a.167.167 0 0 1-.166-.167V4.167h-5V5.25a.167.167 0 0 1-.167.167h-.917a.167.167 0 0 1-.166-.167V4.167Z'
    />
  </svg>
);

export default CalendarOutline;
