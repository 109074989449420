import useAuthRedirect from '@/hooks/useAuthRedirect';

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute = ({ element }: PrivateRouteProps) => {
  useAuthRedirect();
  return element;
};

export default PrivateRoute;
