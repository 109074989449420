import { Col, Flex, Input, Row, Select, Tag } from 'antd';
import { Heading } from '../Heading';
import { CloseCircleIcon, FiltersIcon, SearchIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { LOCAL_AR } from '@/config';
import { Dispatch, SetStateAction, useState } from 'react';

import { FiltersModal } from '../FiltersModal';
import { getSearchItems } from '@/utils/getSearchItems ';

const { Option } = Select;

interface FilterHeaderProps {
  filterItems: string[];
  includeSearchBox?: boolean;
  setSearchValue?: (value?: any) => void;
  searchValue?: ISearch;
}

export interface ISearch {
  search: string | null;
  searchBy: string | null;
}

const FilterHeader = ({
  filterItems,
  includeSearchBox = true,
  setSearchValue,
  searchValue,
}: FilterHeaderProps) => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const { t } = useTranslation();

  const searchItems = getSearchItems(t);

  // handle change searchBy / Search input
  const handleSearchChange = (type: 'search' | 'searchBy', value: string | null) => {
    setSearchValue?.((prev: ISearch) => ({ ...prev, [type]: value }));
  };

  return (
    <Row className='mx-[-5px]'>
      <Col lg={18} md={16} sm={24} xs={24}>
        {/* <Flex gap='4px 16px' wrap align='center'>
          <Flex
            className='cursor-pointer'
            onClick={() => {
              setFiltersModalOpen((prev) => !prev);
            }}
          >
            <Heading
              leadingIcon={<FiltersIcon width={18} height={20} />}
              title={t('filters')}
              fontSize='text-base'
              rotation={false}
            />
          </Flex>
          {filterItems?.map((filter, index) => (
            <Tag
              bordered={false}
              closable
              className='rounded-full bg-customGrayShade3 mr-0'
              key={index}
            >
              {filter}
            </Tag>
          ))}
          <Heading
            trailingIcon={<CloseCircleIcon className={i18n.language === LOCAL_AR ? 'mt-2' : ''} />}
            title={t('clear_all')}
            fontSize='text-sm'
            fontWeight='font-bold'
          />
        </Flex> */}
      </Col>
      {includeSearchBox && (
        <Col lg={6} md={8} sm={24} xs={24} className='mt-2 sm:mt-0'>
          {/* <Flex gap='16px'>
            <Select
              placeholder={t('search_by')}
              className='w-1/2 min-w-[140px] capitalize'
              allowClear
              onChange={(value) => handleSearchChange('searchBy', value)}
              defaultValue={searchValue?.searchBy || ''}
            >
              {searchItems?.map((search, index) => (
                <Option key={index} value={search?.value} className='capitalize'>
                  {search?.label}
                </Option>
              ))}
            </Select>
            <Input
              size='small'
              placeholder={t('search')}
              prefix={<SearchIcon />}
              className='w-full rounded-lg'
              onChange={(e) => handleSearchChange('search', e.target.value)}
              allowClear
            />
          </Flex> */}
        </Col>
      )}
      <FiltersModal visible={filtersModalOpen} onClose={() => setFiltersModalOpen(false)} />
    </Row>
  );
};

export default FilterHeader;
