import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ArrowLeftIcon = ({
  width = 20,
  height = 20,
  color = '#000', // Default color
  ...props
}: CustomSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='m3.35 10.409 5.2 5.2a.626.626 0 0 1-.884.883L1.4 10.226a.625.625 0 0 1 0-.885l6.266-6.266a.625.625 0 0 1 .885.883l-5.2 5.2h14.74a.626.626 0 0 1 0 1.25H3.352Z'
      clipRule='evenodd'
    />
  </svg>
);

export default ArrowLeftIcon;
