import React, { ReactNode } from 'react';
import { Table, ConfigProvider } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { THEME_VARIABLE } from '@/config';
import { useTableAction } from '@/hooks';

export interface ITable {
  [key: string]: any;
}

interface ReusableTableProps<T extends ITable> extends TableProps<T> {
  columns: ColumnsType<T> | any;
  data: ITable[];
  pageSize: number;
  current?: number;
  total?: number;
  onPaginationChange?: (page: number, pageSize?: number) => void;
  rowSelectionEnabled?: boolean;
  header?: ReactNode;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: (values: React.Key[]) => void;
  setSelectedRow?: (values: T[]) => void;
  hidePagination?: boolean;
  loading?: boolean;
  customClassName?: string;
  fixed?: 'left' | 'right' | undefined;
}

function TableAction<T extends ITable>({
  columns,
  data,
  pageSize,
  current,
  total,
  onPaginationChange,
  rowSelectionEnabled = false,
  header,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedRow,
  hidePagination = false,
  loading = false,
  customClassName,
  ...props
}: ReusableTableProps<T>) {
  const { customTitle } = useTableAction();

  const rowSelection = rowSelectionEnabled
    ? {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[], selectedRows: T[]) => {
          setSelectedRowKeys?.(newSelectedRowKeys);
          setSelectedRow?.(selectedRows);
        },
      }
    : undefined;

  const existHeader = header ? () => header : undefined;

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorPrimary: THEME_VARIABLE.customTunaPrimary,
            colorPrimaryHover: THEME_VARIABLE.customTunaPrimary,
            colorBorder: THEME_VARIABLE.customTunaPrimary,
          },
          Table: {
            headerBg: THEME_VARIABLE.colorWhite,
            headerSplitColor: 'transparent',
          },
        },
      }}
    >
      <Table<T>
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data as any}
        title={existHeader}
        loading={loading}
        className={customClassName}
        pagination={
          hidePagination
            ? false
            : {
                pageSize,
                current,
                total,
                showTotal: (total, range) => customTitle(total, range),
                onChange: onPaginationChange,
              }
        }
        footer={() => <></>}
        scroll={{ x: 'max-content' }}
        {...props}
      />
    </ConfigProvider>
  );
}

export default TableAction;
