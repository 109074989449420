import {
  BottomOrderEditCard,
  DeliveryInstructionEditCard,
  Heading,
  OrderIdEditCard,
  PackageAndPaymentEditCard,
  PickupDropEditCard,
  // ProductDetailsCard,
} from '@/components';

import { CustomerDetailsCard } from '@/components/OrderForm';
import ProductDetailsCard from '@/components/OrderForm/ProductDetailsCard';
import { ROUTE } from '@/config';
import { useOrderUidEdit, usePickupAddressEdit, useProductDetails } from '@/hooks';
import { useOrderByUID, usePreviewOrderPrice, useUpdateOrder } from '@/hooksApi/useOrder';
import { useFetchListProvinces, useFetchAreaByProvinceCode } from '@/hooksApi/useRegion';
import { useContactsStore } from '@/stores/contactStore';
import { Preview, Product } from '@/types';
import { attachCountryCodeToPhone, separateCountryCodeFromPhone } from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Form, message, Row, Spin } from 'antd';
import { Store } from 'antd/es/form/interface';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useEffect, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
interface EditOrderProps {
  keyValue?: string;
}

const orderPriceDefaultValue = {
  orders_value: 0,
  orders_value_with_shipment_fee: 0,
  orders_shipment_fee: 0,
  orders_value_with_platform_shipment_fee: 0,
  orders_custom_shipment_fee: 0,
};

const EditOrder = ({ keyValue }: EditOrderProps) => {
  const [customShipmentFee, setCustomShipmentFee] = useState<number | undefined>(undefined);
  const [orderPrice, setOrderPrice] = useState<Preview>(orderPriceDefaultValue);

  const { orderUid } = useParams();
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const regionUid = useContactsStore((state) => state.regionUid); // Access the region UID from Zustand
  const setOrderUidEdit = useOrderUidEdit((state) => state.setOrderUidEdit); // Set the order UID from Zustand
  setOrderUidEdit(orderUid!);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { mutate: mutateEditOrder, isLoading: editOrderLoading } = useUpdateOrder(orderUid!);
  const { mutate: previewOrderPrice, isLoading: orderPriceLoading } = usePreviewOrderPrice();
  const { data: orderData, isLoading: displayOrderLoading } = useOrderByUID(orderUid!); // Use useOrderByUID to fetch the order data
  const queryClient = useQueryClient();
  const { data: provinceList, isLoading: provinceListLoading } = useFetchListProvinces();

  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );
  useEffect(() => {
    if (!areaListLoading && areaList && areaList?.object.length > 0) {
      const area = areaList.object.find((item) => item.uid === orderData?.object.region?.uid);

      form.setFieldsValue({ region_uid: area?.uid });
    }
  }, [areaListLoading, areaList, orderData, keyValue]);

  useEffect(() => {
    form.resetFields();

    if (orderData) {
      setOrderPrice({
        ...orderData.object.preview,
      });
      setCustomShipmentFee(orderData.object.fee_customer_payable);

      const provinceCode = provinceList?.object.find(
        (item) => item.province_code === orderData.object.region?.province_code,
      )?.province_code;
      const area_uid =
        provinceCode && provinceCode === selectedProvinceCode
          ? orderData.object.region?.uid
          : undefined;
      if (provinceCode && provinceCode !== selectedProvinceCode)
        setSelectedProvinceCode(provinceCode);

      const primaryPhoneInfo = separateCountryCodeFromPhone(orderData.object.phone);
      const additionalPhones: string[] = orderData.object.additional_phones
        ? JSON.parse(orderData.object.additional_phones)
        : null;

      const secondaryPhoneInfo =
        additionalPhones?.length > 0
          ? separateCountryCodeFromPhone(additionalPhones[0])
          : undefined;

      console.log({ additionalPhones, secondaryPhoneInfo });
      // Prepopulate the form with fetched order data
      form.setFieldsValue({
        name: orderData.object?.full_name || '',
        address: orderData.object?.address_text || '',
        deliveryInstruction: orderData?.object?.description || '',
        packageSize: orderData?.object?.size || '',
        isFragile: orderData?.object?.is_fragile,
        CodOrPrepaid: orderData?.object?.payment_type,
        CustomerOrMerchant: orderData?.object?.shipment_fee_type,
        orderId: orderData?.object?.custom_id,
        pickupOrDropoff: orderData?.object?.pick_up_type,
        pick_up_address_uid: orderData?.object?.pick_up_address.uid,
        phoneNumberPrimary: primaryPhoneInfo.phone || '',
        countryCodePrimary: primaryPhoneInfo.countryCode,
        countryCodeSecondary: secondaryPhoneInfo?.countryCode,
        secondaryPhoneNumber: secondaryPhoneInfo?.phone,
        province: provinceCode,
        region_uid: area_uid,
      });

      // Set the products if available
      if (orderData?.object?.products) {
        resetProducts(orderData?.object.products); // Reset products if data is available
      }
    }
  }, [orderData, form, keyValue]); // Include 'form' in the dependency array

  /*HOOK TO CREATE NEW PRODUCT AND ARRAY OF ALL PRODUCTS  */
  const {
    products,
    newProduct,
    handleAddProduct,
    handleDeleteProduct,
    handleChangeNewProduct,
    handleChangeProduct,
    resetProducts,
  } = useProductDetails();

  const transformValuesToUpdateOrder = (values: Store, ready_to_pick_up: boolean = false) => {
    // Ensure the phone number is correctly formatted
    const primaryPhoneNumber = values?.phoneNumberPrimary
      ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
      : undefined;

    const secondaryPhoneNumber = values?.secondaryPhoneNumber
      ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
      : undefined;

    return {
      fee_customer_payable: customShipmentFee,
      is_fragile: values?.isFragile || false,
      ready_to_pick_up,
      shipment_fee_type: values?.CustomerOrMerchant || 'BY_MERCHANT',
      payment_type: values?.CodOrPrepaid || 'COLLECT_ON_DELIVERY',
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || null,
      custom_id: values?.orderId || null, // Custom ID
      pick_up_address_uid: values?.pick_up_address_uid || '',
      size: values?.packageSize || 'S', // Default size if not selected
      //TODO: check the contact property
      contact: {
        full_name: values?.name || '',
        address_text: values?.address || '',
        phone: primaryPhoneNumber || '', // Use the formatted phone number
        secondary_phone: secondaryPhoneNumber,
        region_uid: values.region_uid || '',
      },
      //
      full_name: values?.name || '',
      address_text: values?.address || '',
      phone: primaryPhoneNumber,
      secondary_phone: secondaryPhoneNumber,
      region_uid: values.region_uid,
      products: products.map((product) => ({
        title: product?.title || '',
        price: Number(product?.price) || 1, // Ensure price is a number
        quantity: Number(product?.quantity) || 1, // Ensure quantity is a number
      })),
    };
  };

  const onFinish = (values: Store) => {
    const orderPayload = transformValuesToUpdateOrder(values);

    // If saveToAddress is false, directly create the order
    mutateEditOrder(orderPayload, {
      onSuccess: () => {
        queryClient.refetchQueries(['orders-readyToShip', 1]);
        message?.success(t('order_edited_successfully'));
        navigate(ROUTE.ORDERS);
      },
      onError: (error) => {
        message.error(error?.response?.data?.message || t('failed_to_edit_order'));
      },
    });
  };
  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo);
    message.error(t('please_fill_the_all'));
  };

  const updateOrderPrice = (orderPayload: Store) => {
    // console.log('orderPayload:', orderPayload);

    // Ensure that products, contact details, and region UID are available
    if (
      products?.length > 0 &&
      orderPayload?.contact?.full_name &&
      orderPayload?.contact?.phone &&
      orderPayload?.contact?.address_text &&
      orderPayload?.pick_up_address_uid // Ensure pick_up_address_uid is set
    ) {
      previewOrderPrice(orderPayload, {
        onSuccess: (response) => {
          const {
            orders_value,
            orders_value_with_shipment_fee,
            orders_shipment_fee,
            orders_value_with_platform_shipment_fee,
            orders_custom_shipment_fee,
          } = response.object;
          setOrderPrice({
            orders_value,
            orders_value_with_shipment_fee,
            orders_shipment_fee,
            orders_value_with_platform_shipment_fee,
            orders_custom_shipment_fee,
          });
        },
        onError: (error) => {
          message.error(error?.response?.data?.message || t('failed_to_preview_price'));
        },
      });
    } else {
      console.log('Required fields for price preview are missing');
    }
  };

  const onValuesChange = (_: Store, allValues: Store) => {
    const orderPayload = transformValuesToUpdateOrder(allValues, true);

    // Always call previewOrderPrice, even if the product array is empty

    updateOrderPrice(orderPayload);
  };
  const onPackageSizeChange = (size: string) => {
    const formValues = form.getFieldsValue(); // Get current form values
    const orderPayload = transformValuesToUpdateOrder({ ...formValues, packageSize: size }, true);

    updateOrderPrice(orderPayload); // Call the function to update the price
  };

  useEffect(() => {
    const formValues = form.getFieldsValue(); // Get the current form values
    const orderPayload = transformValuesToUpdateOrder(formValues, true); // Transform the form values

    // Only call updateOrderPrice if products or other required fields are present
    if (products?.length > 0) {
      updateOrderPrice(orderPayload);
    }
  }, [products, form, customShipmentFee]); // Trigger the effect when products change

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Flex vertical>
      {displayOrderLoading ? (
        <Spin size='large' className='flex justify-center items-center' />
      ) : (
        <Form
          form={form}
          name='add-new-order'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange} // Track form value changes
          onKeyDown={handleKeyPress}
        >
          <Heading
            title={t('edit_order')}
            routeLink='/orders'
            fontSize='text-2xl'
            fontWeight='font-black'
            extraClassName='leading-[27.84px] uppercase' // Adjust line-height if needed
          />

          <Row gutter={[24, 16]} className='mt-[34px] mb-3'>
            <Col lg={14} md={24} xs={24}>
              <CustomerDetailsCard
                form={form}
                // resetForm={resetForm} // Step 2: Pass resetForm as a prop
                title={t('customer_details')}
                namePlaceholder={t('name')}
                primaryPhonePlaceholder={t('primary_phone')}
                secondaryPhonePlaceholder={t('secondary_phone')}
                provincePlaceholder={t('province')}
                areaPlaceholder={t('area')}
                addressPlaceholder={t('address')}
                saveToAddress={t('save_to_address')}
                selectedProvinceCode={selectedProvinceCode}
                setSelectedProvinceCode={handleSetSelectedProvinceCode}
              />

              <DeliveryInstructionEditCard
                title={t('delivery_instruction')}
                description={t('lorem_ipsum')}
                placeholder={t('do_not_ring_door')}
              />

              <OrderIdEditCard
                title={t('order_id')}
                description={t('if_you_do_not')}
                placeholder={t('order_id_custom')}
              />
              <ProductDetailsCard
                title={t('product_details')}
                placeholders={{
                  title: t('product_name'),
                  quantity: t('qty'),
                  price: t('price'),
                }}
                products={products}
                newProduct={newProduct}
                handleAddProduct={handleAddProduct}
                handleDeleteProduct={handleDeleteProduct}
                handleChangeNewProduct={handleChangeNewProduct}
                handleChangeProduct={handleChangeProduct}
              />
              <PickupDropEditCard
                pickupOrDropoffProp={orderData?.object?.pick_up_type}
                form={form} // Pass the form instance here
                previewOrderPrice={updateOrderPrice} // Pass the price preview function
                transformValuesToPayload={(values: Store) =>
                  transformValuesToUpdateOrder(values, true)
                } // Pass the transformation function
                title={t('pickup_dropoff')}
                option1Label={t('boxy_collects')}
                option2Label={t('you_drop_off')}
                collectDescription={t('boxy_agent_will_collect')}
                dropoffDescription={t('you_drop_off_your_packages')}
              />
            </Col>
            <Col lg={10} md={24} xs={24}>
              <PackageAndPaymentEditCard
                form={form}
                onPackageSizeChange={onPackageSizeChange}
                orderPrice={orderPrice} // Pass orderPrice as a prop
                orderPriceLoading={orderPriceLoading}
                CodOrPrepaid={orderData?.object?.payment_type} // Pass CodOrPrepaid from orderData
                CustomerOrMerchant={orderData?.object?.shipment_fee_type} // Pass CustomerOrMerchant from orderData
                packageSize={orderData?.object?.size}
                onCustomShipmentFeeChange={setCustomShipmentFee}
                customShipmentFee={customShipmentFee}
              />
            </Col>
          </Row>
          <Row className=' sticky bottom-2'>
            <Col lg={24} md={24} xs={24}>
              <BottomOrderEditCard
                isLoading={editOrderLoading}
                createOrderTitle={t('update')}
                readyToShipTitle={t('create_ready_ship')}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Flex>
  );
};

export default EditOrder;
