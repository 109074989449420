// stores/usePickupDropStore.ts

import { create } from 'zustand';

interface PickupDropState {
  selectedPickupUid: string;

  setselectedPickupUid: (value: string) => void;
}

const usePickupAddressEdit = create<PickupDropState>((set) => ({
  selectedPickupUid: '',

  setselectedPickupUid: (uid) => set({ selectedPickupUid: uid }),
}));

export default usePickupAddressEdit;
