import { SVGProps } from 'react';

interface CloseOutlineProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CloseOutline = ({
  width = 20,
  height = 20,
  color = '#939496',
  ...props
}: CloseOutlineProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='m4.42 3.382 5.654 5.71 5.653-5.71a.167.167 0 0 1 .118-.05h1.282a.167.167 0 0 1 .118.285L10.95 9.975l6.344 6.407a.167.167 0 0 1-.119.284h-1.28a.166.166 0 0 1-.12-.05l-5.7-5.757-5.702 5.758a.167.167 0 0 1-.118.05h-1.28a.167.167 0 0 1-.12-.285L9.2 9.975 2.902 3.617a.167.167 0 0 1 .119-.284H4.3c.045 0 .088.018.12.05Z'
    />
  </svg>
);

export default CloseOutline;
