import { PickupRequest } from './pickup';
import { RegionItem } from './region';

export enum PickupAddressType {
  COD = 'COD_DROP_OFF_ADDRESS',
  PICKUP = 'WAREHOUSE',
}
// Define PickupAddress
export interface PickupAddress {
  uid: string;
  platform_code: string;
  title: string;
  type: string;
  default: boolean;
  full_name: string;
  phone: string;
  email: string;
  address_text: string;
  lat: string;
  lng: string;
  description: string;
  region_id: number;
}

// Define PickupAddressResponse
export interface PickupAddressResponse {
  status: string;
  code: number;
  message: string;
  object: PickupAddress[];
}

export interface PickupFetchAddressResponse {
  status: string;
  code: number;
  message: string;
  object: {
    items: PickupAddress[];
    page: number;
    total: number;
    pages: number;
    perPage: number;
  };
}

export interface PickupLastCodResponse {
  status: string;
  code: number;
  message: string;
  object: PickupAddress;
}

export interface UpdatePickupParams {
  data: PickupRequest;
  uid: string;
}

export interface SinglePickupAddressResponse {
  status: string;
  code: number;
  message: string;
  object: {
    uid: string;
    platform_code: string;
    type: string;
    default: number;
    full_name: string;
    phone: string;
    email: string;
    address_text: string;
    lat: string;
    lng: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    region: RegionItem;
  };
}
