import { axiosJSONInstance, axiosMultipartInstance } from '@/config';
import {
  RegisterResponse,
  RegisterParams,
  UsersResponse,
  LoginParams,
  VerifyParams,
  AuthResponse,
  FetchUserResponse,
  UpdateProfileParams,
  FetchCompanyResponse,
  UpdateCompanyParams,
} from '@/types';

// Fetch users
export const fetchUsers = async (page: number = 1): Promise<UsersResponse> => {
  const response = await axiosJSONInstance.get(`/users?page=${page}`);
  return response.data;
};

// register user
export const registerUser = async (data: RegisterParams): Promise<RegisterResponse> => {
  const response = await axiosJSONInstance.post(`/merchants/auth/register`, data);
  return response.data;
};

// login user
export const loginUser = async (data: LoginParams): Promise<RegisterResponse> => {
  const response = await axiosJSONInstance.post('/merchants/auth/login', data);
  return response.data;
};

// verify otp code user
export const verifyOtpUser = async (data: VerifyParams): Promise<AuthResponse> => {
  const response = await axiosJSONInstance.post('/auth/verify', data);
  return response.data;
};

// Fetch user
export const fetchUser = async (): Promise<FetchUserResponse> => {
  const response = await axiosJSONInstance.get(`/user`);
  return response.data;
};

// refreshToken user
export const refreshTokenUser = async (): Promise<AuthResponse> => {
  const response = await axiosJSONInstance.post('/auth/refresh');
  return response.data;
};

// Update user
export const updateUser = async (
  data: Partial<UpdateProfileParams>,
): Promise<FetchUserResponse> => {
  const response = await axiosJSONInstance.patch(`/merchants/profile`, data);
  return response.data;
};

// Upload Avatar
export const uploadAvatar = async (data: FormData): Promise<FetchUserResponse> => {
  const response = await axiosMultipartInstance.post(`/merchants/profile/avatar`, data);
  return response.data;
};

// Fetch company details
export const fetchCompany = async (): Promise<FetchCompanyResponse> => {
  const response = await axiosJSONInstance.get('/merchants/profile/company');
  return response.data;
};

// Update company profile
export const updateCompanyProfile = async (
  data: Partial<UpdateCompanyParams>,
): Promise<FetchCompanyResponse> => {
  const response = await axiosJSONInstance.patch('/merchants/profile/company', data);
  return response.data;
};

// Upload company logo
export const uploadCompanyLogo = async (data: FormData): Promise<FetchCompanyResponse> => {
  const response = await axiosMultipartInstance.post('/merchants/profile/company/avatar', data);
  return response.data;
};
