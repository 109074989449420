import React from 'react';
import { Button, Checkbox, Col, Flex, Form, Image, Input, Select, Typography } from 'antd';
import type { Store } from 'antd/es/form/interface'; // Import the type for the form values
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'; // Import the correct type for errorInfo
import { Link } from 'react-router-dom';
import { countries } from '@/utils/CountryCodes';
import { useTranslation } from 'react-i18next';
import { FORM_TYPE_REGISTER, LOCAL_EN } from '@/config';
// import { convertArabicToEnglishNumbers } from '@/utils';

const { Option } = Select;

interface RegisterFormProps {
  // direction: string;
  countryCode: string;
  setCountryCode: (code: string) => void;
  firstNameInput: string;
  setFirstNameInput: (value: string) => void;
  lastNameInput: string;
  setLastNameInput: (value: string) => void;
  phoneNumberInput: string;
  setPhoneNumberInput: (value: string) => void;
  onFinish: (values: Store) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity) => void;
  createAccountText: string;
  firstNamePlaceholder: string;
  lastNamePlaceholder: string;
  countryCodePlaceholder: string;
  phoneNumberPlaceholder: string;
  termsAndConditionsText: string;
  agreeWithText: string;
  registerButtonText: string;
  loginText: string;
  loginLinkText: string;
  isLoading?: boolean;
}

const RegisterFormCard: React.FC<RegisterFormProps> = ({
  // direction,
  countryCode,
  setCountryCode,
  firstNameInput,
  setFirstNameInput,
  lastNameInput,
  setLastNameInput,
  phoneNumberInput,
  setPhoneNumberInput,
  onFinish,
  onFinishFailed,
  createAccountText,
  firstNamePlaceholder,
  lastNamePlaceholder,
  phoneNumberPlaceholder,
  termsAndConditionsText,
  agreeWithText,
  registerButtonText,
  loginText,
  loginLinkText,
  isLoading,
}) => {
  const { i18n } = useTranslation();
  const { Text } = Typography;

  //HANDLE TO REMOVE NON-NUMERIC CHARACTERS:
  // const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  //   const englishNumberValue = convertArabicToEnglishNumbers(numericValue); // Convert Arabic numbers to English
  //   event.target.value = englishNumberValue;
  // };
  return (
    <Col span={10} className='bg-white'>
      <Form
        name={FORM_TYPE_REGISTER}
        initialValues={{ remember: true, countryCode: countryCode }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='h-full px-[195px]   flex flex-col mt-6 justify-center items-center mx-auto'
      >
        <Col className='h-full flex flex-col justify-between min-w-[344px]  py-[50px]'>
          <Flex vertical>
            <Form.Item>
              <Text className='text-[24px] block uppercase  mb-4 text-customTunaPrimary font-[900] leading-[27.8px]'>
                {createAccountText.split(' ').slice(0, 2).join(' ')}
                <br />
                {createAccountText.split(' ').slice(2).join(' ')}
              </Text>

              <Form.Item name='firstName' rules={[{ required: true, message: '' }]}>
                <Flex className='relative'>
                  <Input
                    placeholder={firstNamePlaceholder}
                    className='border placeholder:text-GrayShade4 font-[400] text-[16px] leading-[20.48px] border-solid border-customGrayShade5 h-[56px] rounded-[8px] px-4'
                    onChange={(e) => {
                      setFirstNameInput(e.target.value);
                    }}
                    suffix={<span className='text-customRed'>*</span>}
                  />
                </Flex>
              </Form.Item>

              <Form.Item name='lastName' rules={[{ required: true, message: '' }]}>
                <Flex className='relative'>
                  <Input
                    placeholder={lastNamePlaceholder}
                    className='border placeholder:text-GrayShade4 placeholder:font-[400] -mt-[8px] placeholder:text-[16px] placeholder:leading-[20.48px] border-solid border-customGrayShade5 rounded-[8px] h-[56px] px-4'
                    onChange={(e) => {
                      setLastNameInput(e.target.value);
                    }}
                    suffix={<span className='text-customRed'>*</span>}
                  />
                </Flex>
              </Form.Item>

              <Flex
                style={{ direction: 'ltr' }}
                className='flex justify-between items-center gap-x-3 -mt-[8px]'
              >
                <Form.Item
                  name='countryCode'
                  rules={[{ required: true, message: '' }]}
                  style={{ height: '80px' }} // Reserve space for error message
                >
                  <Select
                    style={{ direction: 'ltr' }}
                    bordered={false}
                    className='border border-solid border-customGrayShade5 h-[56px] min-w-[105px] rounded-[10px]'
                    onChange={setCountryCode}
                  >
                    {countries.map((country, index) => (
                      <Option key={index} value={country.dialCodes?.[0]}>
                        <span role='img'>
                          <Image preview={false} src={country.image} width={20} height={20} />
                        </span>{' '}
                        ({country.dialCodes?.[0]})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ height: '80px' }}
                  className='w-full'
                  name='phoneNumber'
                  rules={[{ required: true, message: '' }]}
                  // style={{  }} // Reserve space for error message
                >
                  <Flex className='relative'>
                    <Input
                      style={{ direction: 'ltr' }}
                      placeholder={phoneNumberPlaceholder}
                      className='border w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-[10px]'
                      onChange={(e) => {
                        setPhoneNumberInput(e.target.value);
                      }}
                      // onInput={handlePhoneInput} // Handle input to filter numbers
                      suffix={<span className='text-customRed'>*</span>}
                    />
                  </Flex>
                </Form.Item>
              </Flex>
            </Form.Item>
          </Flex>
          <Flex vertical>
            <Form.Item
              className='-mt-[45px]'
              name='agreement'
              valuePropName='checked'
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error(termsAndConditionsText)),
                },
              ]}
            >
              <Checkbox
                className={`text-customTunaPrimary   custom-register-checkbox  -mt-[27.5px] flex items-center  justify-center tracking-[-.32px] font-[400] text-[14px] leading-[11px]`}
              >
                <Flex className='flex items-center gap-x-1'>
                  <Text>{agreeWithText} </Text>
                  <Text className='text-customBlue font-[700]  tracking-[-.32px] text-[14px] leading-[11px]'>
                    {termsAndConditionsText}
                  </Text>
                </Flex>
              </Checkbox>
            </Form.Item>
            <Form.Item className='-mt-5'>
              <Button
                style={{ paddingBottom: '27px', paddingTop: '27px' }}
                type='primary'
                loading={isLoading}
                htmlType='submit'
                className='w-full uppercase  text-customTunaPrimary  flex justify-center items-center h-[56px]  text-sm font-bold rounded-[8px] leading-[13.54px] bg-customYellowPrimary'
              >
                {registerButtonText}
              </Button>
              <Text className='font-[400] mt-4 whitespace-nowrap pt-[2px]    flex justify-center gap-x-[4px] text-[16px] leading-[11px] text-customTunaPrimary'>
                {loginText}{' '}
                <Link
                  to={'/login'}
                  className='text-customBlue font-[700] text-[16px] leading-[11px]'
                >
                  {loginLinkText}
                </Link>
              </Text>
            </Form.Item>
          </Flex>
        </Col>
      </Form>
    </Col>
  );
};

export default RegisterFormCard;
