import { SVGProps } from 'react';

interface RedoIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const RedoIcon = ({ width = 24, height = 24, color = '#1677FF', ...props }: RedoIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='m5.159 2.337-1.1 1.09a.2.2 0 0 0-.06.142v2.828c0 .41.333.743.744.743h2.864a.2.2 0 0 0 .14-.058l1.097-1.086a.2.2 0 0 0-.14-.342H6.521A8.516 8.516 0 0 1 12 3.673c4.695 0 8.5 3.77 8.5 8.42s-3.805 8.42-8.5 8.42c-4.445 0-8.093-3.38-8.468-7.688a10.02 10.02 0 0 1-.026-.538.2.2 0 0 0-.2-.194H2.204a.2.2 0 0 0-.2.205c.005.2.011.357.019.47C2.373 17.924 6.706 22 12 22c5.523 0 10-4.436 10-9.907s-4.477-9.906-10-9.906a10.017 10.017 0 0 0-6.5 2.378V2.479a.2.2 0 0 0-.341-.142Z'
    />
  </svg>
);

export default RedoIcon;
