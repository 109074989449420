import { SVGProps } from 'react';

interface CollectedOutlineProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CollectedOutlineIcon = ({
  width = 33,
  height = 32,
  color = '#38383E',
  ...props
}: CollectedOutlineProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <g clipPath='url(#a)'>
      <path fill='#EBDD4C' d='M13.5 5h6v6h-6z' />
      <path
        fill={color}
        fillRule='evenodd'
        d='M8.195 3.137H24.92a4.582 4.582 0 0 1 4.58 4.58V24.42a4.582 4.582 0 0 1-4.58 4.58H8.195a4.582 4.582 0 0 1-4.58-4.58V7.716a4.582 4.582 0 0 1 4.58-4.58Zm3.768 2H8.195a2.58 2.58 0 0 0-2.58 2.58V24.42a2.58 2.58 0 0 0 2.58 2.58H24.92a2.58 2.58 0 0 0 2.58-2.58V7.716a2.58 2.58 0 0 0-2.58-2.58h-3.86v4.835c0 1.12-.91 2.03-2.03 2.03h-5.037a2.03 2.03 0 0 1-2.03-2.03V5.136Zm7.068 0h-5.038a.03.03 0 0 0-.03.03V9.97c0 .017.013.03.03.03h5.038a.03.03 0 0 0 .03-.03V5.166a.03.03 0 0 0-.03-.03Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.5 0h32v32H.5z' />
      </clipPath>
    </defs>
  </svg>
);
export default CollectedOutlineIcon;
