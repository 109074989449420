import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const MediumBtnIcon = ({ width = 20, height = 20, color = '#000', ...props }: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillOpacity={0.85}
      d='M18.462 5.285 8.841 14.82a.627.627 0 0 1-.938-.065.62.62 0 0 1-.066-.057l-6.3-6.242a.167.167 0 0 1 .118-.285h1.288c.044 0 .086.017.117.048L8.4 13.51l8.54-8.462A.167.167 0 0 1 17.057 5h1.288a.167.167 0 0 1 .117.285Z'
    />
  </svg>
);

export default MediumBtnIcon;
