import { SVGProps } from 'react';

interface FinanceIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const FinanceIcon = ({
  width = 20,
  height = 20,
  color = '#38383E',
  ...props
}: FinanceIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      d='M8.438 7.567V5.375c0-.041.003-.082.011-.121a1.76 1.76 0 0 1-.011-.204c0-.684.397-1.346 1.146-1.859.905-.62 2.363-1.041 4.01-1.041 1.647 0 3.104.421 4.01 1.041.748.513 1.146 1.175 1.146 1.86v11.7c0 .684-.398 1.345-1.147 1.858-.905.62-2.362 1.041-4.01 1.041-.265 0-.526-.01-.78-.032v-1.253c.252.023.514.035.78.035 1.354 0 2.56-.313 3.304-.822.351-.24.603-.507.603-.828v-.998c-.91.583-2.32.973-3.906.973-.266 0-.527-.01-.781-.032V15.44c.252.023.514.035.78.035 1.354 0 2.56-.313 3.304-.822.351-.24.603-.507.603-.828v-.997c-.91.582-2.32.973-3.906.973-.266 0-.527-.012-.781-.033v-1.253c.252.023.514.035.78.035 1.354 0 2.56-.313 3.304-.822.351-.24.603-.507.603-.828v-.998c-.91.583-2.32.974-3.906.974-.266 0-.527-.012-.781-.033V9.59c.252.023.514.035.78.035 1.354 0 2.56-.313 3.304-.822.351-.24.603-.507.603-.828v-.998c-.91.583-2.32.974-3.906.974-1.586 0-2.996-.391-3.906-.974v1.007l-1.25-.417ZM17.5 5.05c0-.32-.252-.587-.603-.827-.744-.51-1.95-.822-3.303-.822-1.354 0-2.56.312-3.303.822-.352.24-.604.506-.604.827 0 .321.252.587.604.828.743.51 1.95.822 3.303.822s2.559-.313 3.303-.822c.351-.24.603-.507.603-.828Z'
      clipRule='evenodd'
    />
    <path
      fill={color}
      fillRule='evenodd'
      d='M1.262 11.104a1.758 1.758 0 0 1-.012-.204c0-.684.398-1.346 1.147-1.859C3.302 8.421 4.759 8 6.407 8c1.647 0 3.103.421 4.009 1.041.749.513 1.146 1.175 1.146 1.86v5.85c0 .684-.397 1.345-1.146 1.858-.906.62-2.362 1.042-4.01 1.042-1.647 0-3.104-.422-4.01-1.042-.748-.513-1.146-1.174-1.146-1.859v-2.6c0-.041.004-.082.012-.121a1.758 1.758 0 0 1-.012-.204v-2.6c0-.041.004-.082.012-.121Zm9.05-.204c0-.32-.251-.587-.602-.827-.745-.51-1.95-.822-3.304-.822-1.353 0-2.559.312-3.303.822-.351.24-.603.506-.603.827 0 .321.252.587.603.828.744.51 1.95.822 3.303.822 1.354 0 2.56-.313 3.304-.822.35-.24.602-.507.602-.828Zm0 1.928c-.91.582-2.32.973-3.906.973-1.586 0-2.995-.391-3.906-.973v.997c0 .321.252.587.603.828.744.51 1.95.822 3.303.822 1.354 0 2.56-.313 3.304-.822.35-.24.602-.507.602-.828v-.997ZM2.5 15.753v.997c0 .321.252.587.603.828.744.51 1.95.822 3.303.822 1.354 0 2.56-.313 3.304-.822.35-.24.602-.507.602-.828v-.997c-.91.582-2.32.973-3.906.973-1.586 0-2.995-.391-3.906-.973Z'
      clipRule='evenodd'
    />
  </svg>
);

export default FinanceIcon;
