/* eslint-disable react/prop-types */
import { THEME_VARIABLE } from '@/config';
import { Flex, Upload, Button, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';
import { DotIcon, UserOutlineIcon, EditOutline } from '../Icons';

interface ImageRequirementCardProps {
  handleAvatarUpload: (file: any) => void; // Function type for handling file upload
  isAvatarUploading: boolean; // Boolean to show loading state
  avatarUrl: string | undefined; // URL of the uploaded avatar
}

const ImageRequirementCard: React.FC<ImageRequirementCardProps> = ({
  handleAvatarUpload,
  isAvatarUploading,
  avatarUrl,
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Flex className='relative border border-solid border-customGrayShade4 rounded-full w-[74px] h-[74px] flex justify-center items-center'>
        <Avatar icon={<UserOutlineIcon width={37} height={37} />} src={avatarUrl} size={74} />

        <Flex className='absolute left-[70%] top-[55%] cursor-pointer flex justify-center items-center w-[32px] h-[32px] rounded-full bg-customYellowPrimary border border-solid border-white'>
          {/* File Upload Component */}
          <Upload
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleAvatarUpload} // Use parent's upload handler
            showUploadList={false}
          >
            <Button
              icon={<EditOutline />}
              loading={isAvatarUploading}
              disabled={isAvatarUploading}
              className='p-0 border-none bg-transparent'
            />
          </Upload>
        </Flex>
      </Flex>

      <Flex vertical className='mx-[27px]'>
        <Heading
          title={t('image_requirement')}
          fontSize='text-xs'
          fontWeight='font-normal'
          color='text-customGrayShade5'
          extraClassName='leading-[14.4px] tracking-[-.32px] capitalize mb-[6px]'
        />
        <Heading
          leadingIcon={<DotIcon color={THEME_VARIABLE.customTunaPrimary} />}
          title={t('supported_formats')}
          fontSize='text-xs'
          fontWeight='font-normal'
          color='text-customTunaPrimary'
          extraClassName='leading-4 tracking-[-.32px] capitalize'
        />
        <Heading
          leadingIcon={<DotIcon color={THEME_VARIABLE.customTunaPrimary} />}
          title={t('maximum_file_size')}
          fontSize='text-xs'
          fontWeight='font-normal'
          color='text-customTunaPrimary'
          extraClassName='leading-4 tracking-[-.32px] capitalize'
        />
        <Heading
          leadingIcon={<DotIcon color={THEME_VARIABLE.customTunaPrimary} />}
          title={t('aspect_ratio')}
          fontSize='text-xs'
          fontWeight='font-normal'
          color='text-customTunaPrimary'
          extraClassName='leading-4 tracking-[-.32px] capitalize'
        />
        <Heading
          leadingIcon={<DotIcon color={THEME_VARIABLE.customTunaPrimary} />}
          title={t('minimum_recommended_resolution')}
          fontSize='text-xs'
          fontWeight='font-normal'
          color='text-customTunaPrimary'
          extraClassName='leading-4 tracking-[-.32px] capitalize'
        />
      </Flex>
    </Flex>
  );
};

export default ImageRequirementCard;
