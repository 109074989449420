import React, { SVGProps } from 'react';

interface ScanCodeOutlineIconProps extends SVGProps<SVGSVGElement> {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const ScanCodeOutlineIcon: React.FC<ScanCodeOutlineIconProps> = ({
  width = 12,
  height = 12,
  color = '#000',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 12 12' // Define the viewBox to ensure proper scaling
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillOpacity={0.85}
      d='M1.65 8a.1.1 0 0 1 .1.1v1.4c0 .4.312.726.706.749l.044.001h1.4a.1.1 0 0 1 .1.1v.55a.1.1 0 0 1-.1.1H2.5A1.5 1.5 0 0 1 1 9.5V8.1a.1.1 0 0 1 .1-.1h.55Zm9.25 0a.1.1 0 0 1 .1.1v1.4A1.5 1.5 0 0 1 9.5 11H8.1a.1.1 0 0 1-.1-.1v-.55a.1.1 0 0 1 .1-.1h1.4a.75.75 0 0 0 .749-.706l.001-.044V8.1a.1.1 0 0 1 .1-.1h.55ZM9.5 1A1.5 1.5 0 0 1 11 2.5v1.4a.1.1 0 0 1-.1.1h-.55a.1.1 0 0 1-.1-.1V2.5a.75.75 0 0 0-.706-.749L9.5 1.75H8.1a.1.1 0 0 1-.1-.1V1.1a.1.1 0 0 1 .1-.1h1.4ZM3.9 1a.1.1 0 0 1 .1.1v.55a.1.1 0 0 1-.1.1H2.5a.75.75 0 0 0-.749.706L1.75 2.5v1.4a.1.1 0 0 1-.1.1H1.1a.1.1 0 0 1-.1-.1V2.5A1.5 1.5 0 0 1 2.5 1h1.4Z'
    />
  </svg>
);

export default ScanCodeOutlineIcon;
