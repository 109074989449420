import { SVGProps } from 'react';

interface LeftIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const LeftIcon = ({ width = 24, height = 24, color = '#38383E', ...props }: LeftIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M15.853 2.556l-9.091 8.775a.748.748 0 0 0-.05 1.007l.05.053 9.09 9.05a.2.2 0 0 0 .141.059h1.564a.2.2 0 0 0 .14-.342L8.39 11.891l9.298-9.048a.2.2 0 0 0-.14-.343h-1.556a.2.2 0 0 0-.14.056Z'
    />
  </svg>
);

export default LeftIcon;
