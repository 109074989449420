import {
  BottomSheet,
  Card,
  CloseOutline,
  DriverInfo,
  Heading,
  MapComponent,
  OrderStatus,
  PaymentStatus,
  ProductDetails,
} from '@/components';
import { OrderItemStatus } from '@/components/PickupsCard/OrderStatus';
import { ROUTE } from '@/config';
import { useFetchOrderRTOById } from '@/hooksApi/useOrder';
import { getStatusActiveOrderRTO } from '@/utils/getStatus';
import { Col, Flex, Layout, MenuProps, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const OrderRtoSingle = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: dataSingleRTO, isLoading: loadingSingleRTO } = useFetchOrderRTOById(id as string);

  const items: OrderItemStatus[] = [
    {
      title: t('rto-scheduled'),
      // description: '12:23 PM',
    },
    {
      title: t('rto-in-transit'),
      // description: '12:23 PM',
    },
    {
      title: t('rto-delivered'),
    },
  ];

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: <Heading title={t('cancel_pickup')} leadingIcon={<CloseOutline />} rotation={false} />,
    },
  ];

  if (loadingSingleRTO) return <Spin />;

  return (
    <Layout className='relative h-fit'>
      {/* Pickup Title */}
      <Row>
        <Col md={24}>
          <Heading
            routeLink={ROUTE.ORDERS}
            title={t('rto_details')}
            fontSize='text-2xl'
            fontWeight='font-bold'
          />
        </Col>
      </Row>
      {/* End of Pickup Title */}

      <Row gutter={[24, 16]} className='mt-6'>
        {/* Map */}
        <Col md={14}>
          <Flex vertical className={`max-h-[28rem] rounded-[10px] shadow-card-dark`}>
            <MapComponent />
          </Flex>
        </Col>
        {/*End of Map */}

        {/* ORDERS STATUS And Payment */}
        <Col md={10}>
          <Flex vertical justify='space-between h-full flex flex-col'>
            <Card extraClassName='px-0 py-6 mb-5'>
              <OrderStatus
                statusMessage={t(dataSingleRTO?.object?.order_current_status?.title || 'new')}
                items={items}
                current={getStatusActiveOrderRTO(
                  dataSingleRTO?.object?.order_current_status?.title || '',
                )}
              />
            </Card>
            <Card>
              <PaymentStatus
                headerTitle={t('payment')}
                titleFirst={t('order_value')}
                total={Number(dataSingleRTO?.object?.total_price) || 0}
                valueFirst={Number(dataSingleRTO?.object?.order_value) || 0}
              />
            </Card>
          </Flex>
        </Col>
        {/* End of ORDERS STATUS And Payment */}
      </Row>

      {/* DRIVER INFO And PRODUCT DETAILS */}
      <Row gutter={[24, 16]}>
        <Col md={14} xs={24} className='mb-16 mt-4'>
          <Card>
            <DriverInfo
              assign={dataSingleRTO?.object.driver === null ? false : true}
              driver={dataSingleRTO?.object.driver}
            />
          </Card>
          <Card>
            <ProductDetails
              title={t('order_drop_off')}
              value={String(dataSingleRTO?.object?.orders_count)}
            />
          </Card>
        </Col>
      </Row>
      {/* End of DRIVER INFO And PRODUCT DETAILS */}

      {/* BottomSheet */}
      <BottomSheet isVisible={true} showRecords={false} menuItems={menuItems} withoutButton />
      {/* End of BottomSheet */}
    </Layout>
  );
};

export default OrderRtoSingle;
