/* eslint-disable react/prop-types */
import { SVGProps } from 'react';

interface RightArrowSuccessIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
  backgroundColor?: string;
}

const RightArrowSuccessIcon: React.FC<RightArrowSuccessIconProps> = ({
  width = 19,
  height = 10,
  fillColor = '#2BB45D',
  backgroundColor = 'none',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill={backgroundColor}
    {...props}
  >
    <path
      fill={fillColor}
      d='m6.715 3.844.353-.354-.351-.351-.354.35.352.355Zm4.456 4.452-.353.353.352.353.354-.352-.353-.354ZM18.5 1.5A.5.5 0 0 0 18.001 1l-4.5-.011a.5.5 0 0 0-.002 1l4 .01-.01 4a.5.5 0 1 0 1 .002l.011-4.5ZM1.352 9.855 7.067 4.2l-.704-.71L.648 9.145l.704.71Zm5.01-5.657 4.456 4.451.706-.707L7.068 3.49l-.706.708Zm5.162 4.452 6.829-6.796-.706-.708-6.829 6.795.706.71Z'
    />
  </svg>
);
export default RightArrowSuccessIcon;
