import { SVGProps } from 'react';

const InformationIcon = ({
  width = 16,
  height = 16,
  color = '#fff',
  ...props
}: SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M8.163.816a7.184 7.184 0 1 1 0 14.368 7.184 7.184 0 0 1 0-14.368Zm0 .98a6.204 6.204 0 1 0 0 12.408 6.204 6.204 0 0 0 0-12.408Zm.523 4.898a.13.13 0 0 1 .13.13v4.637a.13.13 0 0 1-.13.13h-.719a.13.13 0 0 1-.13-.13V7.674h-.523a.13.13 0 0 1-.13-.131v-.718a.13.13 0 0 1 .13-.131h1.372Zm0-1.96a.13.13 0 0 1 .13.131v.719a.13.13 0 0 1-.13.13h-.719a.13.13 0 0 1-.13-.13v-.719a.13.13 0 0 1 .13-.13h.719Z'
    />
  </svg>
);

export default InformationIcon;
