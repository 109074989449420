import {
  DownloadIcon,
  Heading,
  PickupsIcon,
  PriceWithUnit,
  ResolveIcon,
  StatusTag,
} from '@/components';
import {
  CUSTOM_ID,
  CUSTOMER_NAME,
  ORDER_ACTIONS,
  ORDER_DATE,
  ORDER_ID,
  ORDER_STATUS,
  PICKUP_LOCATION,
  PRODUCTS,
  ROUTE,
  THEME_VARIABLE,
} from '@/config';
import { ColumnsType } from 'antd/es/table';
import { OrdersRow, OrderStatusTab, Product } from '@/types';
import { ActionsTable } from '@/components/Action';
import { Flex, message } from 'antd';
import { downloadFile } from '@/utils';
import { getStatusKeyAndColor } from '@/utils/getStatus';
import { useRequestPickup } from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { fetchSingleLabelPickupByUID } from '@/api';
import { useState } from 'react';
import { OrderDataType } from '@/hooks/useOrderData';

const renderHeading = (text: string | undefined) => <Heading title={text} fontSize='text-sm' />;

const renderPriceWithUnit = (
  value: number | undefined,
  t: { (key: string): string; (arg0: string): string | undefined },
) => (
  <Flex align='center' gap={2}>
    <Heading title={t('total')} fontSize='text-sm' />
    <PriceWithUnit
      color={THEME_VARIABLE.customTunaPrimary}
      value={value}
      subTitle={t('iqd')}
      fontSize='14px'
      fontSizeSub='text-sm'
      fontWeight='font-normal'
      fontWeightSub='font-medium'
      tagStyle='bg-customGrayShade3 w-fit px-2 rounded-full border-[1px] border-solid border-customGrayShade4'
    />
  </Flex>
);

export const getColumns = (
  activeTab: OrderStatusTab,
  t: (key: string) => string,
): ColumnsType<any> => {
  const { mutate: mutateRequestPickup } = useRequestPickup();
  const queryClient = useQueryClient();
  const navigation = useNavigate();
  const [idSelected, setIdSelected] = useState<string | null>();

  const disableOnHold = (status: string | undefined) => {
    return ['hold-resolved', 'on-hold'].includes(status || '');
  };

  // Common columns for all tabs
  const commonColumns = [
    {
      title: t('order_date'),
      dataIndex: ORDER_DATE,
      key: ORDER_DATE,
      sorter: (a: { orderDate: Date }, b: { orderDate: Date }) =>
        new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime(),
      render: (text: string) => renderHeading(text),
    },
    {
      title: t('order_id'),
      dataIndex: ORDER_ID,
      key: ORDER_ID,
      render: (text: string) => renderHeading(text),
    },
    {
      title: t('customer_detail'),
      dataIndex: CUSTOMER_NAME,
      key: CUSTOMER_NAME,
      render: (
        _: any,
        record: { customerName: string | undefined; customerAddress: string | undefined },
      ) => (
        <>
          <Heading title={record.customerName} fontSize='text-base' />
          <Heading
            title={record.customerAddress}
            fontSize='text-sm'
            color='text-customGrayShade5'
          />
        </>
      ),
    },
    {
      title: t('pickup_location'),
      dataIndex: PICKUP_LOCATION,
      key: PICKUP_LOCATION,
      render: (text: string) => renderHeading(text),
    },
  ];

  const commonProductColumn = [
    {
      title: t('product'),
      dataIndex: PRODUCTS,
      key: PRODUCTS,
      render: (products: Product[], record: { orderValue: number | undefined }) => (
        <Flex vertical>
          {products?.map((product: Product) => (
            <Flex vertical key={product.uid}>
              <Heading title={`${product.name} X ${product.quantity}`} fontSize='text-sm' />
            </Flex>
          ))}
          {renderPriceWithUnit(record?.orderValue, t)}
        </Flex>
      ),
    },
  ];

  const statusColumns = {
    [OrderStatusTab.ReadyToShip]: [
      ...commonColumns,
      ...commonProductColumn,
      {
        title: t('status'),
        dataIndex: ORDER_STATUS,
        key: ORDER_STATUS,
        render: (text: string) => (
          <StatusTag
            text={t(text)}
            color={getStatusKeyAndColor(text)?.tag || 'processing'}
            iconColor={getStatusKeyAndColor(text)?.color || ''}
          />
        ),
      },
      {
        title: t('filter_custom_id'),
        dataIndex: CUSTOM_ID,
        key: CUSTOM_ID,
        render: (text: string) => renderHeading(text),
      },
      {
        title: t('actions'),
        key: ORDER_ACTIONS,
        render: (data: OrderDataType) => (
          <ActionsTable
            labelBtn={t('ship_now')}
            Icon={<PickupsIcon />}
            uid={data.key}
            orderData={data}
            deleteLabel={t('delete')}
            disabled={data.pickupAddressId === '' ? true : false}
            activeTab={OrderStatusTab.ReadyToShip}
            handleClickBtn={() =>
              mutateRequestPickup(
                {
                  // date: dayjs().format('YYYY-MM-DD'),
                  pick_up_address_uid: data.pickupAddressId ?? '',
                  order_uids: [data.key ?? ''],
                },
                {
                  onSuccess: () => {
                    message.success(t('operation'));
                    queryClient.refetchQueries(['orders-readyToShip']);
                    navigation(ROUTE.PICKUPS);
                  },
                  onError: (error) => {
                    message.error(error?.response?.data?.message || t('operation_failed'));
                  },
                },
              )
            }
          />
        ),
        width: '10%',
        fixed: 'right' as 'right' | undefined,
      },
    ],

    [OrderStatusTab.InProgress]: [
      ...commonColumns,
      ...commonProductColumn,
      {
        title: t('status'),
        dataIndex: ORDER_STATUS,
        key: ORDER_STATUS,
        render: (text: string) => (
          <StatusTag
            text={t(text)}
            color={getStatusKeyAndColor(text)?.tag || 'processing'}
            iconColor={getStatusKeyAndColor(text)?.color || ''}
          />
        ),
      },
      {
        title: t('filter_custom_id'),
        dataIndex: CUSTOM_ID,
        key: CUSTOM_ID,
        render: (text: string) => renderHeading(text),
      },
      {
        title: t('actions'),
        key: ORDER_ACTIONS,
        render: (data: OrdersRow) => {
          return (
            <ActionsTable
              labelBtn={t('label')}
              Icon={<DownloadIcon />}
              uid={data.key}
              activeTab={OrderStatusTab.InProgress}
              disabled={idSelected === data.key}
              editDisabled={!['scheduled', 'out-for-collecting'].includes(data?.status || '')}
              cancelDisabled={
                !['scheduled', 'out-for-collecting', 'collecting'].includes(data?.status || '')
              }
              handleClickBtn={async () => {
                setIdSelected(data.key);
                await downloadFile(
                  data.key as string,
                  fetchSingleLabelPickupByUID,
                  `${data?.platform_code}.pdf`,
                );
                setIdSelected(null);
              }}
            />
          );
        },
        width: '10%',
        fixed: 'right' as 'right' | undefined,
      },
    ],

    [OrderStatusTab.OnHold]: [
      ...commonColumns,
      ...commonProductColumn,
      {
        title: t('status'),
        dataIndex: ORDER_STATUS,
        key: ORDER_STATUS,
        render: (text: string) => (
          <StatusTag
            text={t(text)}
            color={getStatusKeyAndColor(text)?.tag || 'warning'}
            iconColor={getStatusKeyAndColor(text)?.color || ''}
          />
        ),
      },
      {
        title: t('filter_custom_id'),
        dataIndex: CUSTOM_ID,
        key: CUSTOM_ID,
        render: (text: string) => renderHeading(text),
      },
      {
        title: t('actions'),
        key: ORDER_ACTIONS,
        render: (data: OrdersRow) =>
          data.merchant_action_required ? (
            <ActionsTable
              labelBtn={t('resolved')}
              Icon={<ResolveIcon />}
              uid={data?.key}
              activeTab={OrderStatusTab.OnHold}
              editDisabled={disableOnHold(data?.status)}
              cancelDisabled={disableOnHold(data?.status)}
              merchant_action_required
            />
          ) : (
            <Flex className='absolute right-4 bottom-4'>
              <ActionsTable
                withoutButton
                uid={data?.key}
                activeTab={OrderStatusTab.OnHold}
                editDisabled={disableOnHold(data?.status)}
                cancelDisabled={disableOnHold(data?.status)}
              />
            </Flex>
          ),
        width: '10%',
        fixed: 'right' as 'right' | undefined,
      },
    ],

    [OrderStatusTab.RTO]: [
      ...commonColumns,
      ...commonProductColumn,
      {
        title: t('status'),
        dataIndex: ORDER_STATUS,
        key: ORDER_STATUS,
        render: (text: string) => (
          <StatusTag
            text={t(text)}
            color={getStatusKeyAndColor(text)?.tag || 'processing'}
            iconColor={getStatusKeyAndColor(text)?.color || ''}
          />
        ),
      },
      {
        title: t('filter_custom_id'),
        dataIndex: CUSTOM_ID,
        key: CUSTOM_ID,
        render: (text: string) => renderHeading(text),
      },
      {
        title: t('actions'),
        key: ORDER_ACTIONS,
        render: (data: OrdersRow) => {
          return (
            <ActionsTable
              uid={data.key}
              withoutAction
              routeLink={`${ROUTE.ORDER_DETAILS}/${data?.key}`}
              activeTab={activeTab}
              buttonType='default'
            />
          );
        },
        width: '10%',
        fixed: 'right' as 'right' | undefined,
      },
    ],
    [OrderStatusTab.Finished]: [
      ...commonColumns,
      ...commonProductColumn,
      {
        title: t('status'),
        dataIndex: ORDER_STATUS,
        key: ORDER_STATUS,
        render: (text: string) => (
          <StatusTag
            text={t(text)}
            color={getStatusKeyAndColor(text)?.tag || 'success'}
            iconColor={getStatusKeyAndColor(text)?.color || ''}
          />
        ),
      },
      {
        title: t('filter_custom_id'),
        dataIndex: CUSTOM_ID,
        key: CUSTOM_ID,
        render: (text: string) => renderHeading(text),
      },
      {
        title: t('actions'),
        key: ORDER_ACTIONS,
        render: (data: OrdersRow) => (
          <ActionsTable
            uid={data.key}
            activeTab={activeTab}
            buttonType='default'
            withoutAction
            routeLink={`${ROUTE.ORDER_DETAILS}/${data?.key}`}
          />
        ),
        width: '5%',
        fixed: 'right' as 'right' | undefined,
        className: 'text-right',
      },
    ],
  };

  return statusColumns[activeTab] || [];
};

export const getListColumns = (t: (key: string) => string): ColumnsType<any> => [
  {
    title: t('order_date'),
    dataIndex: ORDER_DATE,
    key: ORDER_DATE,
    sorter: (a: { orderDate: Date }, b: { orderDate: Date }) =>
      new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime(),
    render: (text: string) => renderHeading(text),
  },
  {
    title: t('order_id'),
    dataIndex: ORDER_ID,
    key: ORDER_ID,
    render: (text: string) => renderHeading(text),
  },
  {
    title: t('customer_detail'),
    dataIndex: CUSTOMER_NAME,
    key: CUSTOMER_NAME,
    render: (
      _: any,
      record: { customerName: string | undefined; customerAddress: string | undefined },
    ) => (
      <>
        <Heading title={record.customerName} fontSize='text-base' />
        <Heading title={record.customerAddress} fontSize='text-sm' color='text-customGrayShade5' />
      </>
    ),
  },
  {
    title: t('pickup_location'),
    dataIndex: PICKUP_LOCATION,
    key: PICKUP_LOCATION,
    render: (text: string) => renderHeading(text),
  },
  {
    title: t('product'),
    dataIndex: PRODUCTS,
    key: PRODUCTS,
    render: (products: Product[], record: { orderValue: number | undefined }) => (
      <Flex vertical>
        {products?.map((product: Product) => (
          <Flex vertical key={product.uid}>
            <Heading title={`${product.name} X ${product.quantity}`} fontSize='text-sm' />
          </Flex>
        ))}
        {renderPriceWithUnit(record?.orderValue, t)}
      </Flex>
    ),
  },
  {
    title: t('filter_custom_id'),
    dataIndex: CUSTOM_ID,
    key: CUSTOM_ID,
    render: (text: string) => renderHeading(text),
  },
  {
    title: t('status'),
    dataIndex: ORDER_STATUS,
    key: ORDER_STATUS,
    render: (text: string) => (
      <StatusTag
        text={t(text)}
        color={getStatusKeyAndColor(text)?.tag || 'processing'}
        iconColor={getStatusKeyAndColor(text)?.color || ''}
      />
    ),
  },
  {
    title: t('actions'),
    key: ORDER_ACTIONS,
    render: (data: OrdersRow) => {
      return (
        <ActionsTable
          uid={data.key}
          buttonType='default'
          withoutAction
          routeLink={`${ROUTE.ORDER_DETAILS}/${data?.key}`}
        />
      );
    },
    width: '2%',
    fixed: 'right' as 'right' | undefined,
  },
];
