import React from 'react';
import { Flex, Skeleton, Typography } from 'antd';
import { InfoIcon, InformationIcon } from '@/components/Icons';

const { Text } = Typography;

interface OrderShipmentValueCardItemProps {
  value: number;
  label: string;
  currency: string;
  isLoading?: boolean;
  isThinFont?: boolean;
  withIcon?: boolean;
}

const OrderShipmentValueCardItem: React.FC<OrderShipmentValueCardItemProps> = ({
  value,
  label,
  currency,
  isLoading,
  isThinFont,
  withIcon,
}) => {
  return (
    <Flex className='flex justify-between items-center pb-2  mt-1'>
      <Flex className='flex justify-start items-center gap-2'>
        {withIcon ? <InformationIcon color='#1677FF' height={'20px'} width={'20px'} /> : null}
        <Text
          className={`${isThinFont ? 'font-thin text-gray-400' : 'font-normal'} text-[15px] leading-[18px] text-customTunaPrimary`}
        >
          {label}
        </Text>
      </Flex>
      <Flex className='gap-x-1'>
        {isLoading ? (
          <Skeleton.Input active size='small' style={{ width: 50 }} />
        ) : (
          <>
            <Text
              className={`${isThinFont ? 'font-thin text-gray-400 text-[13px]' : 'font-bold text-customTunaPrimary text-[15px]'} leading-[18px] `}
            >
              {value.toLocaleString()}
            </Text>
            <Text
              className={`${isThinFont ? 'font-thin text-gray-400' : 'font-normal text-customTunaPrimary'} uppercase text-[15px] leading-[18px] text-customTunaPrimary`}
            >
              {` ${currency}`}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default OrderShipmentValueCardItem;
