import { useSentryQuery } from '../utils/sentryQueryWrapper';
import { RegionResponse } from '@/types/region';
import { fetchAreaByProvinceCode, fetchListProvinces } from '@/api/regionService';

// Fetch List Provinces
export const useFetchListProvinces = () => {
  return useSentryQuery<RegionResponse, Error>(['region'], () => fetchListProvinces());
};

// Fetch List Province By Code
export const useFetchAreaByProvinceCode = (ProvinceCode: string) => {
  return useSentryQuery<RegionResponse, Error>(
    ['region', ProvinceCode],
    () => fetchAreaByProvinceCode(ProvinceCode),
    {
      enabled: !!ProvinceCode,
    },
  );
};
