import { ContactsResponse } from '@/types';
import { create } from 'zustand';

interface ContactsState {
  contacts: ContactsResponse | null;
  regionUid: string | null; // Add regionUid state
  newRegionUid: string | null;
  setContacts: (contacts: ContactsResponse) => void;
  setRegionUid: (regionUid: string) => void; // Add a setter for regionUid
  setNewRegionUid: (regionUid: string) => void; // Add a setter for regionUid
}

export const useContactsStore = create<ContactsState>((set) => ({
  contacts: null,
  regionUid: null, // Initialize regionUid as null
  newRegionUid: null,
  setContacts: (contacts) => set({ contacts }),
  setRegionUid: (regionUid) => set({ regionUid }), // Setter implementation
  setNewRegionUid: (value) => set({ newRegionUid: value }),
}));
