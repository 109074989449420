import {
  LineChart as LineItem,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';

import { THEME_VARIABLE } from '@/config';
import { Space, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const data = [
  { name: 'Page A', uv: 4000 },
  { name: 'Page B', uv: 3000 },
  { name: 'Page C', uv: 2000 },
  { name: 'Page D', uv: 2780 },
  { name: 'Page E', uv: 1890 },
];

const { Text } = Typography;

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className='relative mr-6'>
        <Space className='bg-customTunaPrimary text-white text-xs rounded-full p-2 shadow-lg border w-20 h-5 border-gray-200'>
          <Text className='absolute top-[5px] text-white'>{`${payload[0].value} ${t('iqd')}`}</Text>
        </Space>
        <svg
          className='absolute top-full left-1/2 transform -translate-x-1/2'
          width='12'
          height='6'
          viewBox='0 0 12 6'
          xmlns='http://www.w3.org/2000/svg'
        >
          <polygon points='0,0 6,6 12,0' fill={THEME_VARIABLE.customTunaPrimary} />
        </svg>
      </div>
    );
  }

  return null;
};

const LineChartItem = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouse = (e: { chartX?: number; chartY?: number }) => {
    if (e.chartX !== undefined && e.chartY !== undefined) {
      setPosition({ x: e.chartX, y: e.chartY });
    }
  };

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineItem
        layout='horizontal'
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
        onMouseMove={handleMouse}
      >
        <XAxis hide />
        <YAxis hide />
        <Tooltip
          content={<CustomTooltip />}
          cursor={false}
          wrapperStyle={{ top: 0, left: 0 }}
          position={{ x: position.x - 40, y: position.y - 50 }}
        />
        <Line type='linear' dataKey='uv' stroke={THEME_VARIABLE.customTunaPrimary} dot={false} />
      </LineItem>
    </ResponsiveContainer>
  );
};

export default LineChartItem;
