import { Col, Layout, Row, Typography } from 'antd';
import PriceWithUnit from '../Price/PriceWithUnit';
import RightArrowSuccessIcon from '../Icons/RightArrowSuccessIcon';
import RightArrowFailIcon from '../Icons/RightArrowFailIcon';
import Heading from '../Heading/Heading ';
import { THEME_VARIABLE } from '@/config';

export type ProgressStatuses = 'normal' | 'exception' | 'active' | 'success';

interface StatisticalOverviewCardProps {
  title: string;
  value: number;
  unit: string;
  percent: number;
  status: ProgressStatuses;
  hasMargin?: boolean;
}

const { Text } = Typography;

const StatisticalOverviewCard = ({
  title,
  percent = 0,
  status,
  unit,
  value,
  hasMargin = true,
}: StatisticalOverviewCardProps) => {
  return (
    <Layout
      className={`bg-white px-6 py-4 rounded-[10px] border-customGrayShade3 border-[1px] shadow-card-dark ${hasMargin ? 'mb-4' : ''}`}
    >
      <Row>
        <Col md={12}>
          <Text className='text-sm font-normal text-customGrayShade5'>{title}</Text>
          <PriceWithUnit
            color={THEME_VARIABLE.customTunaPrimary}
            value={value}
            subTitle={unit}
            fontSize='24px'
            fontSizeSub='text-sm'
          />
        </Col>
        <Col md={12} className='flex justify-end items-center'>
          <Heading
            title={`+${percent}%`}
            trailingIcon={status === 'success' ? <RightArrowSuccessIcon /> : <RightArrowFailIcon />}
            color={status === 'success' ? 'text-customGreen' : 'text-customRed'}
            fontWeight='font-bold'
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default StatisticalOverviewCard;
