import CodDropOff from './CodDropOff';
import PickupReturn from './PickupReturn';

const PickupDrop = () => {
  return (
    <>
      <PickupReturn />
      <CodDropOff />
    </>
  );
};

export default PickupDrop;
