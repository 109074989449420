import { SVGProps } from 'react';

interface OrdersIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const OrdersIcon = ({
  width = 20,
  height = 20,
  fillColor = '#38383E',
  ...props
}: OrdersIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M11.971 1.257h3.669a3.126 3.126 0 0 1 3.125 3.126v11.25a3.124 3.124 0 0 1-3.125 3.125H4.375a3.125 3.125 0 0 1-3.125-3.126V4.382a3.126 3.126 0 0 1 3.125-3.125h3.668a.626.626 0 0 1 .193 0h3.542a.627.627 0 0 1 .193 0Zm-4.456 1.25h-3.14A1.876 1.876 0 0 0 2.5 4.384v11.25a1.875 1.875 0 0 0 1.875 1.874H15.64a1.874 1.874 0 0 0 1.875-1.875V4.382a1.875 1.875 0 0 0-1.875-1.874H12.5v3.5a1.25 1.25 0 0 1-1.645 1.186L10 6.909l-.838.282a1.25 1.25 0 0 1-1.648-1.185V2.508Zm3.735 0H8.765v3.499L9.8 5.658a.624.624 0 0 1 .397 0l1.052.35v-3.5Zm-3.438 9.368a1.562 1.562 0 0 1 1.563 1.563v1.25a1.562 1.562 0 0 1-1.563 1.562h-2.5a1.562 1.562 0 0 1-1.562-1.562v-1.25a1.562 1.562 0 0 1 1.562-1.563h2.5Zm0 1.25h-2.5a.313.313 0 0 0-.312.313v1.25a.312.312 0 0 0 .312.312h2.5a.313.313 0 0 0 .313-.312v-1.25a.313.313 0 0 0-.313-.313Z'
      clipRule='evenodd'
    />
  </svg>
);

export default OrdersIcon;
