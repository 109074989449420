import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ResolveIcon = ({ width = 18, height = 17, color = '#38383E', ...props }: CloseIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      d='m14.7.448.917.908c.032.03.05.074.05.118v2.357a.62.62 0 0 1-.62.619h-2.386a.167.167 0 0 1-.118-.048l-.913-.905a.167.167 0 0 1 .117-.285h1.817A7.096 7.096 0 0 0 9 1.561C5.088 1.56 1.917 4.7 1.917 8.577c0 3.875 3.17 7.017 7.083 7.017 3.724 0 6.777-2.847 7.061-6.464.008-.093.014-.224.018-.391a.167.167 0 0 1 .167-.162h.917c.092 0 .167.074.167.166v.004c-.003.15-.008.268-.013.353-.273 4.315-3.892 7.732-8.317 7.732-4.603 0-8.333-3.696-8.333-8.255C.667 4.018 4.397.322 9 .322a8.348 8.348 0 0 1 5.417 1.982V.566A.167.167 0 0 1 14.7.448Z'
    />
  </svg>
);
export default ResolveIcon;
