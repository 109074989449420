import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTableAction() {
  const { t } = useTranslation();

  const customTitle = useCallback(
    (total: number, range: [number, number]): JSX.Element => (
      <span className='text-customTunaPrimary font-bold'>
        <span className='font-medium'>{t('show')} </span>
        {range[1].toLocaleString()}/{total.toLocaleString()}{' '}
        <span className='font-medium'>{t('records')}</span>
      </span>
    ),
    [t],
  );

  return {
    customTitle,
  };
}
