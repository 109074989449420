import { fetchBulkLabelPickupByUIDs } from '@/api';
import { ROUTE } from '@/config';
import { useRequestPickup } from '@/hooksApi';
import { OrderColumn, OrderStatusTab } from '@/types';
import { downloadFile } from '@/utils';
import { message } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useSubmitOrder = (activeTab: string, selectedRow: OrderColumn[]) => {
  const { mutate: mutateRequestPickup, isLoading: LoadingRequestPickup } = useRequestPickup();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleReadyToShip = () => {
    mutateRequestPickup(
      {
        // date: dayjs().format('YYYY-MM-DD'),
        order_uids: selectedRow?.map((item: OrderColumn) => item.key),
      },
      {
        onSuccess: () => {
          message.success(t('operation'));
          navigation(ROUTE.PICKUPS);
        },
        onError: (error) => {
          message.error(error?.response?.data?.message || t('operation_failed'));
        },
      },
    );
  };

  const handleInProgress = async () => {
    const queryString = selectedRow
      .map((row: OrderColumn) => `order_uid[]=${encodeURIComponent(row?.key)}`)
      .join('&');

    // download labels
    await downloadFile(queryString, fetchBulkLabelPickupByUIDs, 'pickup-labels.pdf');
  };

  const onSubmit = useCallback(() => {
    if (activeTab === OrderStatusTab.ReadyToShip) {
      handleReadyToShip();
    } else {
      handleInProgress();
    }
  }, [activeTab, handleReadyToShip, handleInProgress]);

  return { onSubmit, LoadingRequestPickup };
};

export default useSubmitOrder;
