import { SVGProps } from 'react';

interface DeliveredIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
}

const DeliveredIcon = ({
  width = 40,
  height = 40,
  color = '#38383E',
  backgroundColor = '#F5F7FA',
  ...props
}: DeliveredIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 40 40'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <rect width={40} height={40} fill={backgroundColor} rx={20} />
    <path
      fill={color}
      d='M29.232 14.856L18.736 25.257a.684.684 0 0 1-1.024-.071.668.668 0 0 1-.072-.062l-6.872-6.81a.182.182 0 0 1 .128-.31h1.405c.048 0 .094.018.128.052l5.826 5.773 9.316-9.23a.182.182 0 0 1 .128-.054h1.405a.182.182 0 0 1 .128.311Z'
    />
  </svg>
);

export default DeliveredIcon;
