import {
  BottomSheet,
  Card,
  CloseOutline,
  DownloadIcon,
  DriverInfo,
  ExportIcon,
  Heading,
  MapComponent,
  OrderStatus,
  PaymentStatus,
  ProductDetails,
} from '@/components';
import { OrderItemStatus } from '@/components/PickupsCard/OrderStatus';
import { ROUTE } from '@/config';
import { Col, Flex, Layout, MenuProps, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const RtoDetails = () => {
  const { t } = useTranslation();

  const items: OrderItemStatus[] = [
    {
      title: t('scheduled'),
      description: '12:23 PM',
    },
    {
      title: t('collect'),
      description: '12:23 PM',
    },
    {
      title: t('collected'),
    },
  ];

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: <Heading title={t('cancel_pickup')} leadingIcon={<CloseOutline />} rotation={false} />,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: <Heading title={t('export_to_csv')} leadingIcon={<ExportIcon />} rotation={false} />,
    },
  ];

  return (
    <Layout className='relative'>
      {/* Pickup Title */}
      <Row>
        <Col md={24}>
          <Heading
            routeLink={ROUTE.PICKUPS}
            title={t('pickup_details')}
            fontSize='text-2xl'
            fontWeight='font-bold'
            extraClassName='uppercase'
          />
        </Col>
      </Row>
      {/* End of Pickup Title */}

      <Row gutter={[24, 16]} className='mt-6 '>
        {/* Map */}
        <Col md={14}>
          <Flex vertical className={`max-h-[28rem] rounded-[10px] shadow-card-dark`}>
            <MapComponent />
          </Flex>
        </Col>
        {/*End of Map */}

        {/* ORDERS STATUS And Payment */}
        <Col md={10}>
          <Flex vertical justify='space-between h-full flex flex-col'>
            <Card extraClassName='px-0 py-6 mb-5'>
              <OrderStatus items={items} statusMessage={t('in-transit')} />
            </Card>
            <Card>
              <PaymentStatus
                headerTitle={t('payment')}
                titleFirst={t('order_value')}
                total={5000}
                valueFirst={1500}
                valueSecond={5000}
              />
            </Card>
          </Flex>
        </Col>
        {/* End of ORDERS STATUS And Payment */}
      </Row>

      {/* DRIVER INFO And PRODUCT DETAILS */}
      <Row gutter={[24, 16]}>
        <Col md={16} xs={24}>
          <Card>
            <DriverInfo />
          </Card>
          <Card>
            <ProductDetails />
          </Card>
        </Col>
      </Row>
      {/* End of DRIVER INFO And PRODUCT DETAILS */}

      {/* BottomSheet */}
      <BottomSheet
        withoutButton={true}
        isVisible={true}
        labelBtn={t('labels')}
        Icon={<DownloadIcon />}
        showRecords={false}
        menuItems={menuItems}
      />
      {/* End of BottomSheet */}
    </Layout>
  );
};

export default RtoDetails;
