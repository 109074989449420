import { Col, Flex, Row, Typography } from 'antd';
import React from 'react';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { LOCAL_EN } from '@/config';

interface ResolveFailedDeliveryProps {
  deliveryDescription?: string;
}

const { Text } = Typography;

const ResolveFailedDelivery: React.FC<ResolveFailedDeliveryProps> = ({ deliveryDescription }) => {
  const { i18n, t } = useTranslation();
  return (
    <Flex vertical className='mt-[34px]'>
      <Heading
        title={t('failed_delivery_attempt')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Row gutter={[24, 16]} className=''>
        <Col lg={10} md={24} xs={24}>
          <Flex
            className={`py-2 flex mt-4 items-center bg-customGrayShade1 border border-dashed border-customGrayShade4 rounded-[5px]   ${i18n.language === LOCAL_EN ? 'pl-2' : 'pr-2'}`}
          >
            <Text className=' whitespace-nowrap capitalize font-normal text-xs leading-4   text-customTunaPrimary'>
              {deliveryDescription}
            </Text>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
};

export default ResolveFailedDelivery;
