import { SVGProps } from 'react';

interface CopyIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ExIcon = ({ width = 18, height = 13, color = '#38383E', ...props }: CopyIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      d='m8.39 6.193-1.12 1.12a.625.625 0 0 1-.884-.883l2.187-2.188a.625.625 0 0 1 .884 0l2.188 2.188a.626.626 0 0 1-.884.884L9.64 6.194v5.99a.625.625 0 0 1-1.25 0v-5.99ZM4.953 8.434a.625.625 0 0 1 0 1.25H2.38c-.79 0-1.43-.64-1.43-1.43V1.806c0-.79.64-1.431 1.43-1.431h13.255c.79 0 1.43.641 1.43 1.43v6.449c0 .79-.64 1.43-1.43 1.43h-2.557a.625.625 0 0 1 0-1.25h2.557c.1 0 .18-.08.18-.18V1.806c0-.1-.08-.181-.18-.181H2.38c-.1 0-.18.081-.18.18v6.449c0 .1.08.18.18.18h2.573Z'
      clipRule='evenodd'
    />
  </svg>
);
export default ExIcon;
