import {
  BottomOrderCard,
  DeliveryInstructionCard,
  Heading,
  OrderIdCard,
  PackageAndPaymentCard,
  PickupDropCard,
  //  ProductDetailsCard,
  CustomerDetailsCard as cd,
} from '@/components';
import { ProductDetailsCard, CustomerDetailsCard } from '@/components/OrderForm';
import { ROUTE } from '@/config';
// import { ROUTE } from '@/config';
import { useProductDetails } from '@/hooks';
import { useCreateContact, useRequestPickup } from '@/hooksApi';
import { useCreateOrder, usePreviewOrderPrice } from '@/hooksApi/useOrder';

import { useContactsStore } from '@/stores/contactStore';
import { Preview, PickUpParams } from '@/types';
import { attachCountryCodeToPhone } from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Form, message, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

const orderPriceDefaultValue = {
  orders_value: 0,
  orders_value_with_shipment_fee: 0,
  orders_shipment_fee: 0,
  orders_value_with_platform_shipment_fee: 0,
  orders_custom_shipment_fee: 0,
};
const AddNewOrder = () => {
  const [customShipmentFee, setCustomShipmentFee] = useState<number | undefined>(undefined);
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { mutate: mutateAddNewOrder, isLoading: addNewOrderLoading } = useCreateOrder();
  const { mutate: mutateAddNewContact } = useCreateContact();
  const { mutate: previewOrderPrice, isLoading: orderPriceLoading } = usePreviewOrderPrice();
  //  Add resetForm state
  const [resetForm, setResetForm] = useState(false);
  // Check the form is ready to submit
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  /*HOOK TO CREATE NEW PRODUCT AND ARRAY OF ALL PRODUCTS  */
  const {
    products,
    newProduct,
    handleAddProduct,
    handleDeleteProduct,
    handleChangeNewProduct,
    handleChangeProduct,
    resetProducts,
  } = useProductDetails();
  const queryClient = useQueryClient();
  const [orderPrice, setOrderPrice] = useState<Preview>(orderPriceDefaultValue);
  const transformValuesToAddOrder = (values: Store) => {
    const primaryPhoneNumber = values?.phoneNumberPrimary
      ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
      : undefined;

    const secondaryPhoneNumber = values?.secondaryPhoneNumber
      ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
      : undefined;

    return {
      fee_customer_payable: customShipmentFee,
      is_fragile: values?.isFragile || false,
      ready_to_pick_up: false,
      shipment_fee_type: values?.CustomerOrMerchant || 'BY_MERCHANT',
      payment_type: values?.CodOrPrepaid || 'COLLECT_ON_DELIVERY',
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || '',
      custom_id: values?.orderId || '', // Custom ID
      products: products.map((product) => ({
        title: product?.title || '',
        price: Number(product?.price) || 0, // Ensure price is a number
        quantity: Number(product?.quantity) || 1, // Ensure quantity is a number
      })),
      pick_up_address_uid: values?.pick_up_address_uid || '',
      size: values?.packageSize || 'S', // Default size if not selected
      contact: {
        full_name: values?.name || '',
        address_text: values?.address || '',
        phone: primaryPhoneNumber ?? '',
        // secondary_phone: secondaryPhoneNumber ?? '',
        additional_phones: secondaryPhoneNumber ? [secondaryPhoneNumber] : [],

        region_uid: values?.region_uid || '',
      },
    };
  };
  const transformValuesPricePreview = (values: Store) => {
    const primaryPhoneNumber = values?.phoneNumberPrimary
      ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
      : undefined;

    const secondaryPhoneNumber = values?.secondaryPhoneNumber
      ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
      : undefined;

    return {
      fee_customer_payable: customShipmentFee,
      is_fragile: values?.isFragile || false,
      ready_to_pick_up: false,
      shipment_fee_type: values?.CustomerOrMerchant || 'BY_MERCHANT',
      payment_type: values?.CodOrPrepaid || 'COLLECT_ON_DELIVERY',
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || '',
      custom_id: values?.orderId || '', // Custom ID
      products: products.map((product) => ({
        title: product?.title || '',
        price: Number(product?.price) || 0, // Ensure price is a number
        quantity: Number(product?.quantity) || 1, // Ensure quantity is a number
      })),
      pick_up_address_uid: values?.pick_up_address_uid || '',
      size: values?.packageSize || 'S', // Default size if not selected
      contact: {
        full_name: values?.name || '',
        address_text: values?.address || '',
        phone: primaryPhoneNumber,
        additional_phones: secondaryPhoneNumber ? [secondaryPhoneNumber] : [],

        // secondary_phone: secondaryPhoneNumber ?? '',
        region_uid: values?.region_uid || '',
      },
    };
  };
  const { mutate: requestPickup } = useRequestPickup();
  const handleSubmit = (isShipment: boolean) => {
    form.submit();
    form
      .validateFields()
      .then((values) => onFinish(values, isShipment)) // true means special submit
      // .catch((info) => console.log('Validation failed:', info));
      .catch((errorInfo) => {
        message.error(t('please_fill_the_all'));
        console.error('Validation Failed:', errorInfo);
      });
  };
  const onFinish = (values: Store, isShipment: boolean) => {
    const orderPayload = transformValuesToAddOrder(values);

    const primaryPhoneNumber = values?.phoneNumberPrimary
      ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
      : undefined;

    const secondaryPhoneNumber = values?.secondaryPhoneNumber
      ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
      : undefined;

    if (values.saveToAddress) {
      const contactPayload = {
        full_name: values.name || '',
        phone: primaryPhoneNumber ?? '',
        additional_phones: secondaryPhoneNumber ? [secondaryPhoneNumber] : [],
        address_text: values.address || '',
        type: 'some type', // Assuming a type for the contact, you may modify this as needed
        region_uid: values?.region_uid || '',
      };

      // Call the mutate function to create the new contact
      try {
        mutateAddNewContact(contactPayload, {
          onSuccess: () => {
            queryClient.refetchQueries(['contacts']);
            message?.success(t('contact_added'));
          },
          onError: (error) => {
            message.error(error?.response?.data?.message || t('failed_to_add_contact'));
          },
        });
        // After the contact is created successfully, create the order
        mutateAddNewOrder(orderPayload, {
          onSuccess: (response) => {
            const { uid } = response.object.order; // Get uid from the response
            if (isShipment) {
              if (uid) {
                const params: PickUpParams = {
                  order_uids: [uid], // Ensure to pass orderUid as an array
                };

                // Call the requestPickup mutation with onSuccess and onError handlers
                requestPickup(params, {
                  onSuccess: () => {
                    message.success('Pickup request was successful!'); // Show success message
                    // You can also perform additional actions here, like refreshing data or redirecting
                  },
                  onError: (error) => {
                    message.error(error?.response?.data?.message || t('failed_pickup_request'));
                    // You can also log the error or perform additional error handling here
                  },
                });
              }
            }
            message?.success(t('order_added_successfully'));
            form.resetFields(); // Reset the form fields after a successful submission
            setResetForm(true); // Set resetForm to true
            resetProducts(); // Reset the products list after successful submission
            if (isShipment) {
              navigate(ROUTE.PICKUPS);
            } else {
              navigate(ROUTE.ORDERS);
            }
          },
          onError: (error) => {
            message.error(error?.response?.data?.message || t('failed_to_add_order'));
          },
        });
      } catch (error) {
        const typedError = error as { response?: { data?: { message?: string } } };
        message.error(typedError.response?.data?.message || t('failed_to_create_contact'));
      }
    } else {
      // If saveToAddress is false, directly create the order
      mutateAddNewOrder(orderPayload, {
        onSuccess: (response) => {
          const { uid } = response.object.order; // Get uid from the response
          if (isShipment) {
            if (uid) {
              const params: PickUpParams = {
                order_uids: [uid], // Ensure to pass orderUid as an array
              };

              // Call the requestPickup mutation with onSuccess and onError handlers
              requestPickup(params, {
                onSuccess: () => {
                  message.success('Pickup request was successful!'); // Show success message
                  // You can also perform additional actions here, like refreshing data or redirecting
                },
                onError: (error) => {
                  message.error(error?.response?.data?.message || t('failed_pickup_request'));
                  // You can also log the error or perform additional error handling here
                },
              });
            }
          }

          message?.success(t('order_added_successfully'));
          form.resetFields(); // Reset the form fields after a successful submission
          setResetForm(true); // Set resetForm to true
          resetProducts(); // Reset the products list after successful submission
          if (isShipment) {
            navigate(ROUTE.PICKUPS);
          } else {
            navigate(ROUTE.ORDERS);
          }
        },
        onError: (error) => {
          message.error(error?.response?.data?.message || t('failed_to_add_order'));
        },
      });
    }
  };

  const getCheckContentDetails = (orderPayload: Store) => {
    return !!(
      orderPayload?.contact?.full_name &&
      orderPayload?.contact?.phone &&
      orderPayload?.contact?.address_text &&
      orderPayload?.contact?.region_uid &&
      orderPayload?.pick_up_address_uid
    );
  };
  const updateOrderPrice = (orderPayload: Store) => {
    if (products.length > 0 && getCheckContentDetails(orderPayload)) {
      previewOrderPrice(orderPayload, {
        onSuccess: (response) => {
          const {
            orders_value,
            orders_value_with_shipment_fee,
            orders_shipment_fee,
            orders_value_with_platform_shipment_fee,
            orders_custom_shipment_fee,
          } = response.object;
          setOrderPrice({
            orders_value,
            orders_value_with_shipment_fee,
            orders_shipment_fee,
            orders_value_with_platform_shipment_fee,
            orders_custom_shipment_fee,
          });
        },
        onError: (error) => {
          message.error(error?.response?.data?.message || t('failed_to_preview_price'));
        },
      });
      setIsReadyToSubmit(true);
    } else {
      setIsReadyToSubmit(false);
    }
  };

  const onValuesChange = (_: Store, allValues: Store) => {
    const orderPayload = transformValuesPricePreview(allValues);

    if (products.length === 0) {
      // Set order price to zero when there are no products
      setOrderPrice(orderPriceDefaultValue);
    }

    // Always call previewOrderPrice, even if the product array is empty
    updateOrderPrice(orderPayload);
  };
  const onPackageSizeChange = (size: string) => {
    const formValues = form.getFieldsValue(); // Get current form values
    const orderPayload = transformValuesPricePreview({ ...formValues, packageSize: size });

    updateOrderPrice(orderPayload); // Call the function to update the price
  };

  useEffect(() => {
    const formValues = form.getFieldsValue(); // Get the current form values
    const orderPayload = transformValuesPricePreview(formValues); // Transform the form values

    // If products array is empty, set order price to 0

    if (products.length === 0) {
      // Set order price to zero when there are no products
      setOrderPrice(orderPriceDefaultValue);
    }

    updateOrderPrice(orderPayload);
  }, [
    products,
    customShipmentFee,
    form.getFieldValue('name'),
    form.getFieldValue('phoneNumberPrimary'),
    form.getFieldValue('address'),
    form.getFieldValue('region_uid'),
  ]); // Trigger the effect when products and form fields changed

  const handleCreateProduct = () => {
    const formValues = form.getFieldsValue(); // Get the current form values
    const orderPayload = transformValuesPricePreview(formValues); // Transform the form values
    console.log({ formValues, orderPayload });

    if (getCheckContentDetails(orderPayload)) {
      handleAddProduct();
    } else {
      message.error(t('please_fill_customer_details'));
    }
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode, region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };
  return (
    <Flex vertical>
      <Form
        form={form}
        initialValues={{
          CustomerOrMerchant: 'BY_CUSTOMER',
          CodOrPrepaid: 'COLLECT_ON_DELIVERY',
        }}
        name='add-new-order'
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange} // Track form value changes
      >
        <Heading
          title={t('add_new_order')}
          routeLink='/orders'
          fontSize='text-2xl'
          fontWeight='font-black'
          extraClassName='leading-[27.84px]' // Adjust line-height if needed
        />

        <Row gutter={[24, 16]} className='mt-[40px] mb-3'>
          <Col lg={14} md={24} xs={24}>
            <CustomerDetailsCard
              form={form}
              resetForm={resetForm} // Step 2: Pass resetForm as a prop
              title={t('customer_details')}
              namePlaceholder={t('name')}
              primaryPhonePlaceholder={t('primary_phone')}
              secondaryPhonePlaceholder={t('secondary_phone')}
              provincePlaceholder={t('province')}
              areaPlaceholder={t('area')}
              addressPlaceholder={t('address')}
              saveToAddress={t('save_to_address')}
              setSelectedProvinceCode={handleSetSelectedProvinceCode}
              selectedProvinceCode={selectedProvinceCode}
            />

            <DeliveryInstructionCard
              title={t('delivery_instruction')}
              description={t('add-delivery-instruction')}
              placeholder={t('do_not_ring_door')}
            />

            <OrderIdCard
              title={t('order_id')}
              description={t('if_you_do_not')}
              placeholder={t('order_id_custom')}
            />
            <ProductDetailsCard
              title={t('product_details')}
              placeholders={{
                title: t('product_name'),
                quantity: t('qty'),
                price: t('price'),
              }}
              products={products}
              newProduct={newProduct}
              handleAddProduct={handleCreateProduct}
              handleDeleteProduct={handleDeleteProduct}
              handleChangeNewProduct={handleChangeNewProduct}
              handleChangeProduct={handleChangeProduct}
            />
            <PickupDropCard
              form={form} // Pass the form instance here
              previewOrderPrice={updateOrderPrice} // Pass the price preview function
              transformValuesToPayload={transformValuesToAddOrder} // Pass the transformation function
              title={t('pickup_dropoff')}
              option1Label={t('boxy_collects')}
              option2Label={t('you_drop_off')}
              collectDescription={t('boxy_agent_will_collect')}
              dropoffDescription={t('you_drop_off_your_packages')}
            />
          </Col>
          <Col lg={10} md={24} xs={24}>
            <PackageAndPaymentCard
              form={form}
              onPackageSizeChange={onPackageSizeChange}
              orderPrice={orderPrice} // Pass orderPrice as a prop
              orderPriceLoading={orderPriceLoading}
              onCustomShipmentFeeChange={setCustomShipmentFee}
              customShipmentFee={customShipmentFee}
            />
          </Col>
        </Row>
        <Row className='sticky bottom-4'>
          <Col lg={24} md={24} xs={24}>
            <BottomOrderCard
              isLoading={addNewOrderLoading}
              isReadyToSubmit={isReadyToSubmit}
              createOrderTitle={t('create_order')}
              readyToShipTitle={t('create_ready_ship')}
              handleSubmit={handleSubmit}
            />
          </Col>
        </Row>
      </Form>
    </Flex>
  );
};

export default AddNewOrder;
