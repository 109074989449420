import { Row, Col } from 'antd';
import {
  CardAvailableCod,
  CollectedIcon,
  DeliveredIcon,
  NewOrders,
  OnHoldIcon,
  OrderPerformanceByChart,
  OrderPerformanceByCountry,
  OrderSummaryCards,
  PickupsIcon,
  ReturnIcon,
  StatisticalOverviewCard,
  Heading,
} from '@/components';
import { useSelectStore, useUserStore } from '@/stores';
import { useTranslation } from 'react-i18next';
import { useFetchUser } from '@/hooksApi';
import { useEffect } from 'react';

const Dashboard = () => {
  const { t } = useTranslation();

  const selectedValueChart = useSelectStore((state) => state.selectedValue);

  console.log('selectedValueChart', selectedValueChart);

  const { data } = useFetchUser();
  const { setUser } = useUserStore((state) => state);

  useEffect(() => {
    if (data?.object) {
      setUser(data.object);
    }
  }, [data]);

  return (
    <div>
      <Row gutter={[24, 16]} className='mt-[9px]'>
        {/* AVAILABLE COD */}
        <Col lg={16} md={24} xs={24}>
          <CardAvailableCod />
        </Col>
        {/*End of AVAILABLE COD */}

        {/* StatisticalOverviewCard */}
        <Col span={8} lg={8} md={24} xs={24}>
          <StatisticalOverviewCard
            percent={10}
            status='success'
            title={t('total_revenue')}
            value={2000000}
            unit={t('iqd')}
          />
          <StatisticalOverviewCard
            percent={10}
            status='success'
            title={t('net_profit')}
            value={2000000}
            unit={t('iqd')}
          />
          <StatisticalOverviewCard
            percent={10}
            status='exception'
            title={t('return')}
            value={2000000}
            unit={t('iqd')}
            hasMargin={false}
          />
        </Col>
        {/* End of StatisticalOverviewCard */}
      </Row>

      <Row className='mb-6'>
        {/* ORDER OVERVIEW */}
        <Col span={24} className=''>
          <Heading
            title={t('order_overview')}
            level={4}
            fontWeight='font-bold'
            extraClassName='my-6'
          />
          <Row gutter={[24, 16]} className=''>
            <Col span={4} md={8} lg={8} xs={24}>
              <OrderSummaryCards
                value={52}
                title={t('new_orders')}
                Icon={<NewOrders width={18} height={18} />}
              />
            </Col>
            <Col span={4} md={8} lg={8} xs={24}>
              <OrderSummaryCards value={10} title={t('collecting')} Icon={<CollectedIcon />} />
            </Col>
            <Col span={4} md={8} lg={8} xs={24}>
              <OrderSummaryCards value={42} title={t('in_transit')} Icon={<PickupsIcon />} />
            </Col>
            <Col span={4} md={8} lg={8} xs={24}>
              <OrderSummaryCards value={35} title={t('on_hold')} Icon={<OnHoldIcon />} />
            </Col>
            <Col span={4} md={8} lg={8} xs={24}>
              <OrderSummaryCards value={35} title={t('delivered')} Icon={<DeliveredIcon />} />
            </Col>
            <Col span={4} md={8} lg={8} xs={24}>
              <OrderSummaryCards value={35} title={t('returned')} Icon={<ReturnIcon />} />
            </Col>
          </Row>
        </Col>
        {/* End of ORDER OVERVIEW */}
      </Row>

      {/* Chart And Country ORDER  */}
      <Row gutter={[16, 16]}>
        <Col span={12} md={12} xs={24}>
          <OrderPerformanceByChart />
        </Col>
        <Col span={12} md={12} xs={24}>
          <OrderPerformanceByCountry />
        </Col>
      </Row>
      {/* End of Chart And Country ORDER  */}
    </div>
  );
};

export default Dashboard;
