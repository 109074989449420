import { SVGProps } from 'react';

interface CollectedIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CollectedIcon = ({
  width = 32,
  height = 32,
  color = '#38383E',
  ...props
}: CollectedIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} fill='none' {...props}>
    <path
      fill='#38383E'
      fillRule='evenodd'
      d='m8.046 20.394-.095.052-.24.074-.255.026H2.727l-.255-.026-.24-.074-.216-.118-.188-.155-.155-.187-.117-.217-.075-.24-.026-.255V14.55l.026-.255.075-.24.117-.216.155-.188.188-.155.216-.117.24-.075.255-.026h1.91V8.646l.026-.255.074-.24.118-.216.155-.188.187-.155.217-.117.24-.075.255-.026h1.455V2.933l.026-.252.073-.236.115-.212.153-.185.184-.152.213-.116.236-.073.251-.025h4.56l.25.025.236.073.213.116.185.152.152.185.115.212.074.236.025.252v4.441h2.122l.255.026.24.075.216.117.188.155.155.188.117.216.075.24.026.255v4.632h1.455l.256.026.24.075.216.117.187.155.155.188.118.216.074.24.026.255v4.724l-.026.256-.074.24-.118.216-.155.187-.187.155-.217.118-.24.074-.255.026h-4.728l-.256-.026-.24-.074-.095-.052-.095.052-.24.074-.255.026H8.636l-.255-.026-.24-.074-.095-.052Zm-.682-5.752H2.82v4.54h4.545v-4.54Zm3.182-5.904H6.001v4.54h4.545v-4.54Zm2.516-1.364V3.045H8.728v4.33h4.334Zm3.393 5.904v-4.54H11.91v4.54h4.545Zm-1.818 5.904h4.545v-4.54h-4.545v4.54Zm-5.91 0h4.546v-4.54H8.728v4.54Z'
      clipRule='evenodd'
    />
  </svg>
);

export default CollectedIcon;
