import { Flex } from 'antd';
import { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  extraClassName?: string;
}

const Card = ({ children, extraClassName }: CardProps) => {
  return (
    <Flex
      className={`bg-white flex flex-col p-6 rounded-[10px] mb-2 border-customGrayShade3 border-[1px] shadow-card-dark ${extraClassName}`}
    >
      {children}
    </Flex>
  );
};

export default Card;
