import { FORM_TYPE_LOGIN } from '@/config';
// import { convertArabicToEnglishNumbers } from '@/utils';
import { countries } from '@/utils/CountryCodes';
import { Button, Col, Flex, Form, Image, Input, Select, Typography } from 'antd';
import type { Store } from 'antd/es/form/interface'; // Import the type for the form values
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'; // Import the correct type for errorInfo
import { useState } from 'react';
import { Link } from 'react-router-dom';

const { Option } = Select;

interface LoginFormCardProps {
  // direction: string;
  countryCode: string;
  setCountryCode: (value: string) => void;
  onFinish: (values: Store) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity) => void;
  loginText: string;
  phoneNumberPlaceholder: string;
  loginButtonText: string;
  registerText: string;
  registerLinkText: string;
  isLoading?: boolean;
}
const { Text } = Typography;

const LoginFormCard = ({
  // direction,
  countryCode,
  setCountryCode,
  onFinish,
  onFinishFailed,
  loginText,
  phoneNumberPlaceholder,
  loginButtonText,
  registerText,
  registerLinkText,
  isLoading,
}: LoginFormCardProps) => {
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(true); // State to track if phone number input is empty

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setIsPhoneEmpty(value === ''); // Set state based on whether the input is empty
  };

  //HANDLE TO REMOVE NON-NUMERIC CHARACTERS:
  // const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  //   const englishNumberValue = convertArabicToEnglishNumbers(numericValue); // Convert Arabic numbers to English
  //   event.target.value = englishNumberValue;
  // };
  return (
    <Col span={10} className='bg-white'>
      <Form
        name={FORM_TYPE_LOGIN}
        initialValues={{ remember: true, countryCode: countryCode }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='h-full px-[152px] flex flex-col justify-center items-center mx-auto'
      >
        <Col className='h-full  flex flex-col min-w-[344px]  mt-[98px] justify-between pb-[36px]'>
          <Form.Item>
            <Text className='text-[24px] block  text-customTunaPrimary font-[900] mb-4 leading-[27.8px]'>
              {loginText}
            </Text>

            <Flex
              style={{ direction: 'ltr' }}
              className='flex justify-between gap-x-3 items-center '
            >
              <Form.Item
                name='countryCode'
                rules={[{ required: true, message: '' }]}
                style={{ height: '80px' }} // Reserve space for error message
              >
                <Select
                  bordered={false}
                  style={{ direction: 'ltr' }}
                  className='border border-solid   min-w-[105px] border-customGrayShade5  h-[56px]  rounded-[10px]'
                  onChange={(value) => setCountryCode(value)}
                  dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
                >
                  {countries.map((country, index) => (
                    <Option key={index} value={country.dialCodes?.[0]}>
                      <span role='img'>
                        <Image preview={false} src={country.image} width={20} height={20} />
                      </span>{' '}
                      ({country.dialCodes?.[0]})
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className='w-full '
                name='phoneNumber'
                rules={[{ required: true, message: '' }]}
                style={{ height: '80px' }} // Reserve space for error message
              >
                <Flex className='relative'>
                  <Input
                    style={{ direction: 'ltr' }}
                    placeholder={phoneNumberPlaceholder}
                    className='border w-full   border-customGrayShade5  placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-[10px] '
                    onChange={handlePhoneChange} // Trigger on input change
                    // onInput={handlePhoneInput} // Handle input to filter numbers
                    suffix={<span className='text-customRed'>*</span>}
                  />
                </Flex>
              </Form.Item>
            </Flex>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ paddingBottom: '27px', paddingTop: '27px' }}
              type='primary'
              htmlType='submit'
              loading={isLoading}
              className='w-full text-customTunaPrimary flex uppercase justify-center items-center rounded-[8px]   h-[56px]  text-sm font-bold leading-[13.54px] bg-customYellowPrimary'
            >
              {loginButtonText}
            </Button>
            <Text className='font-[400] mb-10  mt-4 whitespace-nowrap flex pt-[2px] justify-center gap-x-[4px] text-[16px] leading-[11px] text-customTunaPrimary'>
              {registerText}{' '}
              <Link
                to={'/register'}
                className='text-customBlue  font-[700] text-[16px] leading-[11px]'
              >
                {registerLinkText}
              </Link>
            </Text>
          </Form.Item>
        </Col>
      </Form>
    </Col>
  );
};

export default LoginFormCard;
