import { SVGProps } from 'react';

interface PhoneYellowIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const PhoneYellowIcon = ({
  width = 45,
  height = 45,
  fillColor = '#EBDD4C',
  ...props
}: PhoneYellowIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect width={44.819} height={44} x={0.18} fill={fillColor} rx={22} />
    <g clipPath='url(#a)'>
      <path
        fill='#38383E'
        fillRule='evenodd'
        d='m18.41 29.77-4.863 1.928a.75.75 0 0 1-.992-.922l1.629-5.178a9.12 9.12 0 0 1-.96-4.084c0-5.059 4.107-9.165 9.166-9.165 5.058 0 9.165 4.106 9.165 9.165 0 5.058-4.107 9.165-9.165 9.165a9.122 9.122 0 0 1-3.98-.908Zm.39-1.483a7.669 7.669 0 0 0 11.254-6.773 7.669 7.669 0 0 0-7.664-7.665 7.669 7.669 0 0 0-6.739 11.319.75.75 0 0 1 .057.583l-1.25 3.972 3.714-1.471a.75.75 0 0 1 .628.035Zm6.786-5.474c.167.084.279.128.317.2.045.067.045.4-.094.79-.14.39-.807.74-1.123.79a2.234 2.234 0 0 1-1.046-.067c-.24-.078-.55-.178-.945-.35-1.663-.718-2.753-2.397-2.837-2.508-.083-.112-.678-.901-.678-1.719 0-.817.428-1.223.578-1.39a.635.635 0 0 1 .445-.206c.111 0 .222 0 .317.006.1.005.24-.04.372.283.14.334.473 1.151.518 1.235.039.083.067.178.011.289-.056.111-.084.184-.167.278-.083.095-.178.217-.25.29-.084.083-.167.172-.072.338.094.167.428.712.923 1.152.634.567 1.168.74 1.334.823.167.083.267.072.362-.04.1-.11.417-.483.528-.65.111-.167.223-.139.373-.083.15.055.967.456 1.134.54Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M10.18 10h24v24h-24z' />
      </clipPath>
    </defs>
  </svg>
);
export default PhoneYellowIcon;
