import { SVGProps } from 'react';

interface AvailableIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const AvailableIcon = ({
  width = 16,
  height = 16,
  color = '#939496',
  ...props
}: AvailableIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 16 16' // Adjusts scaling based on width and height
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M8 .667a7.333 7.333 0 1 1 0 14.666A7.333 7.333 0 0 1 8 .667Zm0 1a6.333 6.333 0 1 0 0 12.666A6.333 6.333 0 0 0 8 1.667Zm.367 8.666c.073 0 .133.06.133.134v.733c0 .074-.06.133-.133.133h-.734A.133.133 0 0 1 7.5 11.2v-.733c0-.074.06-.134.133-.134h.734Zm0-5.666c.073 0 .133.06.133.133v4.4c0 .074-.06.133-.133.133h-.734A.133.133 0 0 1 7.5 9.2V4.8c0-.074.06-.133.133-.133h.734Z'
      clipRule='evenodd'
    />
  </svg>
);

export default AvailableIcon;
