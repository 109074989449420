import { SVGProps } from 'react';

const VectorIcon = ({
  width = 16,
  height = 16,
  color = '#38383E',
  ...props
}: SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M7.28 3.19a5.467 5.467 0 1 0 6.061 5.435h.909a6.375 6.375 0 1 1-7.219-6.32l-.567-.395a.5.5 0 0 1 .572-.82l1.56 1.087a.5.5 0 0 1 .124.696l-1.087 1.56a.5.5 0 0 1-.82-.572l.467-.67ZM5.125 7.75a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75Zm2.75 0a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75Zm2.75 0a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75Z'
      clipRule='evenodd'
    />
  </svg>
);

export default VectorIcon;
