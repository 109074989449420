import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { Flex, Modal, Typography, Tag, Row, Col, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { AddIcon, CalendarOutline, CheckOutline, CloseCircle, DownIcon } from '../Icons';

const { CheckableTag } = Tag;
const { Text } = Typography;

interface ModalProps {
  visible: boolean;
  onClose: () => void;
}

const FiltersModal: React.FC<ModalProps> = ({ visible, onClose }) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [selectedTask, setSelectedTask] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // Use native Date

  const statuses: string[] = ['scheduled', 'collect', 'received_warehouse', 'collected'];
  const locations: string[] = ['store_west', 'store_east'];
  const tasks: string[] = ['pick_up', 'receive_cash', 'receive_returns'];

  const handleStatusChange = (status: string, checked: boolean) => {
    const nextSelectedStatus = checked
      ? [...selectedStatus, status]
      : selectedStatus.filter((t) => t !== status);
    setSelectedStatus(nextSelectedStatus);
  };

  const handleLocationChange = (location: string, checked: boolean) => {
    const nextSelectedLocation = checked
      ? [...selectedLocation, location]
      : selectedLocation.filter((t) => t !== location);
    setSelectedLocation(nextSelectedLocation);
  };

  const handleTaskChange = (task: string, checked: boolean) => {
    const nextSelectedTask = checked
      ? [...selectedTask, task]
      : selectedTask.filter((t) => t !== task);
    setSelectedTask(nextSelectedTask);
  };

  const handleDateChange = (date: Date | null | null) => {
    setSelectedDate(date); // Store the selected date
  };

  const { t, i18n } = useTranslation();

  const getTagClass = (checked: boolean) => {
    return checked
      ? 'border border-customGreen text-customGreen bg-white' // customGreen border and text color when selected
      : 'bg-customGrayShade3 hover:text-customTunaPrimary text-customTunaPrimary'; // customGrayShade3 background when deselected
  };

  const renderIcon = (checked: boolean) => {
    return checked ? (
      <CheckOutline
        width={16}
        height={16}
        color={THEME_VARIABLE.customGreen}
        className={` ${i18n.language === LOCAL_EN ? 'ml-2' : 'mr-2'}`}
      /> // Check icon when selected
    ) : (
      <AddIcon
        width={16}
        height={16}
        color={THEME_VARIABLE.customTunaPrimary}
        className={`${i18n.language === LOCAL_EN ? 'ml-2' : 'mr-2'} mt-[2px]`}
      /> // Plus icon when deselected
    );
  };

  return (
    <Modal
      className='rounded-[20px]   bg-white border border-customGrayShade3'
      title={
        <Flex
          className='font-bold uppercase text-lg leading-5 text-customTunaPrimary'
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {t('filters')}
        </Flex>
      }
      width={760}
      centered
      okText={t('apply')}
      cancelText={t('clear_all')}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      okButtonProps={{
        style: {
          bottom: '4px',
          marginLeft: '16px',
          marginRight: '16px',
          paddingLeft: '53px',
          paddingRight: '53px',
          paddingTop: '26px',
          paddingBottom: '26px',
          fontWeight: '700',
          color: THEME_VARIABLE.customTunaPrimary,
          fontSize: '14px',
          lineHeight: '20px',
          borderRadius: '8px',
          textTransform: 'uppercase',
        },
      }}
      cancelButtonProps={{
        icon: <CloseCircle width={20} height={20} className='top-[2px] relative' />,

        style: {
          paddingLeft: '19px',
          paddingRight: '19px',
          paddingTop: '26px',
          paddingBottom: '26px',
          fontWeight: '700',
          color: THEME_VARIABLE.customTunaPrimary,
          fontSize: '14px',
          lineHeight: '20px',
          borderRadius: '8px',
          textTransform: 'uppercase',
        },
      }}
    >
      <Flex vertical>
        {/* Status Tags */}
        <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary capitalize'>
          {t('by_status')}
        </Text>
        <Row gutter={[8, 8]} className='mt-4 mb-6'>
          {statuses.map((status) => (
            <Col key={status}>
              <CheckableTag
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '100px',
                  padding: '8px 16px',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '20.48px',
                }}
                className={getTagClass(selectedStatus.includes(status))}
                checked={selectedStatus.includes(status)}
                onChange={(checked) => handleStatusChange(status, checked)}
              >
                {t(status)}
                {renderIcon(selectedStatus.includes(status))}
              </CheckableTag>
            </Col>
          ))}
        </Row>
        {/* Location Tags */}
        <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary capitalize'>
          {t('by_pick_up_location')}
        </Text>
        <Row gutter={[8, 8]} className='mt-4 mb-6'>
          {locations.map((location) => (
            <Col key={location}>
              <CheckableTag
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '100px',
                  padding: '8px 16px',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '20.48px',
                }}
                className={getTagClass(selectedLocation.includes(location))}
                checked={selectedLocation.includes(location)}
                onChange={(checked) => handleLocationChange(location, checked)}
              >
                {t(location)}
                {renderIcon(selectedLocation.includes(location))}
              </CheckableTag>
            </Col>
          ))}
        </Row>
        {/* Task Tags */}
        <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary capitalize'>
          {t('by_task_type')}
        </Text>
        <Row gutter={[8, 8]} className='mt-4 mb-6'>
          {tasks.map((task) => (
            <Col key={task}>
              <CheckableTag
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '100px',
                  padding: '8px 16px',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '20.48px',
                }}
                className={getTagClass(selectedTask.includes(task))}
                checked={selectedTask.includes(task)}
                onChange={(checked) => handleTaskChange(task, checked)}
              >
                {t(task)}
                {renderIcon(selectedTask.includes(task))}
              </CheckableTag>
            </Col>
          ))}
        </Row>

        {/* Date Picker with Prefix Icon */}
        <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary capitalize'>
          {t('by_pick_up_date')}
        </Text>
        <Row gutter={[8, 8]} className='mt-4 mb-6'>
          <Col span={12}>
            <Flex className='relative inline-block w-full'>
              <span
                className={` ${i18n.language === LOCAL_EN ? 'left-3' : 'right-3'} absolute  top-1/2 transform -translate-y-1/2 z-10 flex items-center`}
              >
                <CalendarOutline width={20} height={20} color={THEME_VARIABLE.customTunaPrimary} />{' '}
                {/* Custom Icon */}
              </span>
              <DatePicker
                bordered={false}
                className={`w-full custom-date-picker  border border-solid border-customGrayShade5 rounded-lg h-[56px]  ${i18n.language === LOCAL_EN ? 'pl-10' : 'pr-10'} text-center font-bold`} // Tailwind for styling the DatePicker
                onChange={handleDateChange}
                suffixIcon={
                  <DownIcon width={16} height={16} color={THEME_VARIABLE.customTunaPrimary} />
                }
                placeholder={t('select_date_rage')} // Placeholder text
                value={selectedDate}
              />
            </Flex>
          </Col>
        </Row>
      </Flex>
    </Modal>
  );
};

export default FiltersModal;
