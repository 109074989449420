import { THEME_VARIABLE } from '@/config';
import { Col, Flex, Input, Row, Form, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { CheckOutline, EditOutline } from '../Icons';
import { useFetchUser, useUpdateUser, useUploadAvatar } from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';
import ImageRequirementCard from './ImageRequirementCard';
import { useEffect, useState } from 'react';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: user } = useFetchUser(); // Fetch user data
  const { mutate: updateUserMutation, isLoading } = useUpdateUser(); // Profile update mutation
  const { mutate: uploadAvatarMutation, isLoading: isAvatarUploading } = useUploadAvatar(); // Avatar upload mutation

  const [form] = Form.useForm();

  // State to track if there are unsaved changes
  const [hasChanges, setHasChanges] = useState(false);

  // Pre-populate form with existing user data
  useEffect(() => {
    if (user?.object) {
      form.setFieldsValue({
        first_name: user?.object.first_name,
        last_name: user?.object.last_name,
        phone: user?.object.phone, // Display phone, not editable
      });
    }
  }, [user, form]);

  // Handle form value change and mark as having unsaved changes
  const handleFormChange = () => {
    setHasChanges(true); // Mark form as having unsaved changes
  };

  // Handle form submission for profile update
  const handleFormSubmit = (values: any) => {
    const { first_name, last_name } = values;
    updateUserMutation(
      { first_name, last_name }, // Update only first_name and last_name
      {
        onSuccess: (updatedData) => {
          notification.success({
            message: t('success'),
            description: t('success-update-profile'),
          });

          // Refetch user data to sync
          queryClient.invalidateQueries(['user']).then(() => {
            // Set the form with updated values
            form.setFieldsValue({
              first_name: updatedData?.object.first_name,
              last_name: updatedData?.object.last_name,
            });
            setHasChanges(false);
          });
        },
        onError: () => {
          notification.error({
            message: t('error'),
            description: t('failed-update-profile'),
          });
          setHasChanges(false);
        },
      },
    );
  };

  // Handle avatar upload
  const handleAvatarUpload = (file: any) => {
    const formData = new FormData();
    formData.append('file', file.file); // Append file to formData

    uploadAvatarMutation(formData, {
      onSuccess: () => {
        notification.success({
          message: t('success'),
          description: t('success-update-avatar'),
        });
        queryClient.invalidateQueries(['user']); // Refetch user data after avatar upload
      },
      onError: () => {
        notification.error({
          message: t('error'),
          description: t('failed-update-avatar'),
        });
      },
    });
  };

  return (
    <Flex vertical>
      <Heading
        title={t('personal_info')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />

      {/* Phone Number (Display only) */}
      <Card extraClassName='mt-2'>
        <Row gutter={[24, 16]}>
          <Col lg={21} md={24} xs={24}>
            <Input
              placeholder={t('phone_number')}
              bordered={false}
              disabled
              value={user?.object?.phone}
              className='border border-solid border-customGrayShade5 placeholder:capitalize placeholder:font-normal placeholder:text-base placeholder:leading-[20.48px] placeholder:text-GrayShade4 rounded-lg h-[56px]'
            />
          </Col>
        </Row>
      </Card>

      {/* Form for First Name & Last Name */}
      <Form form={form} onFinish={handleFormSubmit} onValuesChange={handleFormChange}>
        <Card extraClassName='mt-3'>
          {/* Image Upload Component */}
          <ImageRequirementCard
            handleAvatarUpload={handleAvatarUpload}
            isAvatarUploading={isAvatarUploading}
            avatarUrl={user?.object?.avatar?.abs_url}
          />
          <Row gutter={[24, 16]} className='mt-4'>
            <Col lg={21} md={24} xs={24}>
              <Form.Item
                name='first_name'
                rules={[{ required: true, message: t('first_name_required') }]}
              >
                <Input
                  placeholder={t('first_name')}
                  bordered={false}
                  className='border border-solid placeholder:capitalize placeholder:font-normal placeholder:text-base placeholder:leading-[20.48px] placeholder:text-GrayShade4 border-customGrayShade5 rounded-lg h-[56px]'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 16]} className='mt-4'>
            <Col lg={21} md={24} xs={24}>
              <Form.Item
                name='last_name'
                rules={[{ required: true, message: t('last_name_required') }]}
              >
                <Input
                  placeholder={t('last_name')}
                  bordered={false}
                  className='border border-solid placeholder:capitalize placeholder:font-normal placeholder:text-base placeholder:leading-[20.48px] placeholder:text-GrayShade4 rounded-lg h-[56px]'
                />
              </Form.Item>
            </Col>
            <Col lg={3} md={24} xs={24}>
              <Button
                loading={isLoading}
                htmlType='submit'
                type='text'
                rootClassName='m-0 p-0'
                className={`flex justify-center items-center w-[56px] min-h-[56px] rounded-[10px] border border-solid ${
                  hasChanges ? 'bg-customYellowPrimary' : 'border-customTunaPrimary'
                }`}
              >
                {/* Conditionally render the icon based on loading and hasChanges state */}
                {!isLoading &&
                  (hasChanges ? (
                    <CheckOutline width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                  ) : (
                    <EditOutline width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                  ))}
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </Flex>
  );
};

export default PersonalInfo;
