import React from 'react';
import MapGL, { Marker } from 'react-map-gl';
import { Flex, Input, Layout, Space } from 'antd';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';
import { LocationOutlineIcon, MarkerIcon, ScanCodeOutlineIcon, SearchIcon } from '../Icons';
import useMapCoordinates, { MAPBOX_TOKEN } from '@/hooks/useMapCoordinates';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';

export interface CordsProps {
  latitude: number;
  longitude: number;
}

interface AddCoordinatesProps {
  onCoordinatesChange: (coords: CordsProps) => void;
  title: string;
  searchPlaceholder: string;
  coordinates?: CordsProps | null;
}

const AddCoordinates: React.FC<AddCoordinatesProps> = ({
  onCoordinatesChange,
  title,
  searchPlaceholder,
  coordinates,
}) => {
  const { viewport, handleViewportChange, handleInputChange, handleMapClick } =
    useMapCoordinates(onCoordinatesChange);
  const { i18n } = useTranslation();

  return (
    <Flex vertical className='mb-8'>
      <Flex className='relative'>
        <Heading
          title={title}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <span
          className={`absolute text-customRed transform -translate-y-1/2 ${
            i18n.language === LOCAL_EN ? 'left-[34%] top-[25%]' : 'right-[34%] top-[25%]'
          }`}
        >
          *
        </span>
      </Flex>
      <Layout className='h-[240px] w-full rounded-[20px] overflow-hidden mt-[14px]'>
        <MapGL
          {...viewport}
          onMove={handleViewportChange}
          mapStyle='mapbox://styles/mapbox/streets-v11'
          mapboxAccessToken={MAPBOX_TOKEN}
          onClick={handleMapClick}
        >
          <Space
            className={`absolute  z-10  ${
              i18n.language === LOCAL_EN ? 'top-3 left-3' : 'top-3 right-3'
            }`}
          >
            <Input
              prefix={<SearchIcon color={THEME_VARIABLE.customGrayShade5} />}
              placeholder={searchPlaceholder}
              className='rounded-[10px] border border-solid border-customGrayShade4  w-[297px] py-2 placeholder:text-sm placeholder:font-normal placeholder:leading-[18px] placeholder:text-customGrayShade5'
              onChange={handleInputChange}
            />
          </Space>
          <Space className='absolute bottom-3 left-3 z-10 bg-customYellowPrimary rounded-full w-6 h-6 flex items-center justify-center'>
            <ScanCodeOutlineIcon
              className={`  ${i18n.language === LOCAL_EN ? 'mt-1 ml-[1px]' : 'mt-[6px]'}`}
            />
          </Space>
          <Space className='absolute bottom-3 right-3 z-10 bg-customYellowPrimary rounded-full w-6 h-6 flex items-center justify-center'>
            <LocationOutlineIcon className='mt-1 ml-[1px]' />
          </Space>
          {coordinates && (
            <Marker latitude={coordinates?.latitude} longitude={coordinates?.longitude}>
              <MarkerIcon />
            </Marker>
          )}
        </MapGL>
      </Layout>
    </Flex>
  );
};

export default AddCoordinates;
