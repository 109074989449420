import { Button, Dropdown, Flex, MenuProps, Space, Typography } from 'antd';
import {
  DeleteIcon,
  DownloadIcon,
  DuplicateIcon,
  EditOutline,
  EyeIcon,
  MoreOutline,
  PickupsIcon,
} from '../Icons';
import eyeIcon from '/eye-outline.svg';
import { Heading } from '../Heading';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrderStatusTab } from '@/types';
import { useHandleOrderActions } from '@/hooks/useHandleOrderActions';
import { ROUTE } from '@/config';
import { ResolveModal } from '../ResolveModal';
import { useResolveModalStore } from '@/hooks';
import { OrderDataType } from '@/hooks/useOrderData';

const { Text } = Typography;

interface ActionProps {
  withoutButton?: boolean;
  withoutAction?: boolean;
  labelBtn?: string;

  Icon?: ReactNode;
  buttonType?: 'primary' | 'default';
  menuItems?: MenuProps['items'];
  CustomizeIcon?: ReactNode;
  routeLink?: string;
  handleClickBtn?: () => void;
  uid?: string;
  activeTab?: OrderStatusTab;
  disabled?: boolean;
  editDisabled?: boolean;
  cancelDisabled?: boolean;
  deleteLabel?: string;
  merchant_action_required?: boolean;
  onClickDropDown?: MenuProps['onClick'];
  full_name?: string;
  phone?: string;
  preview_orders_value?: number;
  pick_up_address_title?: string;
  orderData?: OrderDataType;
}

// setFullName(full_name || '');
// setPhone(phone || '');
// setOrderValue(String(preview?.orders_value || 0));
// setAddressTitle(pick_up_address?.title || '');
const ActionsTable = ({
  withoutButton = false,
  withoutAction = false,
  editDisabled = false,
  cancelDisabled = false,
  labelBtn,

  Icon = <PickupsIcon width={20} height={20} />,
  buttonType = 'primary',
  menuItems,
  CustomizeIcon = <img src={eyeIcon} />,
  routeLink,
  handleClickBtn,
  uid,
  activeTab,
  disabled,
  deleteLabel,
  merchant_action_required,
  orderData,
  onClickDropDown,
}: ActionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resolveModalOpen, setResolveModalOpen] = useState(false);

  // Zustand store setters
  const setFullName = useResolveModalStore((state) => state.setFullName);
  const setPhone = useResolveModalStore((state) => state.setPhone);
  const setOrderValue = useResolveModalStore((state) => state.setOrderValue);
  const setAddressTitle = useResolveModalStore((state) => state.setAddressTitle);

  labelBtn = labelBtn || t('ship_now');

  // mutate cancel or delete order
  const { handleOrderAction, isLoading } = useHandleOrderActions(
    uid || '',
    activeTab || OrderStatusTab.ReadyToShip,
  );

  ////! refactored const { data } = useOrderByUID( uid || '');

  if (orderData && resolveModalOpen && uid && orderData?.merchant_action_required) {
    setFullName(orderData?.customerName || '');
    setPhone(orderData.phone || '');
    setOrderValue(String(orderData?.orderValue || 0));
    setAddressTitle(orderData?.pickupLocation || '');
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'edit':
        return navigate(ROUTE.ORDER_EDIT.replace(':orderUid', String(uid)));
      case 'view':
        return activeTab === OrderStatusTab.RTO
          ? navigate(`${ROUTE.ORDER_DETAILS_RTO}/${uid}`)
          : navigate(`${ROUTE.ORDER_DETAILS}/${uid}`);
      case 'delete':
        handleOrderAction();
        break;
      default:
        console.log('unknown');
    }
  };

  // console.log('orderData', orderData);
  const items: MenuProps['items'] = menuItems || [
    {
      key: 'edit',
      label: (
        <Heading
          title={t('edit')}
          leadingIcon={<EditOutline />}
          rotation={false}
          disabled={editDisabled}
        />
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'view',
      label: (
        <Heading
          title={t('view')}
          leadingIcon={<EyeIcon stroke='#fff' fill='#fff' />}
          rotation={false}
        />
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'delete',
      label: (
        <Heading
          title={deleteLabel ?? t('cancel')}
          leadingIcon={<DeleteIcon />}
          rotation={false}
          loading={isLoading}
          disabled={cancelDisabled}
        />
      ),
    },
  ];
  const handleClickBtnn = () => {
    if (activeTab === OrderStatusTab.OnHold && orderData?.merchant_action_required) {
      setResolveModalOpen(true); // Open the modal for "OnHold" orders
    } else if (handleClickBtn) {
      handleClickBtn(); // Call the original handler if not "OnHold"
    }
  };

  return (
    <Space>
      {!withoutButton && (
        <Button
          type={buttonType === 'primary' ? 'primary' : 'default'}
          className={` ${buttonType === 'default' ? 'border-solid w-[44px]  border-customTunaPrimary' : 'px-5 '}`}
          onClick={buttonType === 'default' ? () => navigate(routeLink || '') : handleClickBtnn}
          disabled={disabled}
        >
          {buttonType === 'primary' ? (
            <Heading
              title={labelBtn}
              leadingIcon={Icon}
              fontSize='text-xs'
              fontWeight='font-bold'
              extraClassName='uppercase  leading-[20px]'
              rotation={false}
            />
          ) : (
            CustomizeIcon
          )}
        </Button>
      )}
      {!withoutAction && (
        <Dropdown
          menu={{ items, onClick: onClickDropDown || onClick }}
          trigger={['click']}
          className='relative right-0'
        >
          <Flex className='flex  justify-center cursor-pointer items-center w-[44px] h-[44px] rounded-lg border border-solid border-customTunaPrimary'>
            <MoreOutline width={24} height={24} />
          </Flex>
        </Dropdown>
      )}
      {/* Render ResolveModal when activeTab is OnHold */}
      {activeTab === OrderStatusTab.OnHold && orderData?.merchant_action_required && (
        <ResolveModal
          visible={resolveModalOpen}
          onClose={() => setResolveModalOpen(false)}
          orderId={uid}
          platformCode={orderData?.platform_code}
          comment={orderData?.merchant_action_required_text}
        />
      )}
    </Space>
  );
};

export default ActionsTable;
