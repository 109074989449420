import { useMemo, useState } from 'react';
import useOrderUidEdit from './useOrderUidEdit';
import {
  useAddProductToOrder,
  useDeleteProductFromOrder,
  useUpdateProductByUID,
} from '@/hooksApi/useOrder';

interface ProductPivot {
  created_at: string;
  updated_at: string;
}

interface Product {
  uid?: string;
  platform_code?: string;
  title?: string;
  price?: number | string;
  currency?: string;
  quantity?: string | number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  pivot?: ProductPivot;
}

const useEditRowProductModal = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [newProduct, setNewProduct] = useState<Product>({ title: '', quantity: '', price: '' });
  const orderUid = useOrderUidEdit((state) => state.orderUid); // get the order UID from Zustand
  const { mutate: updateProductByUID } = useUpdateProductByUID(); // Use mutation hook for updating products
  const { mutate: addProductToOrder } = useAddProductToOrder(); // Use mutation hook for adding products to the order
  const { mutate: deleteProductFromOrder } = useDeleteProductFromOrder(); // Use mutation hook for adding products to the order

  // Function to calculate the total sum of all products
  const totalSum = useMemo(() => {
    return products.reduce((acc, product) => {
      const quantity =
        typeof product.quantity === 'number'
          ? product.quantity
          : parseFloat(product.quantity || '0');
      const price =
        typeof product.price === 'number' ? product.price : parseFloat(product.price || '0');
      return acc + quantity * price;
    }, 0);
  }, [products]);

  //ADD PRODUCT
  const handleAddProduct = () => {
    if (newProduct.title && newProduct.quantity && newProduct.price) {
      // Add the product to the state
      setProducts([...products, newProduct]);

      // Call the API to add the product to the order
      if (orderUid !== '') addProductToOrder({ orderUID: orderUid, product: newProduct });

      // Clear inputs
      setNewProduct({ title: '', quantity: '', price: '' });
    }
  };
  //DELETE PRODUCT
  const handleDeleteProduct = (index: number) => {
    if (products[index].uid) {
      if (orderUid !== '')
        deleteProductFromOrder({ orderUID: orderUid, productUID: products[index].uid });

      const updatedProducts = products.filter((_, i) => i !== index);
      setProducts(updatedProducts);
    }
  };

  const handleChangeNewProduct = (field: keyof Product, value: string) => {
    setNewProduct({ ...newProduct, [field]: value });
  };

  const handleChangeProduct = (index: number, field: keyof Product, value: string) => {
    const updatedProducts = [...products];
    const productToUpdate = updatedProducts[index];

    // Check if we're modifying the 'pivot' field, which is an object
    if (field === 'pivot') {
      const pivotField = value as keyof ProductPivot;
      updatedProducts[index].pivot = {
        ...updatedProducts[index].pivot,
        [pivotField]: value,
      } as ProductPivot;
    } else {
      // Handle normal fields like title, price, and quantity
      updatedProducts[index][field] = value;
    }

    // If the product has a UID, we can perform an update via the API
    if (productToUpdate.uid && orderUid !== '') {
      updateProductByUID({
        orderUID: orderUid,
        productUID: productToUpdate.uid,
        updatedProduct: { [field]: value },
      });
    }

    setProducts(updatedProducts);
  };

  // Accepts an optional array to reset products, defaulting to an empty array
  const resetProducts = (newProducts: Product[] = []) => {
    setProducts(newProducts);
  };

  return {
    products,
    totalSum, // Expose the total sum
    newProduct,
    handleAddProduct,
    handleDeleteProduct,
    handleChangeNewProduct,
    handleChangeProduct,
    resetProducts, // Export reset function
  };
};

export default useEditRowProductModal;
