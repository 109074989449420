import { CashIcon, PickupIcon, ReciveReturnIcon } from '@/components';
import { StatusEnum } from '@/types';

// datas table tab Ready to Ship
export const dataReady = [
  {
    key: '1',
    orderDate: '8 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '2',
    orderDate: '8 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '4',
    orderDate: '9 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '3',
    orderDate: '10 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '5',
    orderDate: '11 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '5',
    orderDate: '12 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '5',
    orderDate: '13 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    quantity: 12,
    totalValue: '55,000 IQD',
    status: 'Scheduled',
  },
];

// datas table tab Inprogress
export const dataInprogress = [
  {
    key: '1',
    orderDate: '1 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    product: 'COD #456846898488\nTotal 5,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '2',
    orderDate: '2 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    product: 'COD #456846898488\nTotal 5,000 IQD',
    status: 'Scheduled',
  },
  {
    key: '4',
    orderDate: '3 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    product: 'COD #456846898488\nTotal 5,000 IQD',
    status: 'Scheduled',
  },
];

// datas table tab onHold
export const dataOnHold = [
  {
    key: '1',
    orderDate: '1 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    product: 'COD #456846898488\nTotal 5,000 IQD',
    status: 'On Hold',
  },
  {
    key: '2',
    orderDate: '5 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    product: 'COD #456846898488\nTotal 5,000 IQD',
    status: 'On Hold',
  },
  {
    key: '4',
    orderDate: '6 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    pickupLocation: 'Store West',
    product: 'COD #456846898488\nTotal 5,000 IQD',
    status: 'On Hold',
  },
];
// datas table tab RTO
// export const dataRTO = [
//   {
//     key: '1',
//     orderDate: '1 Aug 24',
//     orderId: '#456846879',
//     customerName: 'John Doe',
//     customerAddress: 'Al asmaee, Al basrah, 61002',
//     pickupLocation: 'Store West',
//     product: 'COD #456846898488\nTotal 5,000 IQD',
//     status: 'On Hold',
//   },
//   {
//     key: '2',
//     orderDate: '5 Aug 24',
//     orderId: '#456846879',
//     customerName: 'John Doe',
//     customerAddress: 'Al asmaee, Al basrah, 61002',
//     pickupLocation: 'Store West',
//     product: 'COD #456846898488\nTotal 5,000 IQD',
//     status: 'On Hold',
//   },
//   {
//     key: '4',
//     orderDate: '6 Aug 24',
//     orderId: '#456846879',
//     customerName: 'John Doe',
//     customerAddress: 'Al asmaee, Al basrah, 61002',
//     pickupLocation: 'Store West',
//     product: 'COD #456846898488\nTotal 5,000 IQD',
//     status: 'On Hold',
//   },
//   {
//     key: '5',
//     orderDate: '6 Aug 24',
//     orderId: '#456846879',
//     customerName: 'John Doe',
//     customerAddress: 'Al asmaee, Al basrah, 61002',
//     pickupLocation: 'Store West',
//     product: 'COD #456846898488\nTotal 5,000 IQD',
//     status: 'On Hold',
//   },
// ];
export const dataRTO = [
  {
    key: '1',
    time: '10:00 AM',
    dropOffLocation: 'Store East',
    driver: {
      image: '/driver-banner.svg', // Replace with actual image path
      name: 'Ahmed Ali',
      phone: '+964 770 123 4567',
    },
    status: 'In Transit',
  },
  {
    key: '2',
    time: '2:30 PM',
    dropOffLocation: 'Store North',
    driver: {
      image: '/driver-banner.svg', // Replace with actual image path
      name: 'Sara Hassan',
      phone: '+964 770 987 6543',
    },
    status: 'In Transit',
  },
];
// datas table tab Finished
export const dataFinished = [
  {
    key: '1',
    orderDate: '7 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    quantity: 12,
    totalValue: '55,000',
    status: 'Delivered',
  },
  {
    key: '2',
    orderDate: '8 Aug 24',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    quantity: 13,
    totalValue: '55,000',
    status: 'Delivered',
  },
];

// datas table pickups
export const dataPickups = [
  {
    key: '1',
    location: 'Store East',
    driver: 'John Doe',
    driver_tasks: [
      { text: 'Pick-up', Icon: <PickupIcon /> },
      { text: 'Receive Cash', Icon: <CashIcon width={16} height={14} className='mt-1' /> },
      { text: 'Receive Returns', Icon: <ReciveReturnIcon className='mr-1' /> },
    ],
    status: StatusEnum.COLLECTING,
    eta: '58 min',
    customerName: 'Jane Smith',
    customerAddress: '456 Elm St, Springfield',
    pickupTime: '8 Aug 24',
    pickupLocation: '1pm - 3pm',
  },
  {
    key: '2',
    location: 'Store East',
    driver: 'Alice Johnson',
    driver_tasks: [
      { text: 'Pick-up', Icon: <PickupIcon /> },
      { text: 'Receive Cash', Icon: <CashIcon width={16} height={14} className='mt-1' /> },
    ],
    status: StatusEnum.NEW,
    eta: '58 min',
    customerName: 'Bob Brown',
    customerAddress: '321 Oak St, Metropolis',
    pickupTime: '8 Aug 24',
    pickupLocation: '1pm - 3pm',
  },
];

export const dataSourceCsv = [
  { key: '1', label: 'Order ID', value: '#56945698' },
  { key: '2', label: 'Phone', value: '+971 586 66 66' },
  { key: '3', label: 'City', value: 'Baghdad' },
  { key: '4', label: 'Area', value: 'Lorem ipsum' },
  { key: '5', label: 'Address', value: 'Lorem ipsum' },
  { key: '6', label: 'Pickup Address', value: 'Store West' },
  { key: '7', label: 'Pickup Address', value: 'Small' },
];

export const DataSourceImport = [
  {
    key: '1',
    validity: 'valid',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerPhone: '+971 588 66 44',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    quantity: 8,
    total: 5000,
    packageSize: 'Small',
    orderPayment: 'COD',
    shipmentPayment: 'Merchant',
  },
  {
    key: '2',
    validity: 'valid',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerPhone: '+971 588 66 44',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    quantity: 8,
    total: 5000,
    packageSize: 'Small',
    orderPayment: 'COD',
    shipmentPayment: 'Merchant',
  },
  {
    key: '3',
    validity: 'invalid',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerPhone: '+971 588 66 44',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    quantity: 8,
    total: 5000,
    packageSize: 'Small',
    orderPayment: 'COD',
    shipmentPayment: 'Merchant',
  },
  {
    key: '4',
    validity: 'valid',
    orderId: '#456846879',
    customerName: 'John Doe',
    customerPhone: '+971 588 66 44',
    customerAddress: 'Al asmaee, Al basrah, 61002',
    quantity: 8,
    total: 5000,
    packageSize: 'Small',
    orderPayment: 'COD',
    shipmentPayment: 'Merchant',
  },
];
export const dataRemittance = [
  {
    key: '1',
    date: '12 Jun 2024',
    remittanceId: '#456846879',
    amount: '500,000 IQD',
    status: 'Initiated',
  },
  {
    key: '2',
    date: '6 Jun 2024',
    remittanceId: '#456846879',
    amount: '1,300,000 IQD',
    status: 'Initiated',
  },
  {
    key: '3',
    date: '6 Jun 2024',
    remittanceId: '#456846879',
    amount: '1,300,000 IQD',
    status: 'Initiated',
  },
  {
    key: '4',
    date: '30 May 2024',
    remittanceId: '#456846879',
    amount: '130,000 IQD',
    status: 'Completed',
  },
];

export const financeCODData = [
  {
    key: '1',
    date: '8 Aug 24',
    remittanceId: '#456846879',
    amount: '55,000',
    eta: '13 Jun 2024',
    status: 'Initiated',
  },
  {
    key: '2',
    date: '9 Aug 24',
    remittanceId: '#456846879',
    amount: '55,000',
    eta: '13 Jun 2024',
    status: 'Initiated',
  },
  {
    key: '3',
    date: '10 Aug 24',
    remittanceId: '#456846879',
    amount: '55,000',
    eta: '13 Jun 2024',
    status: 'Initiated',
  },
];

export const financeTransactionData = [
  {
    key: '1',
    date: '8 Aug 24',
    transactionId: '#456846879',
    title: 'Shipping Fee',
    amount: '-2000',
  },
  {
    key: '2',
    date: '9 Aug 24',
    transactionId: '#456846879',
    title: 'Loyalty Incentive',
    amount: '+5000',
  },
  {
    key: '3',
    date: '10 Aug 24',
    transactionId: '#456846879',
    title: 'Shipping Fee',
    amount: '-3000',
  },
  {
    key: '4',
    date: '11 Aug 24',
    transactionId: '#456846879',
    title: 'Shipping Fee',
    amount: '+3000',
  },
  {
    key: '5',
    date: '12 Aug 24',
    transactionId: '#456846879',
    title: 'Shipping Fee',
    amount: '+8000',
  },
];
