import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetchPickupAddresses, useUpdatePickupAddress } from '@/hooksApi/usePickupAddress';
import { PickupAddress, PickupAddressType } from '@/types';
import { useQueryClient } from '@tanstack/react-query';

export const useCodDropOff = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [selectLocation, setSelectLocation] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const { data, isLoading: pickupAddressLoading } = useFetchPickupAddresses(page);
  const queryClient = useQueryClient();

  const [pickupAddresses, setPickupAddresses] = useState<PickupAddress[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);

  const { mutate: updateTypePickupAddress, isLoading: updatePickupAddressLoading } =
    useUpdatePickupAddress();

  useEffect(() => {
    if (data) {
      setPickupAddresses(data.object.items);
      setLoadingMore(false);
    }
  }, [data]);

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    if (checked) {
      setSelectLocation(undefined);
      setPage(1);
    }
  };

  const handleChange = (value: string, defaultValue?: boolean) => {
    setSelectLocation(value);
    updateTypePickupAddress(
      {
        uid: value,
        data: {
          type: PickupAddressType.COD,
          default: defaultValue ?? true,
        },
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries(['pickupAddresses']);
          message.success(t('operation'));
        },
      },
    );
  };

  const loadMore = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight && !loadingMore) {
      if (data && data.object.items.length > 0) {
        setLoadingMore(true);
        setPage((prev) => prev + 1);
      }
    }
  };

  return {
    isChecked,
    selectLocation,
    pickupAddresses,
    pickupAddressLoading,
    updatePickupAddressLoading,
    loadingMore,
    handleCheckboxChange,
    handleChange,
    loadMore,
  };
};
