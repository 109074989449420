import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Flex, Input, Typography } from 'antd';
import { MediumBtnIcon } from '../Icons';
import { THEME_VARIABLE } from '@/config';
import { useTranslation } from 'react-i18next';

interface CustomShipmentFeeProps {
  onCustomShipmentFeeChange: (shipmentFee?: number) => void;
  customShipmentFee?: number;
}

const CustomShipmentFeeCard: React.FC<CustomShipmentFeeProps> = ({
  customShipmentFee,
  onCustomShipmentFeeChange,
}) => {
  const [customShipmentFeeState, setCustomShipmentFeeState] = useState<number | undefined>(
    customShipmentFee,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (customShipmentFee) setCustomShipmentFeeState(customShipmentFee);
  }, [customShipmentFee]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCustomShipmentFee = e.target.value ? Number(e.target.value) : undefined;

    if (!Number.isNaN(newCustomShipmentFee) || newCustomShipmentFee === undefined)
      setCustomShipmentFeeState(newCustomShipmentFee);
  };

  const handleChangeCustomShipmentFee = () => {
    if (customShipmentFeeState) onCustomShipmentFeeChange(customShipmentFeeState);
  };

  const handleClearCustomShipmentFee = () => {
    setCustomShipmentFeeState(undefined);
    onCustomShipmentFeeChange(undefined);
  };

  return (
    <>
      <Typography.Text className='text-[11px] font-[400] text-customGrayShade5'>
        {t('customer_shipping_fee')}
      </Typography.Text>
      <Flex align='center' gap={8}>
        <Input
          placeholder={t('amount')}
          style={{ flexGrow: 1 }}
          className='h-[44px]'
          onChange={handleInputChange}
          value={customShipmentFeeState}
          onClear={handleClearCustomShipmentFee}
          allowClear
        />
        <Button
          className=''
          style={{
            backgroundColor: customShipmentFeeState
              ? THEME_VARIABLE.customYellowPrimary
              : THEME_VARIABLE.customWhite,
            borderColor: customShipmentFeeState
              ? THEME_VARIABLE.customYellowPrimary
              : THEME_VARIABLE.GrayShade5,
          }}
          onClick={handleChangeCustomShipmentFee}
          disabled={!customShipmentFeeState}
        >
          <MediumBtnIcon />
        </Button>
      </Flex>
    </>
  );
};

export default CustomShipmentFeeCard;
