import { Col, Flex, Input, Row, Select, Button, Form, notification } from 'antd';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import ImageRequirementCard from './ImageRequirementCard';
import { THEME_VARIABLE } from '@/config';
import { EditOutline, CheckOutline } from '../Icons';
import { useFetchCompany, useUpdateCompany, useUploadCompanyLogo } from '@/hooksApi'; // Use your custom hooks
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

const CompanyInfo = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  // Fetch company details for pre-populating form fields
  const { data: company } = useFetchCompany();

  // Update company info mutation
  const { mutate: updateCompany, isLoading: isUpdating } = useUpdateCompany();

  // Upload company logo mutation
  const { mutate: uploadLogo, isLoading: isUploading } = useUploadCompanyLogo();

  // State to track if there are unsaved changes
  const [hasChanges, setHasChanges] = useState(false);

  // Pre-populate form with existing user data
  useEffect(() => {
    if (company?.object) {
      form.setFieldsValue({
        name: company?.object.name || '',
        industry: company?.object.industry || '',
      });
    }
  }, [company, form]);

  // Handle form value change and mark as having unsaved changes
  const handleFormChange = () => {
    setHasChanges(true); // Mark form as having unsaved changes
  };

  // Handle form submission for updating company info
  const handleFormSubmit = (values: any) => {
    const { name, industry } = values;
    updateCompany(
      { name, industry },
      {
        onSuccess: (updatedData) => {
          notification.success({
            message: t('success'),
            description: t('success-update-profile'),
          });

          // Refetch user data and set updated values in the form
          queryClient.invalidateQueries(['company']).then(() => {
            form.setFieldsValue({
              name: updatedData?.object.name || '',
              industry: updatedData?.object.industry || '',
            });
            setHasChanges(false);
          });
        },
        onError: () => {
          notification.error({
            message: t('error'),
            description: t('failed-update-profile'),
          });
          setHasChanges(false);
        },
      },
    );
  };

  // Handle logo upload
  const handleLogoUpload = (file: any) => {
    const formData = new FormData();
    formData.append('file', file.file);

    uploadLogo(formData, {
      onSuccess: () => {
        notification.success({
          message: t('success'),
          description: t('success-update-avatar'),
        });
        queryClient.invalidateQueries(['company']); // Refetch company data after logo upload
      },
      onError: () => {
        notification.error({
          message: t('error'),
          description: t('failed-update-avatar'),
        });
      },
    });
  };

  return (
    <Flex vertical className='mt-[30px]'>
      <Heading
        title={t('company_info')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Card extraClassName='mt-3'>
        <ImageRequirementCard
          handleAvatarUpload={handleLogoUpload} // Use for logo upload
          isAvatarUploading={isUploading}
          avatarUrl={company?.object?.avatar?.abs_url} // Provide the current avatar URL if available
        />

        <Form form={form} onFinish={handleFormSubmit} onValuesChange={handleFormChange}>
          <Row gutter={[24, 16]} className='mt-6'>
            <Col lg={21} md={24} xs={24}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: t('Company name is required') }]}
              >
                <Input
                  placeholder={t('company_name')}
                  bordered={false}
                  className='border border-solid placeholder:capitalize placeholder:font-normal placeholder:text-base placeholder:leading-[20.48px] placeholder:text-GrayShade4 border-customGrayShade5 rounded-lg h-[56px]'
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 16]} className='mt-4'>
            <Col lg={21} md={24} xs={24}>
              <Form.Item
                name='industry'
                rules={[{ required: true, message: t('Industry is required') }]}
              >
                <Select
                  bordered={false}
                  placeholder={t('industry')}
                  className='w-full border border-solid custom-select-placeholder border-customGrayShade5 rounded-lg h-[56px]'
                >
                  <Select.Option value='beauty'>{t('beauty')}</Select.Option>
                  <Select.Option value='online_shop'>{t('online_shop')}</Select.Option>
                  <Select.Option value='technology'>{t('technology')}</Select.Option>
                  <Select.Option value='finance'>{t('finance')}</Select.Option>
                  <Select.Option value='healthcare'>{t('healthcare')}</Select.Option>
                  <Select.Option value='education'>{t('education')}</Select.Option>
                  <Select.Option value='real_estate'>{t('real_estate')}</Select.Option>
                  <Select.Option value='retail'>{t('retail')}</Select.Option>
                  <Select.Option value='manufacturing'>{t('manufacturing')}</Select.Option>
                  <Select.Option value='hospitality'>{t('hospitality')}</Select.Option>
                  <Select.Option value='transportation'>{t('transportation')}</Select.Option>
                  <Select.Option value='agriculture'>{t('agriculture')}</Select.Option>
                  {/* Add more options as needed */}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={3} md={24} xs={24}>
              <Button
                htmlType='submit'
                loading={isUpdating}
                className={`flex justify-center items-center w-[56px] min-h-[56px] rounded-[10px] border border-solid ${
                  hasChanges ? 'bg-customYellowPrimary' : 'border-customTunaPrimary'
                }`}
              >
                {/* Conditionally render the icon based on loading and hasChanges state */}
                {!isUpdating &&
                  (hasChanges ? (
                    <CheckOutline width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                  ) : (
                    <EditOutline width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                  ))}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Flex>
  );
};

export default CompanyInfo;
