import { Col, Flex, Form, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';

interface ResolveAddCommentProps {
  failedReason?: string;
}

const { Text } = Typography;

const ResolveAddComment: React.FC<ResolveAddCommentProps> = ({ failedReason }) => {
  const { t } = useTranslation();
  return (
    <Flex vertical className='mt-[34px] mb-10'>
      <Heading
        title={t('add_your_comment')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Row>
        <Col lg={24} md={24} xs={24} className=' '>
          <Form.Item name='comment'>
            <TextArea
              bordered={false}
              className='h-[65px] rounded-[10px] border mt-4 border-solid  border-customGrayShade5'
              placeholder={t('do_not_ring_door')}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row className='mt-[26px]'>
        <Col lg={24} md={24} xs={24}>
          <Text className='   capitalize font-normal text-base leading-[20.48px]   text-customTunaPrimary'>
            {failedReason}
          </Text>
        </Col>
      </Row> */}
    </Flex>
  );
};

export default ResolveAddComment;
