import React from 'react';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { Flex, Typography } from 'antd';

const { Text } = Typography;

// Step 1: Define the Props interface
interface PickupLocationCardProps {
  title: string;
  locationType: string;
  address: string;
}

// Step 2: Update the component to accept props
const PickupLocationCard: React.FC<PickupLocationCardProps> = ({
  title,
  locationType,
  address,
}) => {
  return (
    <Card>
      <Heading
        title={title} // Use the title prop
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Flex vertical className='mt-[17px]'>
        <Text className=' font-normal text-sm leading-[18.2px] text-customGrayShade5'>
          {locationType} {/* Use the locationType prop */}
        </Text>
        <Text className=' font-normal text-base leading-[20.48px] text-customTunaPrimary'>
          {address} {/* Use the address prop */}
        </Text>
      </Flex>
    </Card>
  );
};

export default PickupLocationCard;
