import { SVGProps } from 'react';

interface CashIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CashIcon = ({ width = 24, height = 24, color = '#38383E', ...props }: CashIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      d='M1.76 9.5A1.276 1.276 0 0 1 .5 8.225v-6.2C.5 1.32 1.071.75 1.775.75h10.45c.704 0 1.275.571 1.275 1.275v6.2c0 .704-.571 1.275-1.275 1.275H7.74a3.001 3.001 0 0 1-2.99 3.25A3.001 3.001 0 0 1 1.76 9.5Zm5.717-1h4.748a.275.275 0 0 0 .275-.275v-6.2a.275.275 0 0 0-.275-.275H1.775a.275.275 0 0 0-.275.275v6.2c0 .152.123.275.275.275h.248a3.002 3.002 0 0 1 5.454 0ZM9.25 8a.5.5 0 0 1 0-1h2.25a.5.5 0 0 1 0 1H9.25ZM2.5 3.25a.5.5 0 0 1 0-1h2.25a.5.5 0 0 1 0 1H2.5Zm4.5 0A1.876 1.876 0 0 1 7 7a1.876 1.876 0 0 1 0-3.75Zm0 1A.875.875 0 1 0 7 6a.875.875 0 0 0 0-1.75Zm-2.25 7.5a2 2 0 0 0 0-4 2 2 0 0 0 0 4Zm-.5-1.864-.255.233a.5.5 0 0 1-.675-.738l1.092-1a.5.5 0 0 1 .676 0l1.092 1a.5.5 0 0 1-.675.738l-.255-.233v.864a.5.5 0 0 1-1 0v-.864Z'
      clipRule='evenodd'
    />
  </svg>
);

export default CashIcon;
