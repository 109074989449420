import { useSentryQuery, useSentryMutation } from '../utils/sentryQueryWrapper';
import {
  createRemittance,
  deleteRemittance,
  fetchAvailableCod,
  fetchRemittanceByUID,
  fetchRemittances,
  fetchTransactions,
  updateRemittanceStatus,
} from '@/api/remittanceService';
import {
  AvailableCodResponse,
  ErrorMessageResponse,
  Remittance,
  RemittanceListResponse,
  RemittanceResponse,
  RemittanceSingleResponse,
  TranslationListResponse,
} from '@/types';

type RemittanceStatus = 'initiated' | 'in-transit' | 'delivered';

// Fetch all remittances with pagination
export const useRemittances = (page: number, pageSize?: number, enabled?: boolean) =>
  useSentryQuery<RemittanceListResponse, ErrorMessageResponse>(
    ['remittances', page],
    () => fetchRemittances(page, pageSize),
    { enabled },
  );

// Fetch all transactions
export const useTransactions = (page: number, pageSize?: number, enabled?: boolean) =>
  useSentryQuery<TranslationListResponse, ErrorMessageResponse>(
    ['transactions', page],
    () => fetchTransactions(page, pageSize),
    { enabled },
  );

// Fetch a single remittance by UID
export const useFetchRemittanceByUID = (uid: string) =>
  useSentryQuery<RemittanceSingleResponse, ErrorMessageResponse>(
    ['remittance', uid],
    () => fetchRemittanceByUID(uid),
    { enabled: !!uid },
  );

// Create a new remittance
export const useCreateRemittance = () =>
  useSentryMutation<RemittanceResponse, ErrorMessageResponse, Remittance>(
    (remittance: Remittance) => createRemittance(remittance),
  );

// Delete a remittance by UID
export const useDeleteRemittance = () =>
  useSentryMutation<void, ErrorMessageResponse, number>((uid: number) => deleteRemittance(uid));

// Update the status of an existing remittance
export const useUpdateRemittanceStatus = () =>
  useSentryMutation<void, ErrorMessageResponse, { uid: number; status: RemittanceStatus }>(
    ({ uid, status }) => updateRemittanceStatus(uid, status),
  );

// Fetch available COD
export const useFetchAvailableCod = () =>
  useSentryQuery<AvailableCodResponse, ErrorMessageResponse>(['availableCod'], () =>
    fetchAvailableCod(),
  );
