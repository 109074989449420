import React from 'react';
import { SVGProps } from 'react';

interface RightIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const RightIcon: React.FC<RightIconProps> = ({
  width = 16,
  height = 16,
  color = '#939496',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M5.798 1.704l6.06 5.85a.498.498 0 0 1 .033.671l-.032.036-6.06 6.034a.134.134 0 0 1-.095.038H4.662a.133.133 0 0 1-.094-.227l6.205-6.179-6.199-6.031a.133.133 0 0 1 .093-.23h1.039c.034 0 .067.014.092.038Z'
    />
  </svg>
);

export default RightIcon;
