import { axiosJSONInstance } from '@/config';
import {
  AvailableCodResponse,
  Remittance,
  RemittanceListResponse,
  RemittanceResponse,
  RemittanceSingleResponse,
  TranslationListResponse,
} from '@/types'; // Ensure this path is correct based on your folder structure

// Fetch all remittances
export const fetchRemittances = async (
  page: number,
  pageSize?: number,
): Promise<RemittanceListResponse> => {
  const response = await axiosJSONInstance.get(
    `/merchants/remittance?page=${page}&perpage=${pageSize || 25}`,
  );
  return response.data;
};

// Fetch all transactions
export const fetchTransactions = async (
  page: number,
  pageSize?: number,
): Promise<TranslationListResponse> => {
  const response = await axiosJSONInstance.get(
    `/merchants/transactions?page=${page}&perpage=${pageSize || 25}`,
  );
  return response.data;
};

// Fetch a remittance by UID
export const fetchRemittanceByUID = async (uid: string): Promise<RemittanceSingleResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/remittance/${uid}`);
  return response.data;
};

// Create a new remittance
export const createRemittance = async (remittance: Remittance): Promise<RemittanceResponse> => {
  const response = await axiosJSONInstance.post('/merchants/remittance', remittance);
  return response.data;
};
// Delete a remittance by UID
export const deleteRemittance = async (uid: number): Promise<void> => {
  await axiosJSONInstance.delete(`/merchants/remittance/${uid}`);
};

// Update a remittance's status
export const updateRemittanceStatus = async (
  uid: number,
  status: 'initiated' | 'in-transit' | 'delivered',
) => {
  const response = await axiosJSONInstance.patch(`/merchants/remittance/${uid}/status/${status}`);
  return response.data;
};

// fetch available COD
export const fetchAvailableCod = async (): Promise<AvailableCodResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/transactions/available-cod`);
  return response.data;
};

// Fetch labels Remittance by UID
export const fetchLabelRemittanceByUID = async (uid: string): Promise<File> => {
  const response = await axiosJSONInstance.get(`/merchants/remittance/${uid}/statement`, {
    responseType: 'blob',
  });
  return response.data;
};
