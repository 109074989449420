import { SVGProps } from 'react';

interface EnvironmentIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const EnvironmentIcon = ({
  width = 24,
  height = 24,
  color = '#38383E',
  ...props
}: EnvironmentIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M12 1.75c4.97 0 9 4.04 9 9.022a9 9 0 0 1-2.14 5.84l-.247.287c-1.003 1.138-2.748 2.903-5.236 5.294-.745.717-1.9.742-2.673.074l-.081-.074-1.05-1.014c-2.135-2.077-3.613-3.6-4.433-4.566A9 9 0 0 1 3 10.773C3 5.788 7.03 1.75 12 1.75Zm0 1.5c-4.145 0-7.5 3.37-7.5 7.52 0 1.806.636 3.512 1.779 4.861l.154.18c.558.642 1.394 1.522 2.504 2.634l.34.341c.583.58 1.233 1.217 1.95 1.912l.429.413a.495.495 0 0 0 .688 0l.815-.789c2.227-2.164 3.753-3.735 4.563-4.691a7.494 7.494 0 0 0 1.778-4.862c0-4.15-3.355-7.519-7.5-7.519ZM12 7a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 1.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z'
    />
  </svg>
);

export default EnvironmentIcon;
