import { SVGProps } from 'react';

interface LogOutIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const LogOutIcon = ({ width = 24, height = 24, color = '#DC3545', ...props }: LogOutIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      d='M17.708 12.76h-4.574a.75.75 0 0 1 0-1.5h4.554l-.566-.566a.75.75 0 0 1 1.061-1.061l1.856 1.856a.75.75 0 0 1 0 1.061l-1.856 1.856a.75.75 0 0 1-1.06-1.06l.585-.586Zm-2.324 2.99a.75.75 0 0 1 1.5 0v4.035a1.913 1.913 0 0 1-1.913 1.913h-9.3a1.913 1.913 0 0 1-1.912-1.913V4.234c0-1.056.857-1.913 1.912-1.913h9.3c1.056 0 1.913.857 1.913 1.913V8.25a.75.75 0 0 1-1.5 0V4.234a.413.413 0 0 0-.413-.413h-9.3a.413.413 0 0 0-.412.413v15.551c0 .228.185.413.412.413h9.3a.413.413 0 0 0 .413-.413v-4.034Z'
      clipRule='evenodd'
    />
  </svg>
);

export default LogOutIcon;
