import { Row, Statistic, Typography } from 'antd';
import { TypeFontWeight } from '../Heading/Heading ';
import { THEME_VARIABLE } from '@/config';

interface PriceWithUnitProps {
  value?: number;
  subTitle: string;
  fontSize: string;
  fontSizeSub: string;
  color?: string;
  tagStyle?: string;
  fontWeight?: TypeFontWeight;
  fontWeightSub?: TypeFontWeight;
  colorSubTitle?: string;
}

const { Text } = Typography;

const PriceWithUnit = ({
  value,
  subTitle,
  fontSize,
  fontSizeSub,
  color = THEME_VARIABLE.customTunaPrimary,
  tagStyle,
  fontWeight = 'font-bold',
  fontWeightSub = 'font-light',
  colorSubTitle = 'text-customGrayShade5',
}: PriceWithUnitProps) => {
  return (
    <Row className={`flex gap-x-[2px] items-center ${tagStyle} ${tagStyle}`}>
      {value && (
        <Statistic value={value} valueStyle={{ color, fontSize }} className={`${fontWeight}`} />
      )}
      <Text className={`${colorSubTitle} ${fontSizeSub} ${fontWeightSub}`}>{subTitle}</Text>
    </Row>
  );
};

export default PriceWithUnit;
