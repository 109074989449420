import { Dropdown, Flex, MenuProps, message, Typography } from 'antd';
import React from 'react';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { CheckOutline, DeleteIcon, EditOutline, MeatballMenu } from '../Icons';
import { THEME_VARIABLE } from '@/config';
import { useDeletePickupAddress, useUpdatePickupAddress } from '@/hooksApi/usePickupAddress';
import { useQueryClient } from '@tanstack/react-query';
import { PickupAddress } from '@/types';
import { usePickupAddressStore } from '@/stores';

const { Text } = Typography;

export interface StoreInfo {
  name: string;
  address: string;
  isDefault: boolean;
  id?: string;
}

interface PickupReturnProps {
  stores: PickupAddress[];
  menuItems?: MenuProps['items'];
  showMenu?: boolean; // New prop to control the display of the MeatballMenuIcon
  openModalEdit?: () => void;
}

const PickupsDropCard: React.FC<PickupReturnProps> = ({
  stores,
  menuItems,
  showMenu = true, // Default to true if not provided
  openModalEdit,
}) => {
  const { t } = useTranslation();
  const { setPickupAddresses } = usePickupAddressStore();

  const queryClient = useQueryClient();

  const { mutate: DeleteAddress, isLoading: loadingDeleteAddress } = useDeletePickupAddress();
  const { mutate: UpdateDefaultAddress, isLoading: loadingDefaultAddress } =
    useUpdatePickupAddress();

  const getMenuItems = (store: PickupAddress): MenuProps['items'] => [
    {
      key: '1',
      label: (
        <Heading
          title={t('set_as_default')}
          leadingIcon={<CheckOutline color={THEME_VARIABLE.customTunaPrimary} />}
          rotation={false}
          extraClassName='capitalize'
          loading={loadingDefaultAddress}
        />
      ),
      onClick: () => {
        UpdateDefaultAddress(
          { uid: store.uid, data: { default: true } },
          {
            onSuccess: () => {
              queryClient.refetchQueries(['pickupAddresses']);
            },
            onError(error) {
              message.error(error?.response?.data?.message || t('operation_failed'));
            },
          },
        );
      },
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <Heading
          title={t('edit')}
          leadingIcon={<EditOutline color={THEME_VARIABLE.customTunaPrimary} />}
          rotation={false}
          extraClassName='capitalize'
        />
      ),
      onClick: () => {
        setPickupAddresses?.(store);
        openModalEdit?.();
      },
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <Heading
          extraClassName='capitalize'
          title={t('delete')}
          leadingIcon={<DeleteIcon color={THEME_VARIABLE.customTunaPrimary} />}
          rotation={false}
          loading={loadingDeleteAddress}
        />
      ),
      onClick: () => {
        DeleteAddress(store?.uid, {
          onSuccess: () => {
            queryClient.refetchQueries(['pickupAddresses']);
          },
          onError(error) {
            message.error(error.message);
          },
        });
      },
    },
  ];

  return (
    <Flex vertical>
      {stores.map((store, index) => (
        <Card key={index} extraClassName='mt-4'>
          <Flex justify='space-between'>
            <Flex vertical>
              <Text className='font-normal text-lg leading-[19.2px] tracking-[-.32px] text-customTunaPrimary'>
                {store.title}
              </Text>
              <Text className='font-normal text-base leading-[19.2px] tracking-[-.32px] text-customTunaPrimary'>
                {store.address_text}
              </Text>
            </Flex>
            <Flex className='flex items-center gap-x-[26.5px]'>
              {+store.default === 1 && (
                <Flex className='gap-x-1'>
                  <Flex className='bg-customGreen w-[16px] h-[16px] flex justify-center items-center rounded-full'>
                    <CheckOutline color={THEME_VARIABLE.customWhite} width={10} height={10} />
                  </Flex>
                  <Text className='font-normal capitalize text-sm leading-[16.8px] tracking-[-0.32px] text-customGrayShade5'>
                    {t('default')}
                  </Text>
                </Flex>
              )}
              {showMenu && (
                <Dropdown
                  menu={{ items: menuItems || getMenuItems(store) }}
                  trigger={['click']}
                  rootClassName='bottomSheet'
                  placement='bottomLeft'
                >
                  <Flex className='cursor-pointer'>
                    <MeatballMenu width={20.48} height={20.48} />
                  </Flex>
                </Dropdown>
              )}
            </Flex>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};

export default PickupsDropCard;
