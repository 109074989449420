import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const EyeIcon = ({ width = 22, height = 17, color = '#38383E', ...props }: CloseIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      d='M11 .23c2.009 0 3.848.738 5.268 1.962l.179.157.198.177.211.194.343.322.374.36.405.397.288.286.616.62.498.51.712.738.768.804.404.427c.678.718.703 1.807.073 2.552l-.072.08-.791.835-.562.587-.701.724-.49.499-.605.607-.282.278-.397.386-.366.349-.335.31-.206.187-.192.17-.091.078a8.04 8.04 0 0 1-5.248 1.944 8.042 8.042 0 0 1-5.272-1.966l-.179-.156-.197-.177-.322-.297-.353-.335-.384-.372-.415-.41-.602-.603-.656-.67-.712-.737-.967-1.015-.204-.215a1.907 1.907 0 0 1-.072-2.553l.072-.081.978-1.029.45-.468.636-.656.398-.406.378-.381.456-.454.458-.449.283-.272.39-.367.24-.218.22-.197.103-.09A8.042 8.042 0 0 1 10.999.23Zm0 1.459c-1.588 0-3.087.57-4.279 1.6l-.263.231-.197.18-.322.3-.48.46-.396.39-.428.427-.46.465-.49.502-.523.54-.745.778-.577.609a.477.477 0 0 0 0 .658l.76.8.906.943.501.515.62.629.429.427.268.264.376.365.233.222.322.3.291.264.176.154a6.516 6.516 0 0 0 4.277 1.599 6.515 6.515 0 0 0 4.257-1.58l.171-.15.191-.17.206-.188.22-.205.233-.223.248-.239.399-.39.58-.58.473-.48.503-.517.72-.748.381-.4.579-.611a.477.477 0 0 0 0-.658l-.943-.992-.89-.924-.65-.664-.45-.454-.42-.415-.387-.378-.24-.231-.226-.214-.313-.29-.19-.17-.177-.155a6.516 6.516 0 0 0-4.275-1.596Zm0 2.312c2.418 0 4.379 2.014 4.379 4.5 0 2.485-1.96 4.5-4.379 4.5-2.418 0-4.378-2.015-4.378-4.5C6.622 6.015 8.582 4 11 4Zm.048 1.499v1.8l-.003.09A1.2 1.2 0 0 1 9.93 8.497l-.082.003h-1.8l.003.13a3 3 0 1 0 2.997-3.13Z'
    />
  </svg>
);
export default EyeIcon;
