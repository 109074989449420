import { useSentryQuery, useSentryMutation } from '../utils/sentryQueryWrapper';
import {
  fetchUser,
  loginUser,
  registerUser,
  updateUser,
  verifyOtpUser,
  uploadAvatar,
  updateCompanyProfile,
  uploadCompanyLogo,
  fetchCompany,
} from '@/api/userService';
import {
  RegisterResponse,
  RegisterParams,
  LoginParams,
  VerifyParams,
  AuthResponse,
  FetchUserResponse,
  UpdateProfileParams,
  FetchCompanyResponse,
  UpdateCompanyParams,
} from '@/types';
import { ErrorMessageResponse } from '@/types/error';

// Login user
export const useSetLogin = () => {
  return useSentryMutation<RegisterResponse, ErrorMessageResponse, LoginParams>(
    (data: LoginParams) => loginUser(data),
  );
};

// Register user
export const useSetRegister = () => {
  return useSentryMutation<RegisterResponse, ErrorMessageResponse, RegisterParams>(
    (data: RegisterParams) => registerUser(data),
  );
};

// Verify OTP
export const useVerifyOtp = () => {
  return useSentryMutation<AuthResponse, ErrorMessageResponse, VerifyParams>((data: VerifyParams) =>
    verifyOtpUser(data),
  );
};

// Fetch User
export const useFetchUser = () => {
  return useSentryQuery<FetchUserResponse, Error>(['user'], () => fetchUser());
};

// Update User
export const useUpdateUser = () => {
  return useSentryMutation<FetchUserResponse, ErrorMessageResponse, Partial<UpdateProfileParams>>(
    (data: Partial<UpdateProfileParams>) => updateUser(data),
  );
};

// Upload Avatar
export const useUploadAvatar = () => {
  return useSentryMutation<FetchUserResponse, ErrorMessageResponse, FormData>((data: FormData) =>
    uploadAvatar(data),
  );
};

// Fetch Company Info
export const useFetchCompany = () => {
  return useSentryQuery<FetchCompanyResponse, Error>(['company'], fetchCompany);
};

// Update Company Info
export const useUpdateCompany = () => {
  return useSentryMutation<FetchCompanyResponse, ErrorMessageResponse, UpdateCompanyParams>(
    (data: UpdateCompanyParams) => updateCompanyProfile(data),
  );
};

// Upload Company Logo
export const useUploadCompanyLogo = () => {
  return useSentryMutation<FetchCompanyResponse, ErrorMessageResponse, FormData>((data: FormData) =>
    uploadCompanyLogo(data),
  );
};
