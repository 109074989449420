import { THEME_VARIABLE } from '@/config';
import { Flex, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';
import { AddIcon } from '../Icons';
import PickupDropCard from './PickupsDropCard';
import { AddNewLocationModal } from '../AddNewLocationModal';
import { useState } from 'react';
import { useFetchPickupAddresses } from '@/hooksApi/usePickupAddress';
import { usePickupAddressStore } from '@/stores';
import { PickupTypeEnum } from '@/types';

const PickupReturn = () => {
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const { t } = useTranslation();

  const { data, isLoading } = useFetchPickupAddresses();
  const { clearPickupAddresses } = usePickupAddressStore();

  return (
    <Flex vertical>
      <Flex justify='space-between'>
        <Heading
          title={t('pick_up_return')}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <Flex
          onClick={() => {
            setAddNewLocationModalOpen((prev) => !prev);
            clearPickupAddresses();
          }}
          className='w-[44px] cursor-pointer  h-[44px] flex justify-center items-center rounded-[10px] border border-solid border-customTunaPrimary'
        >
          <AddIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
        </Flex>
      </Flex>
      {isLoading ? (
        <Spin />
      ) : (
        <PickupDropCard
          stores={
            data?.object?.items?.filter((item) => item.type === PickupTypeEnum.WAREHOUSE) || []
          }
          openModalEdit={() => setAddNewLocationModalOpen(true)}
        />
      )}
      <AddNewLocationModal
        visible={addNewLocationModalOpen}
        onClose={() => setAddNewLocationModalOpen(false)}
      />
    </Flex>
  );
};

export default PickupReturn;
