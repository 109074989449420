import { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={19} fill='none' {...props}>
    <path
      fill='#38383E'
      fillRule='evenodd'
      d='M15.495 6.009v10.053c0 1.209-.98 2.188-2.187 2.188h-10.6A2.187 2.187 0 0 1 .52 16.062V2.938C.52 1.729 1.5.75 2.708.75h7.534c.33 0 .649.132.883.366L13.13 3.12v.001l2 2.005c.234.234.365.551.365.883ZM8.938 2h-6.23a.938.938 0 0 0-.938.938v13.125c0 .517.42.937.938.937h10.6c.518 0 .937-.42.937-.938V6.687h-4.057c-.69 0-1.25-.56-1.25-1.25V2Zm-.305 9.116.808-.808a.626.626 0 0 1 .884.884L8.45 13.067a.625.625 0 0 1-.884 0L5.69 11.192a.625.625 0 0 1 .884-.884l.808.808V7.518a.625.625 0 0 1 1.25 0v3.598Zm5.043-5.679-1.43-1.433L10.24 2h-.053v3.437h3.487Z'
      clipRule='evenodd'
    />
  </svg>
);
export default DownloadIcon;
