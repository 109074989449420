import { Button, Flex, Modal, Typography, Form, message } from 'antd'; // Import message for notifications
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResolveOrderInfo from './ResolveOrderInfo';
import ResolveCustomerDetails from './ResolveCustomerDetails';
import ResolveFailedDelivery from './ResolveFailedDelivery';
import ResolveAddComment from './ResolveAddComment';
import { Heading } from '../Heading';
import { ResolveIcon } from '../Icons';
import { useResolveActionOrder, useRtoRequestOrder } from '@/hooksApi/useOrder';
import { OrderResolveAction, OrderRtoRequest } from '@/types';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  platformCode?: string;
  comment?: string;
  orderId?: string | number; // Accept orderId as a prop
}

const { Text } = Typography;

const ResolveModal: React.FC<ModalProps> = ({
  visible,
  onClose,
  orderId,
  platformCode,
  comment,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [submitAction, setSubmitAction] = useState<'resolve' | 'rto'>('resolve'); // Track which action was clicked

  // Hook for resolving the order
  const { mutate: resolveActionOrder, isLoading: resolving } = useResolveActionOrder();
  // Hook for requesting RTO
  const { mutate: requestRtoOrder, isLoading: requestingRto } = useRtoRequestOrder();

  // Handle form submission
  const handleFormSubmit = (values: { comment?: string }) => {
    console.log('Form Values:', values);

    if (submitAction === 'resolve') {
      if (!values.comment) {
        message.error(t('please_fill_your_comment')); // Show an error if comment is missing
        return;
      }

      const resolveAction: OrderResolveAction = {
        merchant_action_required_merchant_text: values.comment!, // Map comment to merchant_action_required_merchant_text
      };

      resolveActionOrder(
        { orderId: orderId?.toString() || '', resolveAction }, // Send resolveAction
        {
          onSuccess: () => {
            message.success(t('order_resolved_successfully')); // Success message
            onClose();
          },
          onError: () => {
            message.error(t('failed_resolve_order')); // Error message
          },
        },
      );
    } else if (submitAction === 'rto') {
      const rtoRequest: OrderRtoRequest = {
        merchant_action_required_merchant_text: values.comment || '', // Map comment to merchant_action_required_merchant_text (can be empty)
      };

      requestRtoOrder(
        { orderId: orderId?.toString() || '', rtoRequest }, // Send rtoRequest
        {
          onSuccess: () => {
            message.success(t('rto_request_successfully_submitted')); // Success message
            onClose();
          },
          onError: () => {
            message.error(t('rto_request_failed')); // Error message
          },
        },
      );
    }
  };

  return (
    <Modal
      className='rounded-[20px] bg-white border border-customGrayShade3'
      title={
        <Flex
          className='gap-x-1'
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '19px' }}
        >
          <Text className='font-bold text-lg text-customTunaPrimary leading-[20.88px] uppercase'>
            {t('resolve')} -
          </Text>
          <Text className='font-normal text-lg text-customTunaPrimary leading-[20.88px]'>
            {platformCode}
          </Text>
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={handleFormSubmit} // Use onFinish to handle form submit
      >
        <ResolveOrderInfo />
        <ResolveCustomerDetails />
        <ResolveFailedDelivery deliveryDescription={comment} />
        <ResolveAddComment failedReason={t('due_to_specific_reason_for_return')} />

        <Flex className='flex justify-end w-full gap-x-2'>
          <Button
            style={{
              paddingTop: '26px',
              paddingBottom: '26px',
            }}
            type='default'
            htmlType='button'
            onClick={() => {
              setSubmitAction('rto'); // Set action to 'rto'
              form.submit(); // Trigger form submit
            }}
            className='rounded-lg'
            loading={requestingRto} // Disable button during loading
          >
            <Heading
              title={t('request_rto')}
              fontWeight='font-bold'
              fontSize='text-sm'
              extraClassName='uppercase leading-[12.23px]'
            />
          </Button>
          <Button
            style={{
              paddingTop: '26px',
              paddingBottom: '26px',
            }}
            type='primary'
            htmlType='button'
            className='rounded-lg'
            onClick={() => {
              setSubmitAction('resolve'); // Set action to 'resolve'
              form.submit(); // Trigger form submit
            }}
            loading={resolving} // Disable button during loading
          >
            <Heading
              leadingIcon={<ResolveIcon width={18} height={18} />}
              title={t('resolved')}
              fontWeight='font-bold'
              fontSize='text-sm'
              extraClassName='uppercase leading-[12.23px]'
            />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ResolveModal;
