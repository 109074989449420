import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Flex, Layout, Row } from 'antd';
import TabBar, { TabItem } from '@/components/Tab/TabBar';
import { In_TRANSIT, LOCAL_AR, STYLE_ORDERS } from '@/config';
import { useTranslation } from 'react-i18next';
import { OrderStatusTab } from '@/types/columns.ts';
import useOrderData from '@/hooks/useOrderData.ts';
import { listStatusButtons } from '@/utils/getStatus.ts';
import FilterButton from '@/components/FilterButton/FilterButton.tsx';

const getTabItems = ({
  t,
  i18n,
  checkActive,
  setActiveButton,
  count,
}: {
  t: (key: string) => string;
  i18n: any;
  checkActive: (item: string) => 'primary' | 'default';
  setActiveButton: React.Dispatch<React.SetStateAction<string>>;
  count: number | undefined;
}): TabItem[] => [
  {
    key: OrderStatusTab.ReadyToShip,
    title: t('ready'),
  },
  {
    key: OrderStatusTab.InProgress,
    title: t('in_progress'),
    content: (
      <Flex className={STYLE_ORDERS}>
        <Button
          onClick={() => setActiveButton('all')}
          type={checkActive('all')}
          className={i18n.language === LOCAL_AR ? In_TRANSIT : ''}
        >
          {t('all')}
        </Button>
        {listStatusButtons[OrderStatusTab.InProgress]?.map((item) => (
          <FilterButton
            key={item.value}
            item={item}
            setActiveButton={setActiveButton}
            checkActive={checkActive}
          />
        ))}
      </Flex>
    ),
  },
  {
    key: OrderStatusTab.OnHold,
    title: t('on_hold'),
    content: (
      <Flex className={STYLE_ORDERS}>
        <Button
          onClick={() => setActiveButton('all')}
          type={checkActive('all')}
          className={i18n.language === LOCAL_AR ? In_TRANSIT : ''}
        >
          {t('all')}
        </Button>
        <Button
          onClick={() => setActiveButton('action_required')}
          type={checkActive('action_required')}
          className={i18n.language === LOCAL_AR ? In_TRANSIT : ''}
        >
          {t('action_required')}
        </Button>
      </Flex>
    ),
    badge: count || undefined,
  },
  {
    key: OrderStatusTab.RTO,
    title: t('rto'),
    content: (
      <Flex className={STYLE_ORDERS}>
        <Button
          onClick={() => setActiveButton('all')}
          type={checkActive('all')}
          className={i18n.language === LOCAL_AR ? In_TRANSIT : ''}
        >
          {t('all')}
        </Button>
        {listStatusButtons[OrderStatusTab.RTO]?.map((item) => (
          <FilterButton
            key={item.value}
            item={item}
            setActiveButton={setActiveButton}
            checkActive={checkActive}
          />
        ))}
      </Flex>
    ),
  },
  {
    key: OrderStatusTab.Finished,
    title: t('finished'),
    content: (
      <Flex className={STYLE_ORDERS}>
        <Button
          onClick={() => setActiveButton('all')}
          type={checkActive('all')}
          className={i18n.language === LOCAL_AR ? In_TRANSIT : ''}
        >
          {t('all')}
        </Button>
        {listStatusButtons[OrderStatusTab.Finished]?.map((item) => (
          <FilterButton
            key={item.value}
            item={item}
            setActiveButton={setActiveButton}
            checkActive={checkActive}
          />
        ))}
      </Flex>
    ),
  },
];

export default getTabItems;
