import { THEME_VARIABLE } from '@/config';
import { Col, Flex, Input, InputNumber, Row, Typography } from 'antd';
import React, { KeyboardEvent } from 'react';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { AddIcon, DeleteIcon, CheckOutline } from '../Icons';

const { Text } = Typography;

interface ProductPivot {
  created_at: string;
  updated_at: string;
}

interface Product {
  uid?: string;
  platform_code?: string;
  title?: string;
  price?: number | string;
  currency?: string;
  quantity?: number | string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  pivot?: ProductPivot;
}

interface ProductDetailsCardProps {
  title: string;
  placeholders: {
    title: string;
    quantity: string;
    price: string;
  };
  products: Product[];
  newProduct: Product;
  handleAddProduct: () => void;
  handleDeleteProduct: (index: number) => void;
  handleChangeNewProduct: (field: keyof Product, value: string) => void;
  handleChangeProduct: (index: number, field: keyof Product, value: string) => void;
}

const ProductDetailsCard: React.FC<ProductDetailsCardProps> = ({
  title,
  placeholders,
  products,
  newProduct,
  handleAddProduct,
  handleDeleteProduct,
  handleChangeNewProduct,
  handleChangeProduct,
}) => {
  const isProductValid = newProduct.title && newProduct.quantity && newProduct.price;

  const handleEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    console.log('handleEnterKey', e.key);

    if (e.key === 'Enter') handleAddProduct();
  };
  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' />

      {/* First Row with AddIcon and inputs */}
      <Flex vertical>
        <Text className='font-normal text-base text-customGrayShade5'>{`Product #${products.length + 1}`}</Text>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={24} xs={24}>
            <Input
              placeholder={placeholders.title}
              value={newProduct.title}
              onChange={(e) => handleChangeNewProduct('title', e.target.value)}
              onKeyDown={handleEnterKey}
              className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
            />
          </Col>
          <Col lg={3} md={24} xs={24}>
            <InputNumber
              min={'1'} // Ensure non-negative values
              style={{ direction: 'ltr' }}
              placeholder={placeholders.quantity}
              value={newProduct.quantity}
              className='border relative custom-input-number flex items-center w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
              onChange={(value) => handleChangeNewProduct('quantity', value?.toString() || '')} // Convert number to string
              onKeyDown={handleEnterKey}
            />
          </Col>
          <Col lg={6} md={24} xs={24}>
            <InputNumber
              min={'1'} // Ensure non-negative values
              style={{ direction: 'ltr' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // Format with thousand separator
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''} // Parse value back without commas
              placeholder={placeholders.price}
              value={newProduct.price}
              onChange={(value) => handleChangeNewProduct('price', value?.toString() || '')} // Convert number to string
              onKeyDown={handleEnterKey}
              className='border relative custom-input-number flex items-center w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
            />
          </Col>
          <Col lg={3} md={24} xs={24}>
            <Flex
              className='border cursor-pointer rounded-lg border-solid flex justify-center items-center h-[56px] w-[56px]'
              onClick={handleAddProduct}
              style={{
                backgroundColor: isProductValid
                  ? THEME_VARIABLE.customYellowPrimary
                  : THEME_VARIABLE.customWhite, // Yellow if valid, otherwise default
                borderColor: isProductValid
                  ? THEME_VARIABLE.customYellowPrimary
                  : THEME_VARIABLE.GrayShade5, // Yellow border if valid, otherwise default
              }}
            >
              {isProductValid ? (
                <CheckOutline color={THEME_VARIABLE.customBlack} width={24} height={24} />
              ) : (
                <AddIcon color={THEME_VARIABLE.customTunaPrimary} />
              )}
            </Flex>
          </Col>
        </Row>
      </Flex>

      {/* Generated Rows with filled inputs */}
      {products.map((product: Product, index) => (
        <Flex vertical key={index}>
          <Text className='font-normal text-base text-customGrayShade5'>{`Product #${index + 1}`}</Text>
          <Row gutter={[16, 16]}>
            <Col lg={12} md={24} xs={24}>
              <Input
                style={{ direction: 'ltr' }}
                placeholder={placeholders.title}
                value={product.title}
                onChange={(e) => handleChangeProduct(index, 'title', e.target.value)}
                className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
              />
            </Col>
            <Col lg={3} md={24} xs={24}>
              <InputNumber
                min={'1'} // Ensure non-negative values
                placeholder={placeholders.quantity}
                value={product.quantity}
                onChange={(value) =>
                  handleChangeProduct(index, 'quantity', value?.toString() || '')
                }
                className='border  flex items-center relative w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
              />
            </Col>
            <Col lg={6} md={24} xs={24}>
              <InputNumber
                min={'1'} // Ensure non-negative values
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // Format with thousand separator
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''} // Parse value back without commas
                style={{ direction: 'ltr' }}
                placeholder={placeholders.price}
                value={product.price}
                onChange={(value) => handleChangeProduct(index, 'price', value?.toString() || '')}
                className='border relative flex items-center w-full border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
              />
            </Col>
            <Col lg={3} md={24} xs={24}>
              <Flex
                className='border cursor-pointer rounded-lg border-solid flex justify-center items-center border-customGrayShade5 h-[56px] w-[56px]'
                onClick={() => handleDeleteProduct(index)}
              >
                <DeleteIcon color={THEME_VARIABLE.customTunaPrimary} />
              </Flex>
            </Col>
          </Row>
        </Flex>
      ))}
    </Card>
  );
};

export default ProductDetailsCard;
