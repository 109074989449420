import * as React from 'react';
import { SVGProps } from 'react';

interface CustomSvgProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CloseCircle: React.FC<CustomSvgProps> = ({
  width = 21, // default width
  height = 21, // default height
  color = '#38383E', // default color
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 21 21' // added viewBox for scalability
    fill='none'
    {...props}
  >
    <path
      fill={color} // use color prop here
      d='M10.853.85a9.175 9.175 0 1 1 0 18.35 9.175 9.175 0 0 1 0-18.35Zm0 1.251a7.924 7.924 0 1 0 0 15.848 7.924 7.924 0 0 0 0-15.848ZM8.507 6.738l2.345 2.345 2.346-2.345a.167.167 0 0 1 .118-.05h1.298a.167.167 0 0 1 .118.285l-2.995 2.995 3.109 3.108a.167.167 0 0 1-.118.285H13.43a.167.167 0 0 1-.118-.049l-2.46-2.46-2.46 2.46a.167.167 0 0 1-.117.05H6.977a.167.167 0 0 1-.118-.286l3.109-3.108-2.995-2.995a.167.167 0 0 1 .118-.284h1.298c.044 0 .086.017.118.049Z'
    />
  </svg>
);

export default CloseCircle;
