import { Statuses, THEME_VARIABLE } from '@/config';
import { OrderStatusTab } from '@/types';

export const statusData: { [key in OrderStatusTab]: string[] } = {
  [OrderStatusTab.ReadyToShip]: ['new'],
  [OrderStatusTab.InProgress]: [
    'scheduled',
    'collecting',
    'out-for-collecting',
    'collected',
    'sorted',
    'transferred-carrier',
    'in-transit',
    'out-for-delivery',
    'postponed',
    'en-route',
    'out-for-collection',
    'received-warehouse',
    'received-carrier-warehouse',
  ],
  [OrderStatusTab.RTO]: [
    'rto-received-warehouse',
    'rto-in-transit',
    'rto-requested',
    'rto-scheduled',
  ],
  [OrderStatusTab.Finished]: [
    'delivered',
    'rto-partially-delivered',
    'rto-delivered',
    'partially-delivered',
  ],
  [OrderStatusTab.OnHold]: ['on-hold'],
};

function getColorData() {
  return {
    [OrderStatusTab.ReadyToShip]: THEME_VARIABLE.customDarkBlue,
    [OrderStatusTab.InProgress]: THEME_VARIABLE.customBlue,
    [OrderStatusTab.OnHold]: THEME_VARIABLE.customOrange,
    [OrderStatusTab.RTO]: THEME_VARIABLE.customDarkBlue,
    [OrderStatusTab.Finished]: THEME_VARIABLE.customGreen,
  };
}

export function getStatusKeyAndColor(status: string) {
  const colorData = getColorData();

  for (const key in statusData) {
    if (statusData[key as OrderStatusTab].includes(status)) {
      return {
        key: key as OrderStatusTab,
        color: colorData[key as OrderStatusTab],
        tag: getStatusTag(key as OrderStatusTab),
      };
    }
  }

  return { key: null, color: null, tag: null };
}

export function getStatusTag(statusTab: OrderStatusTab): string {
  switch (statusTab) {
    case OrderStatusTab.ReadyToShip:
      return 'purple';
    case OrderStatusTab.InProgress:
      return 'processing';
    case OrderStatusTab.OnHold:
      return 'warning';
    case OrderStatusTab.RTO:
      return 'purple';
    case OrderStatusTab.Finished:
      return 'success';
    default:
      return 'unknown Status';
  }
}

export function getStatusActivePickup(active: string): number {
  switch (active) {
    case 'new':
      return -1;
    case 'scheduled':
      return 0;
    case 'collecting':
      return 1;
    case 'out-for-collection':
      return 1;
    case 'out-for-collecting':
      return 1;
    case 'collected':
      return 2;
    default:
      return 0;
  }
}

export function getStatusActiveOrderRTO(active: string): number {
  switch (active) {
    case 'rto-scheduled':
      return 0;
    case 'rto-in-transit':
      return 1;
    case 'rto-delivered':
      return 2;
    default:
      return -1;
  }
}

export function mapOrderStatusToKey(status: string): keyof typeof Statuses | null {
  const statusInfo = getStatusKeyAndColor(status);

  if (!statusInfo.key) {
    return null;
  }

  switch (statusInfo.key) {
    case OrderStatusTab.ReadyToShip:
      return 'CREATED';
    case OrderStatusTab.InProgress:
      return 'IN_PROGRESS';
    case OrderStatusTab.OnHold:
      return 'ON_HOLD';
    case OrderStatusTab.Finished:
      return 'DELIVERED';
    default:
      return 'CREATED';
  }
}

export function mapOrderStatusToValue(status: string): string | null {
  const statusInfo = getStatusKeyAndColor(status);

  if (!statusInfo.key) {
    return null;
  }

  switch (statusInfo.key) {
    case OrderStatusTab.ReadyToShip:
      return 'created';
    case OrderStatusTab.InProgress:
      return 'inProgress';
    case OrderStatusTab.OnHold:
      return 'onHold';
    case OrderStatusTab.Finished:
      return 'delivered';
    default:
      return 'created';
  }
}

export interface listItem {
  title: string;
  value: string;
}

export const listStatusButtons: { [key in OrderStatusTab]: listItem[] } = {
  [OrderStatusTab.ReadyToShip]: [{ title: 'new', value: 'new' }],
  [OrderStatusTab.InProgress]: [
    { title: 'scheduled', value: 'scheduled' },
    { title: 'collecting', value: 'collecting' },
    { title: 'collected', value: 'collected' },
    { title: 'en-route', value: 'en-route' },
    { title: 'out-for-delivery', value: 'out-for-delivery' },
    { title: 'postponed', value: 'postponed' },
  ],
  [OrderStatusTab.RTO]: [
    { title: 'returned-warehouse', value: 'rto-received-warehouse' },
    { title: 'returning-origin', value: 'rto-in-transit' },
    { title: 'rto-requested', value: 'rto-requested' },
    { title: 'rto-scheduled', value: 'rto-scheduled' },
  ],
  [OrderStatusTab.Finished]: [
    { title: 'delivered', value: 'delivered' },
    { title: 'partially-returned', value: 'rto-partially-delivered' },
    { title: 'returned', value: 'rto-delivered' },
    // { title: 'cancelled', value: 'cancelled' },
    { title: 'partially-delivered', value: 'partially-delivered' },
  ],
  [OrderStatusTab.OnHold]: [{ title: 'on-hold', value: 'on-hold' }],
};

export enum TypeTransition {
  deposit = 'deposit',
  withdraw = 'withdraw',
}
