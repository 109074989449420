import * as React from 'react';
import { SVGProps } from 'react';

interface WarningModalIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const WarningModalIcon = ({
  width = 80,
  height = 80,
  color = '#38383E',
  ...props
}: WarningModalIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect width={width} height={height} x={0.5} fill='#F39F54' rx={40} />
    <path
      fill='#fff'
      d='M41.448 27.342c.404.231.739.567.969.973l11.409 20.126a2.61 2.61 0 0 1-.968 3.55 2.58 2.58 0 0 1-1.283.342H28.756a2.597 2.597 0 0 1-2.59-2.603c0-.452.117-.896.34-1.29l11.409-20.125a2.583 2.583 0 0 1 3.533-.973Zm-1.803 1.922-.041.063-11.4 20.037a.648.648 0 0 0 .487.965l.076.004h22.798a.647.647 0 0 0 .596-.9l-.033-.069-11.4-20.037a.647.647 0 0 0-1.083-.063Zm1.498 16.67V47.4c0 .147-.12.267-.267.267h-1.42a.267.267 0 0 1-.267-.267v-1.467c0-.147.12-.266.267-.266h1.42c.147 0 .267.12.267.266Zm0-9.334v6.8c0 .147-.12.267-.267.267h-1.42a.267.267 0 0 1-.267-.267v-6.8c0-.147.12-.267.267-.267h1.42c.147 0 .267.12.267.267Z'
    />
  </svg>
);
export default WarningModalIcon;
