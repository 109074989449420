import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';
import { Col, Divider, Flex, Row } from 'antd';
import { PriceWithUnit } from '../Price';

interface PaymentStatusProps {
  headerTitle: string;
  titleFirst: string;
  valueFirst: number;
  titleSecond?: string;
  valueSecond?: number;
  total: number;
  hideShipment?: boolean;
}

const PaymentStatus = ({
  headerTitle,
  titleFirst,
  titleSecond,
  total,
  valueFirst,
  valueSecond,
  hideShipment = false,
}: PaymentStatusProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        title={headerTitle}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='mb-6'
      />
      <Flex
        vertical
        className='border-dashed border-[1px] border-customGrayShade4 py-3 px-4 rounded-[10px]'
      >
        <Row className='mb-2'>
          <Col span={12}>
            <Heading title={titleFirst} fontSize='text-sm' />
          </Col>
          <Col span={12} className='text-right flex justify-end'>
            <PriceWithUnit
              value={valueFirst}
              subTitle={t('iqd')}
              fontSize='14px'
              fontSizeSub='text-sm'
              fontWeight='font-bold'
              tagStyle='bg-white'
              fontWeightSub='font-medium'
              colorSubTitle='text-customTunaPrimary'
            />
          </Col>
        </Row>
        {titleSecond && !hideShipment && (
          <Row>
            <Col span={12}>
              <Heading title={titleSecond} fontSize='text-sm' />
            </Col>
            <Col span={12} className='text-right flex justify-end'>
              <PriceWithUnit
                value={valueSecond}
                subTitle={t('iqd')}
                fontSize='14px'
                fontSizeSub='text-sm'
                fontWeight='font-bold'
                tagStyle='bg-white'
                fontWeightSub='font-medium'
                colorSubTitle='text-customTunaPrimary'
              />
            </Col>
          </Row>
        )}
        <Divider className='my-2' />
        <Row className='font-bold'>
          <Col span={12}>
            <Heading title={t('total')} fontSize='text-base' fontWeight='font-bold' />
          </Col>
          <Col span={12} className='text-right flex justify-end'>
            <PriceWithUnit
              value={total}
              subTitle={t('iqd')}
              fontSize='16px'
              fontSizeSub='text-sm'
              fontWeight='font-bold'
              tagStyle='bg-white'
              fontWeightSub='font-medium'
              colorSubTitle='text-customTunaPrimary'
            />
          </Col>
        </Row>
      </Flex>
    </>
  );
};

export default PaymentStatus;
