import React from 'react';
import { countries } from '@/utils/CountryCodes';
import { Col, Flex, Form, Image, Input, Row, Select } from 'antd';
import { Heading } from '../Heading';
import { usePickupAddressStore } from '@/stores';

const { Option } = Select;

interface AddCustomerDetailsProps {
  title: string;
  firstNamePlaceholder: string;
  primaryPhonePlaceholder: string;
  primaryPhone: string;
  setPrimaryPhone: (phone: string) => void;
}

const AddCustomerDetails: React.FC<AddCustomerDetailsProps> = ({
  title,
  firstNamePlaceholder,
  primaryPhonePlaceholder,
  primaryPhone,
  setPrimaryPhone,
}) => {
  const { pickupAddresses } = usePickupAddressStore();

  return (
    <Flex vertical className='mt-3'>
      <Heading
        title={title}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase leading-[20.48px]'
      />
      <Row gutter={[24, 16]} className='mt-3'>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='full_name' rules={[{ required: true, message: '' }]}>
            <Input
              variant='outlined'
              placeholder={`${firstNamePlaceholder}*`}
              className='custom-input border custom-select-placeholder border-solid border-customGrayShade4 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>

        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          {!pickupAddresses && (
            <Form.Item name='countryCodePrimary' rules={[{ required: true, message: '' }]}>
              <Select
                defaultValue={primaryPhone}
                variant='borderless'
                style={{ direction: 'ltr' }}
                className='border border-solid custom-select-item custom-select-placeholder border-customGrayShade4 h-[56px] rounded-[10px]'
                onChange={(value) => setPrimaryPhone(value)}
                dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
              >
                {countries.map((country, index) => (
                  <Option key={index} value={country.dialCodes?.[0]}>
                    <span role='img'>
                      <Image preview={false} src={country.image} width={20} height={20} />
                    </span>{' '}
                    ({country.dialCodes?.[0]})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item className='w-full' name='phone' rules={[{ required: true, message: '' }]}>
            <Input
              style={{ direction: 'ltr' }}
              placeholder={`${primaryPhonePlaceholder}*`}
              className='border  relative border-customGrayShade4 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
            />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  );
};

export default AddCustomerDetails;
