import { Flex, Typography } from 'antd';
import { Card } from '../Card';
import { CheckOutline } from '../Icons';

const { Text } = Typography;

interface LastUpdatedCardProps {
  lastUpdateText: string;
  statusIcon?: React.ReactNode;
  statusText: string;
  descriptionText: string;
  dateText: string;
}

const LastUpdatedCard = ({
  lastUpdateText,
  statusIcon = <CheckOutline />,
  statusText,
  descriptionText,
  dateText,
}: LastUpdatedCardProps) => {
  return (
    <Card>
      <Flex vertical gap={7}>
        <Text className=' font-normal capitalize text-sm leading-[18.2px] text-customGrayShade5'>
          {lastUpdateText}
        </Text>
        <Flex gap={5}>
          {statusIcon}
          <Text className='font-bold capitalize  text-base leading-3 tracking-[-.32px] text-customTunaPrimary'>
            {statusText}
          </Text>
        </Flex>
        <Text className='  font-normal text-sm leading-[18px] text-customTunaPrimary'>
          {descriptionText}
        </Text>
        <Text className='  font-normal text-sm leading-[18.2px] text-customGrayShade5'>
          {dateText}
        </Text>
      </Flex>
    </Card>
  );
};

export default LastUpdatedCard;
