import { SVGProps } from 'react';

interface CashIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const DotIcon = ({ width = 4, height = 5, color = '#2CCCFF', ...props }: CashIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <circle cx={2} cy={2.5} r={2} fill={color} />
  </svg>
);
export default DotIcon;
