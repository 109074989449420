import { axiosJSONInstance } from '@/config';
import {
  PickupAddressResponse,
  PickupFetchAddressResponse,
  PickupLastCodResponse,
  PickupRequest,
  SinglePickupAddressResponse,
} from '@/types'; // Ensure this path is correct based on your folder structure

// Fetch all pickup addresses
export const fetchPickupAddresses = async (page?: number): Promise<PickupFetchAddressResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/pick-up-addresses?page=${page}`);
  return response.data;
};

// Fetch a pickup address by UID
export const fetchPickupAddressByUID = async (
  uid: string,
): Promise<SinglePickupAddressResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/pick-up-addresses/${uid}`);
  return response.data;
};

// Fetch Last Cod Pickup Address
export const fetchPickupLastCod = async (): Promise<PickupLastCodResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/pick-up-addresses/last-cod-drop-of`);
  return response.data;
};

// Create new pickup address
export const createPickupAddress = async (data: PickupRequest): Promise<PickupAddressResponse> => {
  const response = await axiosJSONInstance.post('/merchants/pick-up-addresses', data);
  return response.data;
};

// Update pickup address
export const updatePickupAddress = async (
  data: PickupRequest,
  uid: string,
): Promise<PickupAddressResponse> => {
  const response = await axiosJSONInstance.patch(`/merchants/pick-up-addresses/${uid}`, data);
  return response.data;
};

// Delete pickup address by UID
export const deletePickupAddress = async (uid: string): Promise<void> => {
  await axiosJSONInstance.delete(`/merchants/pick-up-addresses/${uid}`);
};
