import { SVGProps } from 'react';

interface RedCheckIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const RedCheckIcon = ({
  width = 24,
  height = 25,
  color = '#DC3545',
  ...props
}: RedCheckIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect width={24} height={24} y={0.5} fill={color} rx={12} />
    <path
      fill='#fff'
      d='m8.348 8.168 3.7 3.737 3.7-3.737a.109.109 0 0 1 .078-.032h.839a.11.11 0 0 1 .077.186l-4.121 4.162 4.152 4.193a.11.11 0 0 1-.077.186h-.839a.11.11 0 0 1-.077-.032l-3.732-3.769-3.732 3.769a.11.11 0 0 1-.077.032H7.4a.11.11 0 0 1-.078-.186l4.152-4.193-4.12-4.162a.11.11 0 0 1 .077-.186h.838a.11.11 0 0 1 .078.032Z'
    />
  </svg>
);
export default RedCheckIcon;
