import VectorIcon from './VectorIcon';

const WarningIcon = () => {
  return (
    <div className='w-8 h-8 rounded-full flex justify-center items-center bg-customOrange'>
      <VectorIcon />
    </div>
  );
};

export default WarningIcon;
