import { SVGProps } from 'react';

interface BoxMediumIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BoxMediumIcon = ({
  width = 56,
  height = 56,
  color = '#939496',
  ...props
}: BoxMediumIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 56 56' // Ensures proper scaling based on the provided width and height
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill={color}
        fillRule='evenodd'
        d='M3.513 21.67a.875.875 0 0 1 .482-.996L27.549 9.708a.876.876 0 0 1 .74 0l13.986 6.52.087.038.048.024 9.453 4.405a.876.876 0 0 1 .484.987.923.923 0 0 1 .022.194V40.3c0 .34-.198.65-.506.794L28.308 52.061a.875.875 0 0 1-.739-.001L3.994 41.073a.876.876 0 0 1-.505-.793V21.875a.9.9 0 0 1 .024-.204Zm29.87-7.658-5.464-2.547L6.436 21.468l5.816 2.711.057-.03 21.074-10.137ZM21.295 28.394l6.644 3.096L49.42 21.487l-7.408-3.452-20.718 10.359Zm-2.025-.944L39.966 17.1l-4.62-2.092L14.3 25.133l4.97 2.317Zm-5.706-.73v5.672l1.86-.748a.875.875 0 0 1 .975.224l1.977 2.178v-5.082l-4.812-2.243Zm13.562 6.322-7-3.262v6.532a.876.876 0 0 1-1.522.589l-3.091-3.406-2.498 1.004a.875.875 0 0 1-1.201-.812v-7.781L5.239 22.84v16.882l21.887 10.2v-16.88Zm1.75 16.824 21.742-10.123V22.86L28.876 32.984v16.882Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h56v56H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default BoxMediumIcon;
