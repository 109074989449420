import React from 'react';
import { Tabs, Badge, Row, Layout, Typography } from 'antd';
import 'tailwindcss/tailwind.css';
import { THEME_VARIABLE } from '@/config';

const { TabPane } = Tabs;

export interface TabItem {
  key: string;
  title: string;
  content?: React.ReactNode;
  badge?: number;
}

interface TabBarProps {
  tabItems: TabItem[];
  activeTab: string;
  setActiveTab: (value: string) => void;
  handleChange?: () => void;
}

const { Text } = Typography;

const TabBar: React.FC<TabBarProps> = ({ tabItems, setActiveTab, activeTab, handleChange }) => {
  return (
    <Layout className='bg-white rounded-lg shadow-card-dark overflow-hidden'>
      <Tabs
        activeKey={activeTab}
        onChange={(activeKey: string) => {
          setActiveTab(activeKey);
          handleChange?.();
        }}
        className='flex flex-col items-start'
        tabBarStyle={{ margin: 0, width: '100%' }}
      >
        {tabItems.map((tab) => (
          <TabPane
            tab={
              <Row className='text-lg font-medium mx-4 items-center'>
                <Text
                  className={`text-[16px] ${activeTab === tab.key ? 'font-medium text-customTunaPrimary' : 'font-medium text-customGrayShade5'}`}
                >
                  {tab.title}
                </Text>
                {tab.badge && (
                  <Badge
                    count={tab.badge}
                    className='ml-2'
                    style={{
                      backgroundColor: THEME_VARIABLE.customGrayShade2,
                      color: THEME_VARIABLE.customGrayShade5,
                      borderRadius: '6px',
                      paddingBottom: '2px',
                      paddingRight: '2px',
                    }}
                  />
                )}
              </Row>
            }
            key={tab.key}
          >
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </Layout>
  );
};

export default TabBar;
