import { DecodeSize } from '@/types';
import { countries } from './CountryCodes';

export function getDialCodeByCountryCode(countryCode: string): string | null {
  const country = countries.find((c) => c.dialCodes.includes(countryCode));
  if (country && country.dialCodes && country.dialCodes.length > 0) {
    return country.dialCodes[0].replace('+', '');
  }
  return null;
}

//HANDLE TO REMOVE NON-NUMERIC CHARACTERS:
export const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  event.target.value = numericValue; // Update input value to be numeric
};
export function totalPrices(numbers: number[]): number {
  return numbers.reduce((sum, number) => sum + number, 0);
}

export function convertedText(input: string): string {
  const words = input.split('_') || input.split('-');

  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizedWords.join(' ');
}

export function decodeSize(size: keyof typeof DecodeSize) {
  return DecodeSize[size] || 'Unknown size';
}
export const downloadFile = async (
  uid: string,
  fetchFunction: (uid: string) => Promise<Blob>,
  fileName: string,
) => {
  try {
    const fileBlob = await fetchFunction(uid);

    const url = window.URL.createObjectURL(fileBlob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error :', error);
  }
};

export function commaSeparatedObjectToArray(obj: any) {
  const titles = obj.product_title.split(',');
  const prices = obj.product_price.split(',').map(Number);
  const quantities = obj.product_quantity.split(',').map(Number);

  return titles.map((title: any, index: any) => ({
    title: title.trim(),
    price: prices[index],
    quantity: quantities[index],
  }));
}

// function arrayToCommaSeparatedObject(array:any) {
//   const productTitles = array.map(item:any => item.title).join(", ");
//   const productPrices = array.map(item => item.price).join(",");
//   const productQuantities = array.map(item => item.quantity).join(",");

//   return {
//     product_title: productTitles,
//     product_price: productPrices,
//     product_quantity: productQuantities,
//   };
// }
interface Product {
  uid?: string;
  platform_code?: string;
  title?: string;
  price?: number | string;
  currency?: string;
  quantity?: string | number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
}

interface CommaSeparatedObject {
  product_title: string;
  product_price: string;
  product_quantity: string;
}

export function arrayToCommaSeparatedObject(array: Product[]): CommaSeparatedObject {
  const productTitles = array.map((item) => item.title).join(', ');
  const productPrices = array.map((item) => item?.price?.toString()).join(',');
  const productQuantities = array.map((item) => item?.quantity?.toString()).join(',');

  return {
    product_title: productTitles,
    product_price: productPrices,
    product_quantity: productQuantities,
  };
}

export const convertArabicToEnglishNumbers = (input: any) => {
  if (!input) {
    return ''; // Return an empty string if input is undefined or null
  }

  const arabicToEnglishMap: { [key: string]: string } = {
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
  };

  return input
    .toString() // Convert input to string to handle numbers
    .split('')
    .map((char: string) => arabicToEnglishMap[char] || char) // Type char as string
    .join('');
};

export const separateCountryCodeFromPhone = (phone: string) => {
  let countryCode = '964'; // +964 as default

  if (phone.trim().startsWith('98')) countryCode = '98';

  phone = phone.replace(countryCode, '').trim();

  return { phone, countryCode: `+${countryCode}` };
};

export const attachCountryCodeToPhone = (phoneNumber: string, _countryCode: string) => {
  if (phoneNumber && phoneNumber !== '') {
    const countryCode = getDialCodeByCountryCode(_countryCode);

    // If the phone number starts with 98 or 964, don't append the country code
    if (!(phoneNumber.startsWith('98') || phoneNumber.startsWith('964'))) {
      return `${countryCode}${convertArabicToEnglishNumbers(phoneNumber)}`;
    }
  }
  return phoneNumber;
};
