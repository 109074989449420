import {
  Card,
  CompanyIcon,
  CustomSettingsCard,
  EnvironmentIcon,
  FreeOrders,
  HelpAndSupport,
  LogOutIcon,
  PersonalCompanyInfo,
  PickupDrop,
  ShippingCard,
  TeamIcon,
} from '@/components';
import { ROUTE, THEME_VARIABLE } from '@/config';
import { useUserStore } from '@/stores';
import { useAuthStore } from '@/stores/authStore';
import type { MenuProps } from 'antd';
import { Col, ConfigProvider, Flex, Menu, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const [selectedKey, setSelectedKey] = useState('profile');
  const clearToken = useAuthStore((state) => state.clearToken);
  const setUser = useUserStore((state) => state.setUser);
  const navigation = useNavigate();
  const { t } = useTranslation();

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedKey(e.key);
  };
  const renderContent = () => {
    switch (selectedKey) {
      case 'profile':
        return <PersonalCompanyInfo />;
      case 'locations':
        return <PickupDrop />;
      case 'teamMembers':
        return <Flex>TeamMembers</Flex>;
      default:
        return null;
    }
  };

  const handleLogOut = () => {
    clearToken?.();
    setUser(undefined);
    navigation(ROUTE.LOGIN);
  };

  return (
    <Row gutter={[24, 16]} className='mt-[34px]'>
      <Col lg={10} md={24} xs={24}>
        {/* V0 remove */}
        {/* <FreeOrders referText={t('refer_and_get_rewarded')} mgCode='MG-567' /> */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimaryBg: THEME_VARIABLE.customYellowPrimary,
            },
          }}
        >
          <Menu
            className='py-4 px-5 rounded-lg shadow-card-dark mt-2'
            mode='vertical'
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
          >
            <Menu.Item key='profile' className='h-[64px] '>
              <CustomSettingsCard
                IconLeft={
                  <CompanyIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                }
                title={t('profile')}
                description={t('manage_your_personal')}
                extraClassName='h-[64px] rounded-4'
                borderBottomStyle={`1px solid ${THEME_VARIABLE.customGrayShade3}`}
                leftIconBackgroundColor={
                  selectedKey === 'profile' ? 'bg-customYellowPrimary' : 'bg-customGrayShade2'
                }
                descriptionColor={
                  selectedKey === 'profile' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
                }
              />
            </Menu.Item>
            <Menu.Item key='locations' className='h-[64px]'>
              <CustomSettingsCard
                IconLeft={
                  <EnvironmentIcon
                    width={24}
                    height={24}
                    color={THEME_VARIABLE.customTunaPrimary}
                  />
                }
                title={t('locations')}
                description={t('manage_your_addresses')}
                extraClassName='h-[64px] '
                //borderBottomStyle={`1px solid ${THEME_VARIABLE.customGrayShade3}`}
                leftIconBackgroundColor={
                  selectedKey === 'locations' ? 'bg-customYellowPrimary' : 'bg-customGrayShade2'
                }
                descriptionColor={
                  selectedKey === 'locations' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
                }
              />
            </Menu.Item>
            {/* V0 remove */}
            {/* <Menu.Item key='teamMembers' className='h-[64px]'>
              <CustomSettingsCard
                IconLeft={
                  <TeamIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                }
                title={t('team_members')}
                description={t('manage_your_team_member')}
                extraClassName='h-[64px] '
                leftIconBackgroundColor={
                  selectedKey === 'teamMembers' ? 'bg-customYellowPrimary' : 'bg-customGrayShade2'
                }
                descriptionColor={
                  selectedKey === 'teamMembers' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
                }
              />
            </Menu.Item> */}
          </Menu>
        </ConfigProvider>
        {/* V0 remove */}
        {/* <ShippingCard /> */}
        <HelpAndSupport />
        <Card extraClassName='mt-6 px-12'>
          <Flex onClick={handleLogOut} className='cursor-pointer'>
            <CustomSettingsCard
              IconLeft={<LogOutIcon width={24} height={24} color={THEME_VARIABLE.customRed} />}
              title={t('log_out')}
              leftIconBackgroundColor='bg-customRedShade1'
            />
          </Flex>
        </Card>
      </Col>
      <Col lg={14} md={24} xs={24}>
        {renderContent()}
      </Col>
    </Row>
  );
};

export default Settings;
