import { SVGProps } from 'react';

interface EditOutlineProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const EditOutline = ({
  width = 20,
  height = 20,
  color = '#939496',
  ...props
}: EditOutlineProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M16.315 2.852a2.625 2.625 0 0 1 0 3.713L5.923 16.955a.438.438 0 0 1-.31.128H2.959a.875.875 0 0 1-.875-.875v-2.656c0-.116.046-.227.128-.31l10.392-10.39a2.625 2.625 0 0 1 3.712 0Zm1.008 12.981c.118 0 .177.056.177.167v.917c0 .11-.059.166-.177.166h-7.146c-.118 0-.177-.055-.177-.166V16c0-.111.059-.167.177-.167h7.146ZM11.738 5.571l-8.29 8.292a.175.175 0 0 0-.052.124v1.61c0 .096.078.175.175.175h1.608a.175.175 0 0 0 .124-.052l8.292-8.292-1.857-1.857Zm5.577 7.346c.123 0 .185.055.185.166V14c0 .111-.062.167-.185.167h-3.797c-.123 0-.185-.056-.185-.167v-.917c0-.11.062-.166.185-.166h3.797Zm-3.729-9.19-.056.053-.875.875 1.856 1.857.876-.875a1.313 1.313 0 0 0 .052-1.8l-.052-.057a1.313 1.313 0 0 0-1.8-.053Z'
    />
  </svg>
);

export default EditOutline;
