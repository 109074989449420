import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CheckOutline = ({ width = 16, height = 16, color = '#2BB45D', ...props }: CustomSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='m14.77 4.228-7.698 7.627a.501.501 0 0 1-.75-.052.49.49 0 0 1-.053-.045L1.23 6.764a.133.133 0 0 1 .095-.228h1.03c.035 0 .069.014.094.039l4.272 4.233 6.832-6.77A.133.133 0 0 1 13.646 4h1.03a.133.133 0 0 1 .094.228Z'
    />
  </svg>
);

export default CheckOutline;
