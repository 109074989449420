import React from 'react';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { Flex, Typography } from 'antd';

const { Text } = Typography;

//  Define the Props interface
interface PackageSizeOrderCardProps {
  title: string;
  size: string;
  description?: string;
  second_text?: string;
  fragile_text?: string;
}

//  Update the component to accept props
const PackageSizeOrderCard: React.FC<PackageSizeOrderCardProps> = ({
  title,
  size,
  description,
  second_text,
  fragile_text,
}) => {
  return (
    <Card>
      <Heading
        title={title} // Use the title prop
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Flex className='justify-between w-[50%]'>
        <Flex vertical className='mt-[17px]'>
          <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
            {size} {/* Use the size prop */}
          </Text>
          <Text className=' font-normal text-base leading-[20.48px] text-customTunaPrimary'>
            {description} {/* Use the description prop */}
          </Text>
        </Flex>
        {second_text && (
          <Flex vertical>
            <Heading
              title={second_text}
              fontSize='text-sm'
              fontWeight='font-normal'
              color='text-customGrayShade5'
            />
            <Heading title={fragile_text} fontSize='text-sm' fontWeight='font-normal' />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default PackageSizeOrderCard;
