import { Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';
import { LOCAL_EN } from '@/config';
import { useResolveModalStore } from '@/hooks';

const { Text } = Typography;

const ResolveOrderInfo = () => {
  const { t, i18n } = useTranslation();
  // Zustand store values
  const orderValue = useResolveModalStore((state) => state.order_value);

  return (
    <Flex vertical>
      <Heading
        title={t('order_info')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase'
      />
      <Row gutter={[24, 16]} className='mt-[17px]'>
        {/* <Col lg={8} md={24} xs={24}>
          <Flex vertical>
            <Text className=' font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {t('order_id_custom_id')}
            </Text>
            <Text className=' font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {platformCode}
            </Text>
          </Flex>
        </Col> */}
        <Col lg={8} md={24} xs={24}>
          <Flex vertical>
            <Text className=' capitalize font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {t('value')}
            </Text>
            <Flex
              className={`gap-x-1  ${i18n.language === LOCAL_EN ? 'justify-start' : 'justify-end'}`}
              style={{ direction: 'ltr' }}
            >
              <Text className=' font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
                {orderValue.toLocaleString()}
              </Text>
              <Text className=' uppercase font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
                {t('iqd')}
              </Text>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
};

export default ResolveOrderInfo;
