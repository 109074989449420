import { create } from 'zustand';
import { PickupAddress } from '@/types';

interface PickupAddressStore {
  pickupAddresses: PickupAddress | null;
  setPickupAddresses: (pickupAddresses: PickupAddress) => void;
  clearPickupAddresses: () => void;
}

const usePickupAddressStore = create<PickupAddressStore>((set) => ({
  pickupAddresses: null,
  setPickupAddresses: (pickupAddresses) => set({ pickupAddresses }),
  clearPickupAddresses: () => set({ pickupAddresses: null }),
}));

export default usePickupAddressStore;
