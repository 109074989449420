import CompanyInfo from './CompanyInfo';
import PersonalInfo from './PersonalInfo';

const PersonalCompanyInfo = () => {
  return (
    <>
      <PersonalInfo />
      <CompanyInfo />
    </>
  );
};

export default PersonalCompanyInfo;
