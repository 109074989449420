import { SVGProps } from 'react';

interface OrdersIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const ReciveReturnIcon = ({
  width = 10,
  height = 13,
  fillColor = '#38383E',
  ...props
}: OrdersIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M2.01 9.785h-.495A1.372 1.372 0 0 1 .144 8.414V1.44C.144.683.758.068 1.515.068h6.981c.757 0 1.372.615 1.372 1.372v6.974c0 .757-.615 1.371-1.372 1.371h-.494a3.001 3.001 0 0 1-2.996 3.159A3.001 3.001 0 0 1 2.01 9.785Zm5.763-1h.723a.372.372 0 0 0 .372-.371V1.44a.372.372 0 0 0-.372-.372h-6.98a.372.372 0 0 0-.372.372v6.974c0 .205.166.371.371.371h.723a3.003 3.003 0 0 1 5.535 0ZM4.004 3.65a.5.5 0 0 1-.703.711l-.9-.876a.5.5 0 0 1 0-.717l.9-.876a.5.5 0 0 1 .698.717l-.043.041h2.229a1.925 1.925 0 1 1 0 3.85H3.11a.5.5 0 0 1 0-1h3.075a.925.925 0 1 0 0-1.85h-2.18Zm1.002 8.294a2 2 0 0 0 0-4 2.001 2.001 0 0 0 0 4Zm-.5-2.114-.255.233a.5.5 0 0 1-.675-.737l1.092-1a.5.5 0 0 1 .675 0l1.093 1a.5.5 0 0 1-.676.737l-.254-.233v1.114a.5.5 0 0 1-1 0V9.83Z'
      clipRule='evenodd'
    />
  </svg>
);
export default ReciveReturnIcon;
