import { create } from 'zustand';

interface PickupDropState {
  totalPrice: number;
  setTotalProductsPrice: (value: number) => void;
}

const useEditModalTotalPriceStore = create<PickupDropState>((set) => ({
  totalPrice: 0,
  setTotalProductsPrice: (value) => set({ totalPrice: value }),
}));

export default useEditModalTotalPriceStore;
