import { useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { TableAction, FilterHeader } from '@/components';
import { useTranslation } from 'react-i18next';
import { getColumns } from './list.tsx';
import '@/styles/pickups.css';
import usePickupData from '@/hooks/usePickupData.ts';

const pageSize = 25;

const Pickups = () => {
  const { t } = useTranslation();

  // pagination
  const [current, setCurrent] = useState(1);

  // handle onChange pagination
  const handlePaginationChange = (page: number) => {
    setCurrent(page);
  };

  // fetch data
  const { dataPickup, totals, isLoading } = usePickupData(current, pageSize);

  return (
    <Layout className='overflow-hidden relative w-full h-fit -mt-[7px]'>
      <Row className='w-full'>
        <Col md={24} xs={24} className='mt-6 mb-16'>
          <TableAction
            columns={getColumns(t)}
            data={dataPickup}
            pageSize={pageSize}
            current={current}
            total={totals}
            customClassName='table-pickup'
            onPaginationChange={handlePaginationChange}
            loading={isLoading}
            //*V0 remove*/
            // header={
            //   <FilterHeader
            //     filterItems={[t('on_hold'), t('customer'), '4568795546']}
            //     includeSearchBox={false}
            //   />
            // }
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Pickups;
