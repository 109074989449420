import { SVGProps } from 'react';

const MeatballMenu = ({
  width = 22, // Default width
  height = 22, // Default height
  color = '#38383E', // Default fill color
  ...props
}: SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 22 22' // Use viewBox to scale the SVG
    fill='none'
    {...props}
  >
    <path
      fill={color} // Use the color prop for the fill color
      fillRule='evenodd'
      d='M21.418 11c0 5.75-4.668 10.418-10.418 10.418C5.25 21.418.58 16.75.58 11 .581 5.25 5.25.582 11 .582 16.75.582 21.418 5.25 21.418 11Zm-1.5 0c0-4.923-3.996-8.919-8.918-8.919C6.077 2.081 2.08 6.077 2.08 11c0 4.922 3.996 8.918 8.919 8.918 4.922 0 8.918-3.996 8.918-8.918ZM8 11a1.126 1.126 0 1 1-2.252-.002A1.126 1.126 0 0 1 8 11Zm4.125 0a1.126 1.126 0 1 1-2.252-.002 1.126 1.126 0 0 1 2.252.002Zm4.125 0a1.126 1.126 0 1 1-2.251-.001 1.126 1.126 0 0 1 2.25 0Z'
      clipRule='evenodd'
    />
  </svg>
);

export default MeatballMenu;
