import {
  ActivityLogResponse,
  ErrorMessageResponse,
  Order,
  OrderListResponse,
  OrderPriceResponse,
  OrderResolveAction,
  OrderResolveResponse,
  OrderRtoRequest,
  OrderRtoResponse,
  OrderSingleResponse,
  OrderSingleRtoResponse,
  OrdersResponse,
  OrderStatusTab,
  Product,
} from '@/types';
import { useSentryQuery, useSentryMutation } from '../utils/sentryQueryWrapper';
import {
  addProductToOrder,
  cancelOrder,
  createOrder,
  deleteOrder,
  deleteProductFromOrder,
  fetchListByStatusSlug,
  fetchListFinished,
  fetchListInprogress,
  fetchListOnHold,
  fetchListOrderReady,
  fetchListOrders,
  fetchOrderActivityLogByOrderUID,
  fetchListReturningOrigin,
  fetchListReturningWarehouse,
  fetchOrderByUID,
  fetchOrderCollecting,
  fetchOrderEnRoute,
  fetchOrderRTO,
  fetchOrderRTOById,
  fetchOrders,
  previewOrderPrice,
  resolveActionOrder,
  rtoRequestOrder,
  updateOrder,
  updateProductByUID,
} from '@/api/orderService';
import { ISearch } from '@/components/TableAction/FilterHeader';

// Fetch all orders
export const useOrders = () => {
  return useSentryQuery<OrdersResponse, Error>(['orders'], fetchOrders);
};

// Fetch a single order by UID
export const useOrderByUID = (uid: string) => {
  return useSentryQuery<OrderSingleResponse, Error>(['order', uid], () => fetchOrderByUID(uid), {
    enabled: !!uid,
  });
};

// Create a new order
export const useCreateOrder = () => {
  return useSentryMutation<OrdersResponse, ErrorMessageResponse, Order>((order: Order) =>
    createOrder(order),
  );
};

// Preview order price
export const usePreviewOrderPrice = () => {
  return useSentryMutation<OrderPriceResponse, ErrorMessageResponse, Order>((order: Order) =>
    previewOrderPrice(order),
  );
};

// Update an existing order
export const useUpdateOrder = (uid: string) => {
  return useSentryMutation<OrdersResponse, ErrorMessageResponse, Partial<Order>>(
    (orderUpdates: Partial<Order>) => updateOrder({ ...orderUpdates, uid }),
  );
};

// Delete an order by ID
export const useDeleteOrderById = () =>
  useSentryMutation<void, ErrorMessageResponse, string>((uid: string) => deleteOrder(uid));

// Cancel an order by UID
export const useCancelOrder = () =>
  useSentryMutation<OrdersResponse, ErrorMessageResponse, string>((uid: string) =>
    cancelOrder(uid),
  );

// Add a new product to an order
export const useAddProductToOrder = () => {
  return useSentryMutation<
    OrdersResponse,
    ErrorMessageResponse,
    { orderUID: string; product: Product }
  >(({ orderUID, product }: { orderUID: string; product: Product }) =>
    addProductToOrder(orderUID, product),
  );
};

// Delete a new product to an order
export const useDeleteProductFromOrder = () => {
  return useSentryMutation<
    OrdersResponse,
    ErrorMessageResponse,
    { orderUID: string; productUID: string }
  >(({ orderUID, productUID }: { orderUID: string; productUID: string }) =>
    deleteProductFromOrder(orderUID, productUID),
  );
};

// Update an existing product by its UID
export const useUpdateProductByUID = () => {
  return useSentryMutation<
    Product,
    ErrorMessageResponse,
    { orderUID: string; productUID: string; updatedProduct: Partial<Product> }
  >(
    ({
      orderUID,
      productUID,
      updatedProduct,
    }: {
      orderUID: string;
      productUID: string;
      updatedProduct: Partial<Product>;
    }) => updateProductByUID(orderUID, productUID, updatedProduct),
  );
};

// Fetch List Order ReadyToShip
export const useFetchListOrderReady = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    ['orders', page, search?.search, search?.searchBy],
    () => fetchListOrderReady(page, pageSize, search),
    { keepPreviousData: true, enabled },
  );
};

// Fetch List Order Inprogress
export const useFetchListInprogress = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`orders-${OrderStatusTab.InProgress}`, page, search?.search, search?.searchBy],
    () => fetchListInprogress(page, pageSize, search),
    { keepPreviousData: true, enabled },
  );
};

// Fetch List Order OnHold
export const useFetchListOnHold = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`orders-${OrderStatusTab.OnHold}`, page, search?.search, search?.searchBy],
    () => fetchListOnHold(page, pageSize, search),
    { keepPreviousData: true, enabled },
  );
};

// Fetch List Order Finished
export const useFetchListFinished = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`orders-${OrderStatusTab.Finished}`, page, search?.search, search?.searchBy],
    () => fetchListFinished(page, pageSize, search),
    { keepPreviousData: true, enabled },
  );
};

// Fetch List Order RTO
export const useFetchListRTO = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`orders-${OrderStatusTab.RTO}`, page, search?.search, search?.searchBy],
    () => fetchOrderRTO(page, pageSize, search),
    { keepPreviousData: true, enabled },
  );
};

// Fetch List Order All
export const useFetchListOrders = (page: number, pageSize?: number, search?: ISearch) => {
  return useSentryQuery<OrderListResponse, Error>(
    ['orders-list', page, search?.search, search?.searchBy],
    () => fetchListOrders(page, pageSize, search),
    {
      keepPreviousData: true,
      enabled: !!search?.search && !!search?.searchBy,
    },
  );
};

// Fetch List By Status Slug
export const useFetchListByStatusSlug = (
  slug: string,
  page: number,
  pageSize?: number,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, ErrorMessageResponse>(
    ['orders', slug, page, search?.search, search?.searchBy],
    () => fetchListByStatusSlug(slug, page, pageSize, search),
    {
      keepPreviousData: true,
      enabled: ![
        'all',
        'collecting',
        'en-route',
        'on-hold',
        'rto-in-transit',
        'rto-received-warehouse',
      ].includes(slug),
    },
  );
};

// Resolve order action
export const useResolveActionOrder = () => {
  return useSentryMutation<
    OrderResolveResponse,
    ErrorMessageResponse,
    { orderId: string; resolveAction: OrderResolveAction }
  >(({ orderId, resolveAction }: { orderId: string; resolveAction: OrderResolveAction }) =>
    resolveActionOrder(orderId, resolveAction),
  );
};

// Rto request Order
export const useRtoRequestOrder = () => {
  return useSentryMutation<
    OrderRtoResponse,
    ErrorMessageResponse,
    { orderId: string; rtoRequest: OrderRtoRequest }
  >(({ orderId, rtoRequest }: { orderId: string; rtoRequest: OrderRtoRequest }) =>
    rtoRequestOrder(orderId, rtoRequest),
  );
};

// Fetch RTO Order By Id
export const useFetchOrderRTOById = (uid: string) => {
  return useSentryQuery<OrderSingleRtoResponse, Error>(
    ['order-rto', uid],
    () => fetchOrderRTOById(uid),
    {
      enabled: !!uid,
    },
  );
};

// Fetch order collecting
export const useFetchOrderCollecting = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`order-${OrderStatusTab.InProgress}`, page, search?.search, search?.searchBy],
    () => fetchOrderCollecting(page, pageSize, search),
    { enabled },
  );
};

// Fetch order en-route
export const useFetchOrderEnRoute = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`order-${OrderStatusTab.InProgress}`, page, search?.search, search?.searchBy],
    () => fetchOrderEnRoute(page, pageSize, search),
    { enabled },
  );
};

// Fetch order ActivityLogs
export const useFetchOrderActivityLogByOrderUID = (uid: string) => {
  return useSentryQuery<ActivityLogResponse, Error>(
    [`order-${OrderStatusTab.InProgress}`, uid],
    () => fetchOrderActivityLogByOrderUID(uid),
    { enabled: !!uid },
  );
};

// Fetch List Order RTO returning-origin
export const useFetchReturningOrigin = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`order-${OrderStatusTab.RTO}-returnOrigin`, page, search?.search, search?.searchBy],
    () => fetchListReturningOrigin(page, pageSize, search),
    { enabled },
  );
};

// Fetch List Order RTO returning-warehouse
export const useFetchReturningWarehouse = (
  page: number,
  pageSize?: number,
  enabled?: boolean,
  search?: ISearch,
) => {
  return useSentryQuery<OrderListResponse, Error>(
    [`order-${OrderStatusTab.RTO}-returnWarehouse`, page, search?.search, search?.searchBy],
    () => fetchListReturningWarehouse(page, pageSize, search),
    { enabled },
  );
};
