import { SVGProps } from 'react';

interface SvgComponentProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const LocationOutlineIcon = ({
  width = 12,
  height = 12,
  color = '#000',
  ...props
}: SvgComponentProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 12 12'
    fill='none'
    {...props}
  >
    <path
      fill={color}
      fillOpacity={0.85}
      d='m6.765 1.582 3.641 7.97c.231.508.02 1.112-.47 1.351a.956.956 0 0 1-.853-.007L6.091 9.384a.478.478 0 0 0-.433 0l-2.991 1.512a.97.97 0 0 1-1.317-.464 1.043 1.043 0 0 1-.006-.88l3.641-7.97a.97.97 0 0 1 1.78 0Zm-.668.325a.242.242 0 0 0-.431-.026l-.014.026-3.64 7.97a.26.26 0 0 0 0 .22c.056.117.187.17.303.128l.027-.012 2.992-1.512a1.196 1.196 0 0 1 1.025-.028l.057.027 2.992 1.513a.239.239 0 0 0 .213.002.257.257 0 0 0 .128-.31l-.01-.028-3.642-7.97Z'
    />
  </svg>
);

export default LocationOutlineIcon;
