import { useSentryQuery, useSentryMutation } from '../utils/sentryQueryWrapper';
import {
  PickupResponse,
  PickUpParams,
  PickupDataResponse,
  ErrorMessageResponse,
  PickUpSingleResponse,
} from '@/types';
import { cancelPickupById, fetchPickups, fetchSinglePickupById, requestPickup } from '@/api';

// Fetch Pickups
export const useFetchPickups = (page: number, pageSize?: number) => {
  return useSentryQuery<PickupResponse, ErrorMessageResponse>(
    ['pickups', page, pageSize],
    () => fetchPickups(page, pageSize),
    {
      keepPreviousData: true,
    },
  );
};

// Fetch Single Pickup By ID
export const useFetchSinglePickupById = (uid: string, refetchInterval?: number) => {
  return useSentryQuery<PickUpSingleResponse, ErrorMessageResponse>(
    ['pickup', uid],
    () => fetchSinglePickupById(uid),
    {
      enabled: !!uid,
      refetchInterval,
      refetchIntervalInBackground: !!refetchInterval,
    },
  );
};

// Request Pickup
export const useRequestPickup = () => {
  return useSentryMutation<PickupDataResponse, ErrorMessageResponse, PickUpParams>((data) =>
    requestPickup(data),
  );
};

// Cancel Pickup By ID
export const useCancelPickupById = () => {
  return useSentryMutation<PickupResponse, ErrorMessageResponse, string>((id: string) =>
    cancelPickupById(id),
  );
};
