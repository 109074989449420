import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const SearchIcon = ({ width = 17, height = 17, color = '#38383E', ...props }: CloseIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      d='M2.268 2.23c2.913-2.862 7.636-2.862 10.549 0a7.234 7.234 0 0 1 .285 10.066l3.11 3.055c.066.065.067.17.002.236l-.002.002-.641.63a.167.167 0 0 1-.234 0L12.2 13.135c-2.93 2.305-7.216 2.123-9.931-.543a7.236 7.236 0 0 1 0-10.362Zm.879.863a6.03 6.03 0 0 0 0 8.635c2.427 2.385 6.363 2.385 8.79 0a6.03 6.03 0 0 0 0-8.635C9.51.71 5.575.71 3.148 3.093Z'
    />
  </svg>
);
export default SearchIcon;
