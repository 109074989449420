import React from 'react';
import { Col, Flex, Image, Row, Timeline, Typography } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import './OrderTracker.css';
import { LOCAL_EN, Statuses, StatusesType } from '@/config'; // Import the statuses
import { useTranslation } from 'react-i18next';
import { ActivityItem } from '@/types';
import dayjs from 'dayjs';
const { Text } = Typography;

interface ImageItem {
  imageTitle: string;
  imageUrl: string;
}

export interface TimelineItemProps extends ActivityItem {
  //TODO: TimelineItemProps should be removed
  day?: string;
  month?: string;
  time?: string;
  title: string;
  description?: string; // Make description optional
  images?: ImageItem[]; // Add images array
  status: StatusesType; // Use the imported status type
}

interface OrderTrackerProps {
  timelineItems: ActivityItem[];
}

const OrderTracker: React.FC<OrderTrackerProps> = ({ timelineItems }) => {
  const { t, i18n } = useTranslation();

  const getStatusClassName = (status: TimelineItemProps['status']) => {
    switch (status) {
      case Statuses.IN_PROGRESS:
        return ` ${i18n.language === LOCAL_EN ? 'custom-gray-circle-timeline-dot-rtl' : 'custom-gray-circle-timeline-dot-rtl'}`;
      case Statuses.DELIVERED:
        return ` ${i18n.language === LOCAL_EN ? 'custom-green-circle-timeline-dot-ltr' : 'custom-green-circle-timeline-dot-rtl'}`;
      case Statuses.ON_HOLD:
        return ` ${i18n.language === LOCAL_EN ? 'custom-yellow-circle-timeline-dot-ltr' : 'custom-yellow-circle-timeline-dot-rtl'}`;
      case Statuses.CREATED:
        return ` ${i18n.language === LOCAL_EN ? 'custom-square-timeline-dot-ltr' : 'custom-square-timeline-dot-rtl'}`;
      default:
        return '';
    }
  };

  return (
    <Row gutter={[24, 16]} className='mt-8'>
      <Col
        lg={24}
        md={24}
        xs={24}
        className={` ${i18n.language === LOCAL_EN ? 'timeline_container-ltr' : 'timeline_container-rtl'}`}
      >
        <Timeline mode='left'>
          {timelineItems.map((item, index) => {
            const label = (
              <Flex vertical gap={4} key={index}>
                <Flex gap={4}>
                  <Text className=' font-bold text-xs leading-3 tracking-[-0.32px] text-customDarkBrown'>
                    {dayjs(item.created_at).format('DD')}
                  </Text>
                  <Text className=' font-bold text-xs leading-3 tracking-[-0.32px] text-customGrayShade5'>
                    {dayjs(item.created_at).format('MMM')}
                  </Text>
                </Flex>
                <Text
                  className={`  ${i18n.language === LOCAL_EN ? 'text-left' : 'text-right'}  font-normal text-xs leading-[10px] tracking-[-0.32px] text-customGrayShade5`}
                >
                  {dayjs(item.created_at).format('hh:mm')}
                </Text>
              </Flex>
            );

            return (
              <Timeline.Item
                key={index}
                label={label}
                className={getStatusClassName(
                  index === 0 ? Statuses.DELIVERED : Statuses.IN_PROGRESS,
                )}
              >
                <Card>
                  <Heading
                    title={t(item.title)}
                    fontSize='text-base'
                    fontWeight='font-bold'
                    extraClassName='capitalize'
                  />
                  {item.description ? (
                    <Flex
                      vertical
                      className='bg-customGrayShade1 mt-3 rounded-[5px] border border-dashed border-customGrayShade4 px-[9px] py-2'
                    >
                      <Text className=' font-normal capitalize text-xs leading-4 text-customTunaPrimary'>
                        {item.description}
                      </Text>
                    </Flex>
                  ) : item.images ? (
                    <Row className='mt-3 rounded-[5px]   px-[9px] py-2'>
                      {item.images.map((image, idx) => (
                        <Col lg={6} md={12} xs={24} key={idx} className='mb-2'>
                          <Flex vertical>
                            <Text className=' font-normal capitalize text-xs leading-[18px] text-customGrayShade5'>
                              {image.imageTitle}
                            </Text>
                            <Image
                              width={60}
                              height={60}
                              src={image.imageUrl}
                              alt={image.imageTitle}
                              className='rounded-[5px] w-full'
                              style={{ maxWidth: '100%', marginBottom: '4px' }}
                            />
                          </Flex>
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                </Card>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Col>
    </Row>
  );
};

export default OrderTracker;
