import { Heading, TableAction, Toast } from '@/components';
import { IMPORT_FIRST_ROW, IMPORT_LABEL } from '@/config';
import { useFetchOrderExcelByUID } from '@/hooksApi/useImport';
import { CsvData, DecodeSize } from '@/types';
import { decodeSize } from '@/utils';
import { Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

export interface StepProps {
  id?: string | null;
  setRow?: (value: number) => void;
}

const Step2 = ({ id }: StepProps) => {
  const { t } = useTranslation();

  const { data: dataOrderExcel, isLoading: loadingOrderExcel } = useFetchOrderExcelByUID(
    id as string,
  );

  const RowFirstOrderExcel = dataOrderExcel?.object?.items?.[0];

  const columns: ColumnsType<CsvData> = [
    {
      title: t('you_csv'),
      dataIndex: IMPORT_LABEL,
      key: IMPORT_LABEL,
      render: (text) => <Heading title={text} fontSize='text-base' />,
    },
    {
      title: t('first_row'),
      dataIndex: IMPORT_FIRST_ROW,
      key: IMPORT_FIRST_ROW,
      render: (text) => <Heading title={text} fontSize='text-base' />,
    },
  ];

  const dataSourceCsv = [
    { key: '1', label: t('order_id'), value: RowFirstOrderExcel?.platform_code || '---' },
    { key: '2', label: t('city'), value: RowFirstOrderExcel?.region?.province || '---' },
    { key: '3', label: t('area'), value: RowFirstOrderExcel?.region?.area_name || '---' },
    { key: '4', label: t('address'), value: RowFirstOrderExcel?.address_text || '---' },
    {
      key: '5',
      label: t('package_size'),
      value: decodeSize(RowFirstOrderExcel?.size as keyof typeof DecodeSize) || 'Unknown size',
    },
  ];

  return (
    <Flex vertical gap={20}>
      <Toast type='info' title={t('info')} />
      <TableAction
        columns={columns}
        data={dataSourceCsv}
        pageSize={7}
        hidePagination
        loading={loadingOrderExcel}
      />
    </Flex>
  );
};

export default Step2;
