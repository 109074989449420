import { Col, Image, Layout, Button, Dropdown, Menu } from 'antd';
import { VerifyFormCard } from '@/components';
import boxyLogo from '/boxy-logo.svg';
import { useTranslation } from 'react-i18next';
import useVerify from '@/hooks/useVerify';
import { useLocation } from 'react-router-dom';
import useRequestOTP from '@/hooks/useRequestOtp';
import { LOCAL_AR, LOCAL_EN } from '@/config';
import { GlobalOutlined } from '@ant-design/icons';

const Verify = () => {
  const { t } = useTranslation();
  const { toggleDirection, isLoading } = useVerify();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;

  const { countdown, requestOTP } = useRequestOTP(phoneNumber);
  const languageMenu = (
    <Menu onClick={toggleDirection}>
      <Menu.Item key={LOCAL_EN}>English</Menu.Item>
      <Menu.Item key={LOCAL_AR}>العربية</Menu.Item>
    </Menu>
  );

  return (
    <Layout className='flex justify-center items-center min-h-screen bg-customYellowPrimary'>
      <Dropdown overlay={languageMenu} trigger={['click']} className='absolute top-6 right-6'>
        <Button icon={<GlobalOutlined style={{ fontSize: '26px' }} />} type='text'></Button>
      </Dropdown>
      <Col>
        <Col className='flex justify-center '>
          <Image
            width={'160px'}
            height={'74.1px'}
            src={boxyLogo}
            alt='Login Visual'
            preview={false}
          />
        </Col>

        <Col className='h-[80vh] mt-[53px]'>
          <VerifyFormCard
            // direction={direction}
            title={t('verify_phone')}
            description={t('enter_digit_code')}
            phoneNumber={phoneNumber ? `+${phoneNumber}` : ''}
            resendText={countdown > 0 ? `${t('resend')} (${countdown})` : t('resend')}
            countdown={countdown}
            onResendClick={requestOTP}
            timeUnit={t('timeUnit')}
            whatsappText={t('via_whatsApp')}
            submitText={t('submit')}
            isLoading={isLoading}
          />
        </Col>
      </Col>
    </Layout>
  );
};

export default Verify;
