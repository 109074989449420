import { ThemeConfig } from 'antd';

const theme = {
  YellowPrimary: '#EBDD4C',
  YellowPrimaryHover: '#D9CC3F',
  TunaPrimary: '#38383E',
  darkBrown: '#312011', //without  name in figma
  SemiBlue: '#2CCCFF', //without  name in figma
  LightBlue: '#E2F8FF', //without  name in figma
  DarkBlue: '#6116FF', //without  name in figma
  LightPurple: '#EBE2FF', //without  name in figma
  GrayShade1: '#FAFBFC',
  GrayShade2: '#F5F7FA',
  GrayShade3: '#F0F1F3',
  GrayShade4: '#E1E1E3',
  GrayShade5: '#939496',
  White: '#FFFFFF', //without  name in figma
  Blue: '#1677FF',
  BlueShade1: '#EEF5FF',
  Green: '#2BB45D',
  GreenShade1: '#CCF0D9',
  Black: '#000000', //without  name in figma
  Orange: '#F39F54',
  OrangeShade1: '#FDEEE0',
  Red: '#DC3545',
  RedShade1: '#FCDCDB',
  ValentineRed: '#F05652',
  YellowDark: '#7C7329',
  MuddyGreen: '#6DA544', //without  name in figma
  DarkRed: '#D80027', //without  name in figma,
  ColorsBrown: '#A2845E',
  Violet: '#A360E2',
};

// Added custom to all colors, call them this way: custom...
export const THEME_VARIABLE: ThemeConfig['token'] & Record<string, string> = {
  customYellowPrimary: theme.YellowPrimary,
  customTunaPrimary: theme.TunaPrimary,
  customDarkBrown: theme.darkBrown,
  customSemiBlue: theme.SemiBlue,
  customLightBlue: theme.LightBlue,
  customDarkBlue: theme.DarkBlue,
  customLightPurple: theme.LightPurple,
  customGrayShade1: theme.GrayShade1,
  customGrayShade2: theme.GrayShade2,
  customGrayShade3: theme.GrayShade3,
  customGrayShade4: theme.GrayShade4,
  customGrayShade5: theme.GrayShade5,
  customWhite: theme.White,
  customBlue: theme.Blue,
  customBlueShade1: theme.BlueShade1,
  customGreen: theme.Green,
  customGreenShade1: theme.GreenShade1,
  customBlack: theme.Black,
  customOrange: theme.Orange,
  customOrangeShade1: theme.OrangeShade1,
  customRed: theme.Red,
  customRedShade1: theme.RedShade1,
  customValentineRed: theme.ValentineRed,
  customYellowDark: theme.YellowDark,
  customMuddyGreen: theme.MuddyGreen,
  customDarkRed: theme.DarkRed,
  customColorsBrown: theme.ColorsBrown,
  customViolet: theme.Violet,
  colorPrimary: theme.YellowPrimary,
  colorTextBase: theme.TunaPrimary,
  fontSizeBase: '16px',

  //TABLE of DEFAULT THEME :
  // Colors
  // colorPrimary: '#1890ff', // Primary color
  colorPrimaryHover: theme.YellowPrimaryHover, // Primary color on hover
  colorPrimaryActive: theme.YellowPrimaryHover, // Primary color on active state
  colorPrimaryDisabled: '#d6e4ff', // Disabled primary color
  colorSuccess: '#52c41a', // Success color
  colorWarning: '#faad14', // Warning color
  colorError: '#f5222d', // Error color
  // colorTextBase: '#000000d9', // Base text color
  colorTextSecondary: '#00000073', // Secondary text color
  colorTextDisabled: '#00000040', // Disabled text color
  colorBorderBase: '#d9d9d9', // Base border color
  colorBorderSplit: '#f0f0f0', // Border color for split elements
  colorBackgroundBase: '#ffffff', // Base background color
  colorBackgroundContainer: '#fafafa', // Container background color

  // Typography
  // fontSizeBase: '14px', // Base font size
  fontSizeLg: '16px', // Large font size
  fontSizeSm: '12px', // Small font size
  // lineHeightBase: 1.5, // Base line height
  // lineHeightLg: 1.6, // Large line height
  // lineHeightSm: 1.4, // Small line height
  // fontWeightBase: 400, // Base font weight
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif', // Font family

  // Padding and Margins
  paddingBase: '16px', // Base padding
  paddingLg: '24px', // Large padding
  paddingSm: '12px', // Small padding
  marginBase: '16px', // Base margin
  marginLg: '24px', // Large margin
  marginSm: '12px', // Small margin

  // Borders
  borderRadiusBase: '4px', // Base border radius
  borderRadiusSm: '2px', // Small border radius
  borderWidthBase: '1px', // Base border width
  borderColorBase: theme.GrayShade5, // Base border color
  borderStyleBase: 'solid', // Base border style

  // Buttons
  buttonPaddingHorizontal: '16px', // Button horizontal padding
  buttonPaddingVertical: '8px', // Button vertical padding
  buttonBorderRadius: '4px', // Button border radius
  buttonHeightBase: '32px', // Base button height
  buttonHeightLg: '40px', // Large button height
  buttonHeightSm: '24px', // Small button height

  // Inputs
  inputPaddingHorizontal: '12px', // Input horizontal padding
  inputPaddingVertical: '8px', // Input vertical padding
  inputBorderRadius: '4px', // Input border radius
  inputHeightBase: '32px', // Base input height
  inputHeightLg: '40px', // Large input height
  inputHeightSm: '24px', // Small input height

  // Forms
  formItemMarginBottom: '24px', // Margin bottom for form items
  labelColor: '#000000d9', // Label text color
  labelFontSize: '14px', // Label font size

  // Tables
  tablePadding: '16px', // Table padding
  tableBorderColor: '#f0f0f0', // Table border color
  tableHeaderBackground: '#fafafa', // Table header background color
  tableHeaderColor: '#000000d9', // Table header text color

  // Modals
  modalHeaderPadding: '24px', // Modal header padding
  modalBodyPadding: '24px', // Modal body padding
  modalFooterPadding: '16px', // Modal footer padding
  modalBorderRadius: '4px', // Modal border radius
  modalHeaderColor: '#000000d9', // Modal header text color

  // Shadows
  boxShadowBase: '0 3px 6px rgba(0, 0, 0, 0.16)', // Base box shadow
  boxShadowSecondary: '0 2px 8px rgba(0, 0, 0, 0.08)', // Secondary box shadow

  // Links
  linkColor: '#1890ff', // Link color
  linkHoverColor: '#40a9ff', // Link hover color
  linkActiveColor: '#096dd9', // Link active color
  colorWhite: '#FFF',
  colorBlack: '#000',
};
