import { SVGProps } from 'react';

interface DeleteIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const DeleteIcon = ({ width = 20, height = 20, color = '#939496', ...props }: DeleteIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M13.75 1.25c.69 0 1.25.56 1.25 1.25v1.25h2.75c.092 0 .167.075.167.167v.916A.167.167 0 0 1 17.75 5h-.667v11.25a2.5 2.5 0 0 1-2.5 2.5H5.417a2.5 2.5 0 0 1-2.5-2.5V5H2.25a.167.167 0 0 1-.167-.167v-.916c0-.092.075-.167.167-.167H5V2.5c0-.69.56-1.25 1.25-1.25h7.5ZM15.833 5H4.167v11.25c0 .666.52 1.21 1.176 1.248l.074.002h9.166c.666 0 1.21-.52 1.248-1.177l.002-.073V5ZM8.167 7.5c.092 0 .166.075.166.167v7.166a.167.167 0 0 1-.166.167H7.25a.167.167 0 0 1-.167-.167V7.667c0-.092.075-.167.167-.167h.917Zm5 0c.092 0 .166.075.166.167v7.166a.167.167 0 0 1-.166.167h-.917a.167.167 0 0 1-.167-.167V7.667c0-.092.075-.167.167-.167h.917Zm.583-5h-7.5v1.25h7.5V2.5Z'
    />
  </svg>
);

export default DeleteIcon;
