import { useSentryQuery, useSentryMutation } from '../utils/sentryQueryWrapper';
import {
  createPickupAddress,
  deletePickupAddress,
  fetchPickupAddressByUID,
  fetchPickupAddresses,
  fetchPickupLastCod,
  updatePickupAddress,
} from '@/api';
import {
  ErrorMessageResponse,
  PickupAddressResponse,
  PickupFetchAddressResponse,
  PickupLastCodResponse,
  PickupRequest,
  SinglePickupAddressResponse,
  UpdatePickupParams,
} from '@/types';

// Fetch all pickup addresses
export const useFetchPickupAddresses = (page?: number) => {
  return useSentryQuery<PickupFetchAddressResponse, Error>(
    ['pickupAddresses', page],
    () => fetchPickupAddresses(page),
    {
      keepPreviousData: true,
    },
  );
};

// Fetch Last Cod Pickup Address
export const useFetchPickupLastCod = () => {
  return useSentryQuery<PickupLastCodResponse, Error>(['lastCod'], () => fetchPickupLastCod());
};

// Fetch a single pickup address by UID
export const useFetchPickupAddressByUID = (uid: string) => {
  return useSentryQuery<SinglePickupAddressResponse, Error>(
    ['pickupAddress', uid],
    () => fetchPickupAddressByUID(uid),
    {
      enabled: !!uid,
    },
  );
};

// Create a new pickup address
export const useCreatePickupAddress = () => {
  return useSentryMutation<PickupAddressResponse, ErrorMessageResponse, PickupRequest>(
    (data: PickupRequest) => createPickupAddress(data),
  );
};

// Update an existing pickup address
export const useUpdatePickupAddress = () => {
  return useSentryMutation<PickupAddressResponse, ErrorMessageResponse, UpdatePickupParams>(
    ({ data, uid }: UpdatePickupParams) => updatePickupAddress(data, uid),
  );
};

// Delete a pickup address by UID
export const useDeletePickupAddress = () => {
  return useSentryMutation<void, Error, string>((uid: string) => deletePickupAddress(uid));
};
