import * as React from 'react';
import { SVGProps } from 'react';

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const UserOutlineIcon: React.FC<CustomSVGProps> = ({
  width = 38,
  height = 38,
  color = '#E1E1E3',
  viewBox = '0 0 38 38',
  ...props
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox={viewBox}
    {...props}
  >
    <path
      fill={color}
      d='M19.385 3.583a7.692 7.692 0 0 1 7.692 7.693v3.115a7.689 7.689 0 0 1-3.846 6.663v.259l8.46 4.521a5.154 5.154 0 0 1 2.694 5.114 3.898 3.898 0 0 1-3.875 3.469H8.26a3.898 3.898 0 0 1-3.875-3.469 5.154 5.154 0 0 1 2.693-5.114l8.46-4.521v-.259a7.689 7.689 0 0 1-3.845-6.663v-3.115a7.692 7.692 0 0 1 7.692-7.692Zm0 2.313a5.38 5.38 0 0 0-5.377 5.195l-.003.185v3.115c0 1.875.965 3.579 2.517 4.556l.174.106 1 .578a.308.308 0 0 1 .153.266l.004 2.616a.308.308 0 0 1-.163.272l-9.522 5.089a2.842 2.842 0 0 0-1.485 2.82c.085.762.702 1.35 1.456 1.406l.12.004h22.25c.81 0 1.488-.607 1.577-1.41a2.842 2.842 0 0 0-1.348-2.742l-.137-.078-9.521-5.089a.309.309 0 0 1-.163-.272l.003-2.616c0-.11.059-.211.154-.266l1-.578a5.376 5.376 0 0 0 2.686-4.454l.004-.208v-3.115a5.38 5.38 0 0 0-5.38-5.38Z'
    />
  </svg>
);

export default UserOutlineIcon;
