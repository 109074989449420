import React from 'react';
import { Button, Flex, Form } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { AddIcon, PickupsIcon } from '../Icons';

// Define an interface for the props
interface BottomOrderCardProps {
  createOrderTitle: string;
  readyToShipTitle: string;
  isLoading?: boolean;
  isReadyToSubmit?: boolean;
  handleSubmit: (value: boolean) => void;
}

const BottomOrderCard: React.FC<BottomOrderCardProps> = ({
  createOrderTitle,
  readyToShipTitle,
  isLoading,
  isReadyToSubmit,
  handleSubmit,
}) => {
  return (
    <Card extraClassName='py-[19px]'>
      <Flex gap='middle' justify='flex-end' align='center'>
        <Button
          onClick={() => handleSubmit(true)}
          disabled={!isReadyToSubmit} // Disable based on isFormReady
          style={{ paddingBottom: '27px', paddingTop: '27px' }}
          className='rounded-[8px] border-solid  border-customTunaPrimary disabled:opacity-60 '
        >
          <PickupsIcon width={20} height={20} />
          <Heading
            title={readyToShipTitle}
            fontWeight='font-bold'
            fontSize='text-sm'
            extraClassName=' uppercase leading-5 text-customTunaPrimary'
          />
        </Button>
        <Form.Item className='!mb-0'>
          <Button
            loading={isLoading}
            // htmlType='submit'
            onClick={() => handleSubmit(false)}
            disabled={!isReadyToSubmit} // Disable based on isFormReady
            type='primary'
            className='rounded-[8px] disabled:bg-yellow-100 gap-x-2'
            style={{ paddingBottom: '27px', paddingTop: '27px' }}
          >
            {!isLoading && <AddIcon width={20} height={20} />}
            <Heading
              title={createOrderTitle}
              fontWeight='font-bold'
              fontSize='text-sm'
              extraClassName=' uppercase leading-5 text-customTunaPrimary'
            />
          </Button>
        </Form.Item>
      </Flex>
    </Card>
  );
};

export default BottomOrderCard;

// const { t } = useTranslation();
// const navigate = useNavigate();
// const orderUid = useOrdersStore((state) => state.orderUid);
// const { mutate: requestPickup } = useRequestPickup();

// // Function to handle pickup button click
// const handlePickupClick = () => {
// if (orderUid) {
//   const params: PickUpParams = {
//     order_uids: [orderUid], // Ensure to pass orderUid as an array
//   };

//   // Call the requestPickup mutation with onSuccess and onError handlers
//   requestPickup(params, {
//     onSuccess: () => {
//       navigate(ROUTE.PICKUPS);
//       message.success('Pickup request was successful!'); // Show success message
//       // You can also perform additional actions here, like refreshing data or redirecting
//     },
//     onError: (error) => {
//       message.error(error?.response?.data?.message || t('failed_pickup_request'));
//       // You can also log the error or perform additional error handling here
//     },
//   });
// }
// };
