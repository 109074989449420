import { SVGProps } from 'react';

interface PencilIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const PencilIcon = ({ width = 24, height = 25, color = '#38383E', ...props }: PencilIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      d='M19.578 3.922a3.15 3.15 0 0 1 0 4.456L7.107 20.847a.525.525 0 0 1-.37.153H3.55c-.58 0-1.05-.47-1.05-1.05v-3.187c0-.14.055-.273.153-.372l12.47-12.469a3.15 3.15 0 0 1 4.455 0Zm1.21 15.578c.141 0 .212.067.212.2v1.1c0 .133-.07.2-.212.2h-8.576c-.141 0-.212-.067-.212-.2v-1.1c0-.133.07-.2.212-.2h8.576ZM14.086 7.186l-9.95 9.95a.21.21 0 0 0-.06.149v1.93c0 .117.093.21.21.21h1.93a.21.21 0 0 0 .148-.061l9.95-9.95-2.228-2.228ZM20.778 16c.148 0 .222.067.222.2v1.1c0 .133-.074.2-.222.2h-4.556c-.148 0-.222-.067-.222-.2v-1.1c0-.133.074-.2.222-.2h4.556ZM16.304 4.973l-.068.063-1.05 1.05 2.227 2.228 1.05-1.05c.59-.59.619-1.537.064-2.16l-.063-.067v-.001a1.575 1.575 0 0 0-2.16-.063Z'
    />
  </svg>
);
export default PencilIcon;
