import { Upload, Typography, Row, Col, Flex, Card as CardAnt, message, Spin } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { BarIcon, Card, Heading, TrashIcon } from '@/components';
import { useTranslation } from 'react-i18next';
import { ALLOW_FILE_CSV } from '@/config';
import { useUploadExcel } from '@/hooksApi/useImport';
import { useImportTableUid } from '@/hooks';
const { Dragger } = Upload;
const { Paragraph, Link } = Typography;

interface StepProps {
  setId: (id: string | null) => void;
  uploadedFile: RcFile | null;
  setUploadedFile: (file: RcFile | null) => void;
  setCurrentStep: (value: any) => void;
}

const Step1 = ({ setId, setUploadedFile, uploadedFile, setCurrentStep }: StepProps) => {
  const { t } = useTranslation();
  const { mutate: UploadExcel, isLoading: loadingExcel } = useUploadExcel();
  const setImportTableUid = useImportTableUid((state) => state.setImportTableUid); // Access the Table UID setter from Zustand

  const handleBeforeUpload = (file: RcFile) => {
    const isCSVOrXLSX = ALLOW_FILE_CSV.includes(file.type);
    const isFileSizeValid = file.size <= 1048576;

    if (!isCSVOrXLSX) {
      message.error(t('only_csv_xlsx'));
      return Upload.LIST_IGNORE;
    }

    if (!isFileSizeValid) {
      message.error(t('file_size_limit_exceeded'));
      return Upload.LIST_IGNORE;
    }

    uploadFile(file);
    return false;
  };

  const uploadFile = async (file: RcFile) => {
    const formData = new FormData();
    formData.append('excel', file);
    setUploadedFile(file);

    UploadExcel(formData, {
      onSuccess: (data) => {
        setId(data?.object?.uid || '');
        setImportTableUid(data?.object?.uid || '');
        message.success(t('upload_Success'));
        setCurrentStep((prev: number) => prev + 1);
      },
      onError: (error) => {
        message.error(error?.response?.data?.message || t('operation_failed'));
      },
    });
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    setId(null);
    if (uploadedFile) {
      message.info(`${uploadedFile?.name} ${t('file_removed')}`);
    }
  };

  return (
    <Row gutter={24}>
      <Col md={14} xs={24}>
        <Card>
          <Heading
            title={t('import_instructions')}
            level={4}
            fontWeight='font-bold'
            extraClassName='mb-6'
          />
          <Paragraph>
            <strong>1. {t('download_template')}:</strong> {t('start_download')}{' '}
            <Link href='/boxy-bulk-import.csv' target='_blank'>
              {t('csv_template')}
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <strong>2. {t('fill_data')}:</strong> {t('use_first')}.
          </Paragraph>
          <Paragraph>
            <strong>3. {t('upload_file')}:</strong> {t('after_upload')}.
          </Paragraph>
          <Paragraph>
            <strong>4. {t('mandatory_fields')}:</strong> {t('ensure')}.
          </Paragraph>
          <Paragraph>
            <strong>5. {t('import_limit')}:</strong> {t('can_import')}.
          </Paragraph>
          <CardAnt className='mt-4 bg-customGrayShade1'>
            <Heading title={t('how_to_work')} level={5} fontWeight='font-bold' />
            <Paragraph>
              {t('importing')} <Link href='#'>{t('csv_guide')}</Link>.
            </Paragraph>
          </CardAnt>
        </Card>
      </Col>
      <Col md={10} xs={24}>
        <Card>
          <Heading
            title={t('upload_file')}
            level={4}
            fontWeight='font-bold'
            extraClassName='mb-6'
          />
          {loadingExcel ? (
            <Spin />
          ) : (
            <Dragger
              name={t('upload_file')}
              multiple={false}
              maxCount={1}
              beforeUpload={handleBeforeUpload}
              accept='.csv, .xlsx'
            >
              <BarIcon className='my-4' />
              <Flex vertical align='center' justify='center'>
                <Flex gap={1}>
                  <Heading
                    title={t('click_to_upload')}
                    fontWeight='font-bold'
                    fontSize='text-base'
                    extraClassName='underline'
                  />
                  <Heading title={t('drag_drop')} fontWeight='font-bold' fontSize='text-base' />
                </Flex>
                <Heading
                  title={t('maximum_size')}
                  fontSize='text-sm'
                  color='text-customGrayShade5'
                />
              </Flex>
            </Dragger>
          )}
          {uploadedFile && (
            <Flex className='mt-4 gap-2 flex justify-between'>
              <Flex>
                <strong>{t('file_name')}:</strong> <Flex className='mx-2'>{uploadedFile.name}</Flex>
              </Flex>
              <TrashIcon onClick={handleDeleteFile} color='red' />
            </Flex>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Step1;
