import { Avatar, Col, Flex, Progress, Row, Space } from 'antd';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import UserContactCard from '../Card/UserContactCard';
import { PhoneYellowIcon, UserOutlineIcon } from '../Icons';
import { IMAGE_SAMPLE, THEME_VARIABLE } from '@/config';
import { DriverItem } from '@/types';
import { Link } from 'react-router-dom';

interface DriverInfoProps {
  assign?: boolean;
  driver?: DriverItem | null;
}

const DriverInfo = ({ assign = false, driver }: DriverInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex align='center' justify='space-between'>
        <Heading title={t('driver_info')} fontSize='text-lg' fontWeight='font-bold' />
        {/* <Space className='rounded-full bg-customBlue p-2 h-[20px]' align='center'>
          <Heading
            title={`14 ${t('min')}`}
            color='text-colorWhite'
            extraClassName='whitespace-nowrap'
          />
        </Space> */}
      </Flex>
      <Row className='mt-2' gutter={[0, 16]}>
        {assign ? (
          <>
            {' '}
            <Col md={8} xs={24}>
              <UserContactCard
                profileImageUrl={IMAGE_SAMPLE}
                rating={4.5}
                assign
                textItem={
                  <Heading title={t('driver')} fontSize='text-sm' color='text-customGrayShade5' />
                }
                subTitleItem={
                  <Heading
                    title={`${driver?.first_name || '--'} ${driver?.last_name || '--'}`}
                    fontSize='text-base'
                  />
                }
              />
            </Col>
            <Col md={14} xs={24}>
              <Flex vertical>
                <Heading
                  title={t('phone_number')}
                  fontSize='text-sm'
                  color='text-customGrayShade5'
                />
                <Heading title={driver?.phone || '----'} fontSize='text-base' />
              </Flex>
            </Col>
            <Col md={2} className='flex justify-end' xs={24}>
              <Link to={`https://wa.me/${driver?.phone}`} target='_blank'>
                <PhoneYellowIcon />
              </Link>
            </Col>
          </>
        ) : (
          <Col md={24}>
            <Space align='center' size='middle'>
              <Space style={{ position: 'relative', display: 'inline-block' }}>
                <Progress
                  type='circle'
                  percent={25}
                  size={50}
                  strokeColor={THEME_VARIABLE.customYellowPrimary}
                  format={() => (
                    <Avatar
                      size='large'
                      icon={<UserOutlineIcon />}
                      style={{ backgroundColor: 'transparent' }}
                    />
                  )}
                />
              </Space>
              <Heading title={t('order_assign')} fontSize='text-sm' color='text-customGrayShade5' />
            </Space>
          </Col>
        )}
      </Row>
    </>
  );
};

export default DriverInfo;
